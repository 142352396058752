<template>
  <fieldset class="w-full mb-6">
    <legend class="h4 font-semi-bold mb-2">Dicas</legend>

    <p class="font-medium text-red mb-6"  v-if="!tips.length">Esta pergunta não possui dicas</p>
    <div class="w-full mt-8" v-else>
      <div class="w-full">
        <div class="w-full my-2 hidden lg:flex">
          <p class="w-20/100 flex items-center pl-4">Título</p>
          <p class="w-45/100 flex items-center pl-4">Conteúdo</p>
          <p class="w-10/100 flex items-center pl-4">Imagem</p>
          <p class="w-15/100 flex items-center pl-4">Ações</p>
        </div>

        <div class="w-full overflow-x-auto max-w-full flex flex-col">
          <div 
            class="w-full bg-white rounded flex flex-wrap shadow-sm my-2 lg:h-10vh cursor-move"
            v-for="(item, index) in tips"
            :key="`game-puzzle-item-${index}`"
            draggable="true"
            @dragstart="dragStart(index)"
            @dragover.prevent
            @dragend="dragEnd"
            @drop="dragFinish(index, tips)"
          >
            <div class="block w-full lg:w-20/100 p-2 lg:border-r border-smoke-light flex items-center">
              <h5 class="font-bold">{{item.title}}</h5>
            </div>
            <div class="block w-full lg:w-45/100 p-2 lg:border-r border-smoke-light flex items-center">
              <h5 class="font-bold">{{item.content}}</h5>
            </div>
            <div class="block w-full lg:w-10/100 p-2 lg:border-r border-smoke-light h-10vh flex justify-center">
              <a :href="item.file_url" class="flex justify-center" target="_blank" v-if="item.file_url">
                <img class="max-h-full" :src="item.file_url" height="100%" alt=""/>
              </a>
            </div>
            <div class="block w-full lg:w-15/100 p-1 flex items-center justify-center">
              <button
                @click="$eventBus.$emit('openTipModal', { id: item.id })"
                class="focus:outline-none">
                <feather type="edit"></feather>
              </button>
              <button
                @click="deleteItem(item.id)"
                class="focus:outline-none ml-3">
                <feather type="x"></feather>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      @click="$eventBus.$emit('openTipModal')"
      class="button button-border-yellow bg-white">
      <feather type="plus"></feather>
      <span class="ml-2">Adicionar</span>
    </button>

    <GameTipFormModal @updated="getTips"></GameTipFormModal>
    
  </fieldset>
</template>

<script>
import GameTipFormModal from './GameTipFormModal'
export default {
  name: 'GameQuizQuestionTipList',
  components: { GameTipFormModal },
  props: ['item_id'],
  data() {
    return {
      tips: []
    }
  },
  mounted() {
    this.getTips()
    this.dragAndDropUrl = `/admin/tips/sync_order`
  },
  methods: {
    getTips() {
      const query = `
        {
          tips (
            where: [{ column: "item_id", operation: "=", value: "${this.item_id}"}]
            orderBy: [{ column: "order", order: "asc" }]
          ) {
            id
            item_id
            item_type
            title
            content
            file_url
          }
        }
      `

      this.$graphql({ query, fetchPolicy: 'network-only'})
        .then(({ data }) => {
          this.tips = data.tips
          this.$emit('updated')
        })
    },

    deleteItem(itemId) {
      this.$api.delete(`/admin/tips/destroy/${itemId}`)
        .then(() => {
          this.getTips()
          this.$emit('updated')
          this.$toast.success(`Dica removida com sucesso`)
        })
        .catch((err) => {
          if(err.response.data) {
            this.$toast.error(err.response.data.message)
          }
        })
    },

    toggleCorrectOption(itemId) {
      this.$api.put(`/admin/tips/toggle_correct/${itemId}`)
        .then(() => {
          this.getTips()
          this.$toast.success(`Item atualizado com sucesso`)
        })
    },

    callbackDragAndDrop() {
      this.getTips()
    }
  }
}
</script>