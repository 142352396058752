import GameList from '@/modules/admin/game/GameList'
import GameForm from '@/modules/admin/game/GameForm'
import GameShow from '@/modules/admin/game/GameShow'
import GameQuizQuestionShow from '@/modules/admin/game/quiz_question/GameQuizQuestionShow'
import GamePuzzleButtonForm from '@/modules/admin/game/puzzle/GamePuzzleButtonForm'

export default [
  {
    path: 'games',
    name: 'GameList',
    component: GameList,
    meta: {
      breadcrumb: 'Configuração games / Lista'
    }
  },
  {
    path: 'games/add',
    name: 'GameAdd',
    component: GameForm,
    meta: {
      breadcrumb: 'Configuração games / Adicionar'
    }
  },
  {
    path: 'games/update/:gameId',
    name: 'GameUpdate',
    component: GameForm,
    meta: {
      breadcrumb: 'Configuração games / Atualizar'
    }
  },
  {
    path: 'games/show/:gameId',
    name: 'GameShow',
    component: GameShow,
    meta: {
      breadcrumb: 'Configuração games / Detalhes'
    }
  },
  {
    path: 'games/questions/:quizQuestionId/detalhes',
    name: 'GameQuizQuestionShow',
    component: GameQuizQuestionShow,
    meta: {
      breadcrumb: 'Configuração games / Pergunta Quiz'
    }
  },
  {
    path: 'games/puzzle_buttons/:gameId/add',
    name: 'GamePuzzleButtonAdd',
    component: GamePuzzleButtonForm,
    meta: {
      breadcrumb: 'Configuração games / Adicionar botão'
    }
  },
  {
    path: 'games/puzzle_buttons/:gameId/:puzzleButtonId/edit',
    name: 'GamePuzzleButtonUpdate',
    component: GamePuzzleButtonForm,
    meta: {
      breadcrumb: 'Configuração games / Editar botão'
    }
  },
]