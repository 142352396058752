module.exports = ({ file_name, csv_data }) => {
  return new Promise((resolve, reject) => {
    try {
      csv_data
      var exportedFilenmae = file_name + ".csv";
      var blob = new Blob([csv_data], { type: "text/csv;charset=utf-8;" })
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", exportedFilenmae)
          link.style.visibility = "hidden"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      resolve()
    } catch (error) {
      console.log(error)
      reject()
    }
  })
}