<template>
  <div class="w-full">
    <div class="w-full flex flex-wrap lg:flex-no-wrap justify-between items-end">
      <SearchInput
        class="lg:max-w-50/100 search-input"
        v-model="filters.company_name"
        @keydown="getCompanies"
        :label="'Busca'"
      ></SearchInput>
      <router-link :to="{ name: 'CompanyAdd' }" class="lg:w-1/6 mt-3 lg:mt-0 button button-yellow whitespace-no-wrap">
        <feather type="plus" class="mr-2"></feather>
        <span>Novo cliente</span>
      </router-link>
    </div>

    <div class="w-full mt-4">
      <legend class="font-medium">Filtro</legend>

      <div class="w-full flex flex-wrap">
        <div class="w-full lg:w-25/100 lg:pr-3 mt-1 lg:mt-0 relative">
          <v-select 
            class="vue-select"
            placeholder="Filtre por ramo"
            :class="filters.industry_id ? 'filled' : ''"
            label="name"
            :options="industries"
            :reduce="item => item.id"
            v-model="filters.industry_id"
            @input="getCompanies"
          ></v-select>
        </div>
        <div class="w-full lg:w-25/100 lg:pr-3 mt-1 lg:mt-0 relative">
          <v-select 
            class="vue-select"
            placeholder="Filtre por tamanho"
            :class="filters.company_size ? 'filled' : ''"
            label="label"
            :options="companySizes"
            :reduce="item => item.value"
            v-model="filters.company_size"
            @input="getCompanies"
          ></v-select>
        </div>
        <div class="w-full lg:w-25/100 lg:pr-3 mt-1 lg:mt-0 relative">
          <v-select 
            class="vue-select"
            placeholder="Filtre por status"
            :class="filters.is_active != null ? 'filled' : ''"
            label="label"
            :options="statuses"
            :reduce="item => item.value"
            v-model="filters.is_active"
            @input="getCompanies"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="w-full mt-4 overflow-x-auto max-w-full min-w-0">
      <table class="table table-striped bg-white shadow-sm whitespace-no-wrap">
        <thead>
          <tr>
            <th>Nome <OrderByHelper :orderBy="orderBy" :item="'company_name'" @updateOrderBy="toggleOrderBy"/></th>
            <th>Criação <OrderByHelper :orderBy="orderBy" :item="'created_at'" @updateOrderBy="toggleOrderBy"/></th>
            <th>Ramo</th>
            <th>Região <OrderByHelper :orderBy="orderBy" :item="'state'" @updateOrderBy="toggleOrderBy"/></th>
            <th>Núm funcionários <OrderByHelper :orderBy="orderBy" :item="'employees_number'" @updateOrderBy="toggleOrderBy"/></th>
            <th>Tamanho <OrderByHelper :orderBy="orderBy" :item="'company_size'" @updateOrderBy="toggleOrderBy"/></th>
            <th>Ativo <OrderByHelper :orderBy="orderBy" :item="'is_active'" @updateOrderBy="toggleOrderBy"/></th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in companies" :key="company.Id">
            <td>{{company.name}}</td>
            <td>{{company.created_at}}</td>
            <td>{{company.industry.name}}</td>
            <td>{{company.state}}</td>
            <td>{{company.employees_number}}</td>
            <td>{{company.formattedCompanySize}}</td>
            <td>{{company.formattedIsActive}}</td>
            <td>
              <router-link class="" :to="{ name: 'CompanyUpdate', params: { companyId: company.id }}">
                <feather type="edit"></feather>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Paginator
      class="mt-2"
      v-model="offset" 
      :length="companies.length" 
      :limit="limit"
      @getItems="getCompanies"
    ></Paginator>

  </div>
</template>

<script>
import Paginator from '@/components/Paginator'
import PaginationInfo from '@/models/PaginationInfo'
import Company from '@/models/Company'
import { companySizeOptions } from '@/models/Enums'
export default {
  name: 'CompanyList',
  components: { Paginator },
  data() {
    return {
      filters: {
        company_name: '',
        industry_id: '',
        company_size: '',
        is_active: null,
      },
      orderBy: {
        column: 'name',
        direction: 'asc'
      },
      offset: 0,
      limit: 12,
      companies: [],
      companySizes: companySizeOptions,
      statuses: [
        { value: true, label: 'Ativo' },
        { value: false, label: 'Não ativo' }
      ],
      industries: [],
      paginationInfo: new PaginationInfo()
    }
  },
  mounted() {
    this.getCompanies()
    this.getIndustries()
  },
  methods: {

    getCompanies() {
      const query =  `
        {
          companies (
            where: [
              ${this.filters.industry_id ? `{ column: "industry_id", operation: "=", value: "${this.filters.industry_id}" }` : ''}
              ${this.filters.company_size ? `{ column: "company_size", operation: "=", value: "${this.filters.company_size}" }` : ''}
              ${this.filters.is_active != null ? `{ column: "is_active", operation: "=", value: "${this.filters.is_active}" }` : ''}
              { column: "name", operation: "ILIKE", value: "%${this.filters.company_name}%" }
            ]
            orderBy: [{ column: "${this.orderBy.column}", order: "${this.orderBy.direction}" }]
            paginate: { offset: ${this.offset}, limit: ${this.limit} }
          ) {
            id
            name
            state
            employees_number
            created_at
            company_size
            is_active
            logo_url
            industry {
              name
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only'  })
        .then(({ data }) => {
          this.companies = data.companies.map((company) => new Company(company))
        })
    },

    getIndustries() {
      const query =  `
        {
          industries {
            id
            name
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.industries = data.industries
        })
    },
    toggleOrderBy(column) {
      if(this.orderBy.direction == 'asc') {
        this.orderBy.column = column
        this.orderBy.direction = 'desc'
        this.getCompanies()
        return
      }

      this.orderBy.column = column
      this.orderBy.direction = 'asc'
      this.getCompanies()
      return
    }
  }
}
</script>

<style>

</style>