<template>
  <div class="w-full flex flex-wrap items-start">

    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Dados iniciais</legend>
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <InputWithLabel
          class="md:pr-4 w-full md:w-50/100"
          :label="'Nome do projeto'"
          v-model="project.name"
        ></InputWithLabel>
        <div class="w-full md:w-50/100 md:pl-4">
          <label>Nome empresa ou CNPJ</label>
          <v-select 
            class="vue-select"
            placeholder="Selecione uma empresa"
            :class="project.company_id ? 'filled' : ''"
            label="name"
            :options="companies"
            :filter="filterCompanies"
            :reduce="company => company.id"
            v-model="project.company_id"
          ></v-select>
        </div>
      </div>

      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-4 md:pr-4 w-full md:w-50/100"
          :label="'Data do evento'"
          v-model="project.event_start_at_date"
          :mask="'##/##/####'"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-4 md:pl-4 w-full md:w-50/100"
          :label="'Hora de início do jogo'"
          :mask="'##:##'"
          v-model="project.event_start_at_hour"
        ></InputWithLabel>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-4"/>

    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Usuários</legend>
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <InputWithLabel
          class="w-full md:w-50/100 md:pr-4 "
          :label="'URL perguntas inciais'"
          v-model="project.url_questions"
        ></InputWithLabel>
        <InputWithLabel
          class="w-full md:w-50/100 md:pl-4"
          :label="'Máx. quantidade de usuários no projeto'"
          v-model="project.max_users"
        ></InputWithLabel>
      </div>

      <div class="mt-4 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <div class="w-full flex">
          <!-- <InputWithLabel
            class="w-full md:w-50/100 md:pr-4 "
            :label="'Quantidade de usuários ???'"
            v-model="project.user_quantity"
            mask="####"
            type="tel"
          ></InputWithLabel> -->
          <InputWithLabel
            class="w-full md:w-50/100 md:pr-4 "
            :label="'Máx. quantidade de usuários por sala'"
            v-model="project.room_quantity"
            mask="####"
            type="tel"
          ></InputWithLabel>
          <InputWithLabel
            class="w-full md:w-50/100 md:pl-4 "
            :label="'Tempo total por time (em minutos)'"
            v-model="project.total_time"
            mask="####"
            type="tel"
          ></InputWithLabel>
        </div>
      </div>

      <div class="mt-4 w-full flex flex-wrap items-end">
        <div class="w-full flex">
          <SwitchToggle
            class="w-full"
            v-model="project.has_penalties"
            label="Habilitar penalidade dos games?"
          ></SwitchToggle>
        </div>
        <div class="w-full flex items-end mt-2">
          <InputFile
            class="w-full"
            label="Música de fundo"
            v-model="project.background_music"
            accept="audio/mp3"
          ></InputFile>
          <button 
            @click="clearBackgroundMusic()"
            class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
            Excluir
          </button>
        </div>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-4"/>

    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Contato</legend>
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <InputWithLabel
          class="w-full md:w-50/100 md:pr-4 "
          label="Nome do contato"
          v-model="project.contact_name"
        ></InputWithLabel>
        <InputWithLabel
          class="w-full md:w-50/100 md:pl-4"
          label="Email contato"
          v-model="project.contact_email"
        ></InputWithLabel>
      </div>
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <InputWithLabel
          class="w-full md:w-50/100 md:pr-4 "
          label="Telefone do contato"
          v-model="project.contact_phone"
          :mask="['(##) ####-####', '(##) #####-####']"
        ></InputWithLabel>
      </div>
      <Textarea
        class="mt-4"
        label="Lista de contatos"
        placeholder="Ex. website, indicação, rede social, workshop, email..."
        v-model="project.contact_source"
      ></Textarea>
    </fieldset>

    <div class="w-full text-base mt-4 mb-2" v-if="project.hasError" v-html="project.validationPhraseHtml"></div>
    
    <button 
      v-if="!isEdit"
      @click="store()" 
      :disabled="project.hasError"
      class="button button-yellow">
      Cadastrar
    </button>
    <button 
      v-else
      @click="update()" 
      :disabled="project.hasError"
      class="button button-yellow mt-4">
      Atualizar
    </button>

    <AvoidExitModal></AvoidExitModal>

  </div>
</template>

<script>
import Project from '@/models/Project'

export default {
  name: 'ProjectForm',
  data() {
    return {
      companies: [],
      games: [],
      project: new Project(),
      isEdit: false,
      showAddGame: false,
      gameSelected: null
    }
  },
  mounted() {
    this.getCompanies()
    if(this.$route.params.projectId) {
      this.isEdit = true
      this.getProject()
    }
  },
  methods: {

    getCompanies() {
      const query =  `
        {
          companies (
            orderBy: [{ column: "name", order: "asc" }]
          ) {
            id
            name
            cnpj
            state
            employees_number
            created_at
            company_size
            is_active
            industry {
              name
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.companies = data.companies
        })
    },

    getProject() {

      const query =  `
        {
          project (
            findBy: { column: "id", value: "${this.$route.params.projectId}"}
          ) {
            id
            company_id
            name
            event_start_at
            event_start_at_date
            event_start_at_hour
            total_time
            max_users
            user_quantity
            room_quantity
            contact_name
            contact_email
            contact_phone
            contact_source
            has_penalties
            company {
              name
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.project = new Project(data.project)
        })
    },

    filterCompanies(options, search) {
      return options.filter((item) => {
        if(item.name.toLowerCase().includes(search.toLowerCase()) ||  item.cnpj.includes(search.toLowerCase())) {
          return true
        }
        return false
      })
    },

    clearBackgroundMusic() {
      this.project.background_music = new File(["foo"], "", { type: "text/plain" })
    },
    
    store() {
      this.project.event_start_at = this.project.getEventStartAtToStore
      const formData = this.project.getFormData
      this.$api.post(`/admin/projects/store`, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success('Projeto cadastrado com sucesso')
          this.$router.push({ name: 'ProjectList', forceRedirect: true })
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error('Erro ao cadastrar projeto')
        })
    },

    update() {
      this.project.event_start_at = this.project.getEventStartAtToStore
      const formData = this.project.getFormData
      this.$api.post(`/admin/projects/update/${this.project.id}`, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success('Projeto atualizado com sucesso')
          this.$router.push({ name: 'ProjectList' , forceRedirect: true})
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error('Erro ao atualizar projeto')
        })
    },

    
  }
}
</script>