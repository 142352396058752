<template>
  <fieldset class="w-full mb-6">
    <legend class="h4 font-semi-bold mb-2">Opcões de resposta</legend>

    <p class="font-medium text-red mb-6"  v-if="!quiz_question_options.length">Opcões não cadastradas</p>
    <div class="w-full mt-8" v-else>
      <div class="w-full">
        <div class="w-full my-2 hidden lg:flex">
          <p class="w-20/100 flex items-center pl-4">Título</p>
          <p class="w-45/100 flex items-center pl-4">Conteúdo</p>
          <p class="w-10/100 flex items-center pl-4" >É correta</p>
          <p class="w-10/100 flex items-center pl-4">Imagem</p>
          <p class="w-15/100 flex items-center pl-4">Ações</p>
        </div>

        <div class="w-full overflow-x-auto max-w-full flex flex-col">
          <div 
            class="w-full bg-white rounded flex flex-wrap shadow-sm my-2 lg:h-10vh cursor-move"
            v-for="(item, index) in quiz_question_options"
            :key="`game-puzzle-item-${index}`"
            draggable="true"
            @dragstart="dragStart(index)"
            @dragover.prevent
            @dragend="dragEnd"
            @drop="dragFinish(index, quiz_question_options)"
          >
            <div class="block w-full lg:w-20/100 p-2 lg:border-r border-smoke-light flex items-center">
              <h5 class="font-bold">{{item.title}}</h5>
            </div>
            <div class="block w-full lg:w-45/100 p-2 lg:border-r border-smoke-light flex items-center">
              <h5 class="font-bold">{{item.content}}</h5>
            </div>
            <div
              class="block w-full lg:w-10/100 p-2 lg:border-r border-smoke-light h-10vh flex justify-center items-center">
              <BooleanLabel v-if="item.is_correct" :boolean="item.is_correct"></BooleanLabel>
              <button
                v-else
                @click="toggleCorrectOption(item.id)"
                class="button-sm button-border-yellow focus:outline-none">
                Não
              </button>
            </div>
            <div class="block w-full lg:w-10/100 p-2 lg:border-r border-smoke-light h-10vh flex justify-center">
              <a :href="item.file_url" class="flex justify-center" target="_blank" v-if="item.file_url">
                <img class="max-h-full" :src="item.file_url" height="100%" alt=""/>
              </a>
            </div>
            <div class="block w-full lg:w-15/100 p-1 flex items-center justify-center">
              <button
                @click="$eventBus.$emit('openQuizQuestionOptionModal', { id: item.id })"
                class="focus:outline-none">
                <feather type="edit"></feather>
              </button>
              <button
                @click="deleteItem(item.id)"
                class="focus:outline-none ml-3">
                <feather type="x"></feather>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      @click="$eventBus.$emit('openQuizQuestionOptionModal')"
      :disabled="quiz_question_options.length >= 4"
      class="button button-border-yellow bg-white">
      <feather type="plus"></feather>
      <span class="ml-2">Adicionar</span>
    </button>

    <GameQuizQuestionOptionModal @update="getItems" type="quiz_question_options"></GameQuizQuestionOptionModal>
    
  </fieldset>
</template>

<script>
import GameQuizQuestionOptionModal from './GameQuizQuestionOptionModal'
export default {
  name: 'QuestionOptionTipList',
  components: { GameQuizQuestionOptionModal },
  data() {
    return {
      quiz_question_options: []
    }
  },
  mounted() {
    this.getItems()
    this.dragAndDropUrl = `/admin/quiz_question_options/sync_order`
  },
  methods: {
    getItems() {
      const query = `
        {
          quiz_question_options (
            where: [{ column: "quiz_question_id", operation: "=", value: "${this.$route.params.quizQuestionId}"}]
            orderBy: [{ column: "order", order: "asc" }]
          ) {
            id
            title
            content
            file_url
            is_correct
          }
        }
      `

      this.$graphql({ query, fetchPolicy: 'network-only'})
        .then(({ data }) => {
          this.quiz_question_options = data.quiz_question_options
        })
    },

    deleteItem(itemId) {
      this.$api.delete(`/admin/quiz_question_options/destroy/${itemId}`)
        .then(() => {
          this.getItems()
          this.$toast.success(`Item removido com sucesso`)
        })
        .catch((err) => {
          if(err.response.data) {
            this.$toast.error(err.response.data.message)
          }
        })
    },

    toggleCorrectOption(itemId) {
      this.$api.put(`/admin/quiz_question_options/toggle_correct/${itemId}`)
        .then(() => {
          this.getItems()
          this.$toast.success(`Item atualizado com sucesso`)
        })
    },

    callbackDragAndDrop() {
      this.getItems()
    }
  }
}
</script>