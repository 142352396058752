
export default {

  setLoaderShow(context, show) {
    if(show) {
      let currentLoaderInstances = context.getters.getterLoaderInstances
      let newLoaderInstances = currentLoaderInstances + 1
      context.commit('setLoaderShow', {loaderShow: true, loaderInstances: newLoaderInstances})
    } else {
      context.dispatch('setLoaderOff')
    }
  },

  setLoaderOff(context) {
    setTimeout(() => {
      let currentLoaderInstances = context.getters.getterLoaderInstances
      // console.log('currentLoaderInstances', currentLoaderInstances)
      if(currentLoaderInstances <= 1) {
        context.commit('setLoaderShow', {loaderShow: false, loaderInstances: 0})
      } else {
        let newLoaderInstances = currentLoaderInstances - 1
        context.commit('setLoaderShow', {loaderShow: true, loaderInstances: newLoaderInstances})
      }
    }, 1000)
  },

  resetLoader(context) {
    context.commit('resetLoader')
  },

}
