<template>
  <BaseGameLayout>
    <div class="w-full h-full p-8 flex flex-wrap">

      <div class="w-full h-10vh">
        <h1 class="text-center text-white text-3xl font-bold">Olá {{getPlayer.name}}, aguarde o game iniciar</h1>
      </div>

      <div class="w-full flex h-80vh">
        <div class="h-full w-60/100 flex flex-col p-3">

          <div class="w-full h-full flex flex-col bg-yellow-smoke border border-yellow border-only-corner border-only-corner-lg rounded relative">

            <span></span>
            <div class="w-full border-b border-yellow p-3 relative">
              <h2 class="w-full text-center text-2xl text-white font-bold">{{getTeam.name}}</h2>
              <button @click="$eventBus.$emit('openTeamNameEditModal')" class="z-10 rounded-full h-full flex items-center justify-center text-yellow absolute top-0 right-0 mr-2 focus:outline-none">
                <feather class="pointer-events-none" type="edit"></feather>
              </button>
            </div>

            <div class="w-full h-full p-3">
              <h4 class="w-full text-center font-bold text-white">Enigmas selecionados</h4>

              <div class="w-full flex h-full max-w-full overflow-x-auto  ">

                <transition-group
                  class="w-full flex justify-start overflow-x-auto overflow-y-hidden pb-4 mt-3"
                  mode="out-in"
                  enter-active-class="transition ease-out duration-200 transform"
                  enter-class="opacity-0 translate-y-full"
                  enter-to-class="opacity-100"
                  leave-active-class="transition ease-in duration-200 transform"
                  leave-class="opacity-100"
                  leave-to-class="opacity-0 translate-y-full"
                >
                  <div class="w-25vh flex-none mr-3 flex flex-col rounded bg-yellow-smoke h-25vh p-1" v-for="game in games" :key="game.id">
                    <h5 class="w-full text-center text-yellow font-medium text-2xl">{{game.name}}</h5>
                    <img class="h-full w-full rounded object-cover mt-2" :src="game.background_file_url" alt="" />
                  </div>
                  
                </transition-group>
              </div>

            </div>

            <div class="w-full border-t border-yellow p-3 flex flex-col items-center justify-center h-20vh">
              
              <div class="w-full flex">
                <div class="w-70/100 flex flex-col justify-center items-start">
                  <h5 class="w-full text-sm text-yellow text-lg">
                    A partida 
                    <span v-if="remainTimestamp <= 0">iniciou em:</span>
                    <span v-else>iniciará em:</span>
                  </h5>
                  <h1 class="w-full text-5xl text-red">{{getRemainTime}}</h1>
                </div>
                <div class="w-30/100">
                  <GameButton label="Ir para o game" @click="goToCurrentTeamGame" v-if="remainTimestamp <= 0"></GameButton>
                  <GameButton label="Aguarde" @click="goToCurrentTeamGame" v-else></GameButton>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div class="h-full w-40/100 p-3 flex flex-col">

          <div class="w-full flex flex-col bg-yellow-smoke border border-yellow border-only-corner rounded relative p-3">
            <div class="w-full flex flex-col">
              <span class="flex justify-between text-yellow text-2xl">
                <h3>Pessoas online</h3>
                <h3>123</h3>
              </span>
            </div>
          </div>

          <div class="w-full h-full flex flex-col bg-yellow-smoke border border-yellow border-only-corner rounded relative mt-3 p-3">
            <div class="w-full flex flex-col whitespace-no-wrap ellipsis">
              <span class="flex justify-between text-yellow text-2xl">
                <h3 class="text-yellow text-xl ellipsis mr-3">Pessoas online da sua equipe</h3>
                <h3 class="text-yellow text-xl">{{getRoomPlayers.length}} / {{getMaxPlayersInTeam}}</h3>
              </span>
              <hr class="w-full border border-smoke-light my-3"/>

              <div class="w-full flex justify-between mb-1" v-for="player in getRoomPlayers" :key="`online-${player.playerId}`">
                <h3 class="text-white">{{player.playerName}}</h3>
                <h3 class="text-green">online</h3>
              </div>
              <div class="w-full flex justify-between mb-1" v-for="player in offlinePlayers" :key="`offline-${player.playerId}`">
                <h3 class="text-grey">{{player.playerName}}</h3>
                <h3 class="text-red">offline</h3>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <BaseModal
      ref="gameInitModal"
      modalDialogClasses="max-w-sm bg-black" 
    >
      <div class="w-full flex flex-col items-center justify-center">
        <h2 class="text-center">
          <span class="mr-1">O game</span>
          <span class="mr-1" v-if="remainTimestamp < 0">iniciou em:</span>
          <span class="mr-1" v-else>iniciará em:</span>
          <span class="text-yellow">{{getRemainTime}}</span>
        </h2>

        <button @click="goToCurrentTeamGame()" class="mt-3 button border text-yellow border-yellow border-only-corner">
          <span>Ir para o game</span>
        </button>
      </div>
    </BaseModal> -->

    <GameTeamNameEditModal></GameTeamNameEditModal>


    <audio id="audio-countdown" src="/audio/countdown.mp3"></audio>

  </BaseGameLayout>
</template>

<script>
import dayjs from 'dayjs'
import Game from '@/models/Game'
import GameTeamNameEditModal from './GameTeamNameEditModal'
export default {
  name: 'GameLobby',
  components: { GameTeamNameEditModal },
  metaInfo () {
    return {
      title: `Lobby time ${this.getTeam.name} | `
    }
  },
  data() {
    return {
      offlinePlayers: [],
      games: [],
      remainTimestamp: 0,
      timeInterval: null,
      gameStarted: false
    }
  },
  computed: {
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getNextGame() {
      return this.games.length ? this.games[0] : new Game()
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getRoom() {
      return this.$store.getters.getterRoom
    },
    getRoomPlayers() {
      return this.$store.getters.getterRoom.players.filter((player) => !player.playerIsHidden)
    },
    getRemainTime() {
      const duration = dayjs.duration(this.remainTimestamp.toString().replace(/\D/g, ''), "seconds")
      if(duration.days() > 1) {
        return `${duration.days('')} dias ${duration.format('HH:mm:ss')}`
      }
      return duration.format('HH:mm:ss')
    },
    getMaxPlayersInTeam() {
      return this.offlinePlayers.length + this.getRoomPlayers.length
    },
    getServerTime() {
      return this.$store.getters.getterServerTime
    }

  },
  watch: {
    getRoom() {
      this.getOfflinePlayers()
    }
  },
  mounted() {
    this.$store.dispatch('getLoggedPlayer', { projectSlug: this.$route.params.projectSlug })
    this.getProjectGames()
    this.initTimer()
  },

  methods: {

    initTimer(){
      this.timeInterval = setInterval(() => {
        this.remainTimestamp = dayjs(this.getProject.event_start_at, 'DD/MM/YYYY HH:mm:ss').diff(dayjs(this.getServerTime), 'seconds')
        if(this.remainTimestamp < 5 && this.remainTimestamp > 0) window.playSound('audio-countdown')

      }, 1000)

    },

    stopTime() {
      clearInterval(this.timeInterval)
      this.remainTimestamp = 0
      this.timeInterval = null
    },

    getProjectGames() {
      const query = `
        {
          project (findBy: { column: "slug", value: "${this.$route.params.projectSlug}" }) {
            id
            name
            event_start_at
            games (
              orderBy: [{ column: "order", order: "asc" }]
            ) {
              id
              name
              slug
              component
              difficulty
              background_file_url
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          data.project.games.forEach((game, index) => {
            setTimeout(() => {
              this.games.push(game)
            }, index * 300)
          })
        })
    },


    getOfflinePlayers() {

      const playersOnline = this.getRoomPlayers.map(player => `"${player.playerId}"`)

      const query = `
        {
          players_project (
            where: [
              { column: "project_id", operation: "=", value: "${this.getProject.id}" },
              { column: "team_id", operation:  "=", value: "${this.getTeam.id}" },
            ],
            whereNotIn: [
              { column: "player_id", value: [${playersOnline}] }
            ]
          ) {
            id
            player_id
            player {
              name
              is_hidden
            }
          }
        }
      `

      this.$graphql({ query})
        .then(({ data }) => {
          this.offlinePlayers = data.players_project.map((player_project) => {
            return {
              playerId: player_project.player_id,
              playerName: player_project.player.name,
              playerIsHidden: player_project.player.is_hidden
            }
          }).filter((player) => !player.playerIsHidden)
        })
    },

    goToCurrentTeamGame() {
      this.$api.post(`/game/room/go_to_current_game`, { team_id: this.getTeam.id })
    }

  }
}
</script>

<style>

</style>