<template>
  <div class="w-full h-screen flex flex-wrap items-center justify-center my-auto bg-yellow-admin p-3">

    <BaseLoader></BaseLoader>
    <div class="max-w-sm w-full flex">
      <div class="w-full p-6 box-login">
        
        <div class="w-full flex justify-center">
          <img src="/img/logo_ideia.svg" class="logo" alt="Ideia ativada logo" />
        </div>
        <InputWithLabel
          class="mt-7"
          v-model="user.email"
          :type="'email'"
          id="admin-email"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-2"
          v-model="user.password"
          :type="'password'"
          id="admin-pass"
          @keydown.enter="attempt()"
        ></InputWithLabel>
        <button @click="attempt()" class="mt-2 button button-yellow w-full">
          Entrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminLogin',
  metaInfo () {
    return {
      title: `Admin |`
    }
  },
  data() {
    return {
      user: {
        email: process.env.NODE_ENV == 'development' ? 'igortrindademe@gmail.com' : '',
        password: process.env.NODE_ENV == 'development' ? '123123' : ''
      }
    }
  },
  mounted() {
    if(this.$store.getters.getterLoggedUserToken) {
      this.$store.dispatch('logoutUser')
    }
  },
  methods: {
    attempt() {
      this.$store.dispatch('attemptUserLogin', this.user )
    }
  }
}
</script>

<style>
  .box-login input {
    text-align: center;
    
    border-radius: 30px;
  }

  .box-login .button {
    border-radius: 30px;
  }

  .box-login .logo {
    max-width: 320px;
  }
</style>