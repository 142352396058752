
import Vue from 'vue'

/**
 * Global components
 */
import AdminHeader from '@/modules/admin/layout/AdminHeader'
import AdminSidebar from '@/modules/admin/layout/AdminSidebar'
import AdminLayout from '@/modules/admin/layout/AdminLayout'
import BaseGameLayout from '@/modules/game/layout/BaseGameLayout'
import BaseModal from '@/modules/game/layout/BaseModal'

/**
 * Game
 */
import GamePuzzle from '@/modules/game/games/puzzle/GamePuzzle'
import GameQuiz from '@/modules/game/games/quiz/GameQuiz'
import GameGenius from '@/modules/game/games/genius/GameGenius'

import BaseLoader from '@/components/BaseLoader'
import InputWithLabel from '@/components/InputWithLabel'
import Textarea from '@/components/Textarea'
import CheckBox from '@/components/CheckBox'
import Radio from '@/components/Radio'
import Dropdown from '@/components/Dropdown'
import StatusLabel from '@/components/StatusLabel'
import SwitchToggle from '@/components/SwitchToggle'
import InputFile from '@/components/InputFile'
import AvoidExitModal from '@/components/AvoidExitModal'
import GameDifficultyLabel from '@/components/GameDifficultyLabel'
import ProjectStatusLabel from '@/components/ProjectStatusLabel'
import SearchInput from '@/components/SearchInput'
import BooleanLabel from '@/components/BooleanLabel'
import OrderByHelper from '@/components/OrderByHelper'
import PuzzleButtonAccessHelper from '@/components/PuzzleButtonAccessHelper'
import Toast from '@/components/Toast'
import GameButton from '@/components/GameButton'
import GameIntroductionModal from '@/modules/game/layout/GameIntroductionModal'
import VideoAudioControls from '@/modules/game/layout/VideoAudioControls'
import InputGame from '@/components/InputGame'
import InputSlider from '@/components/InputSlider'
import SelectInput from '@/components/SelectInput'

import ToastMixin from '@/mixins/ToastMixin'
import DragAndDropMixin from '@/mixins/DragAndDropMixin'
import GeneralMixin from '@/mixins/GeneralMixin'

/**
 * Reports
 */
import ReportWithHeader from '@/modules/report/layout/ReportWithHeader'
import ReportHome from '@/modules/report/reports/ReportHome'
import Report02 from '@/modules/report/reports/02/Report02'
import Report03 from '@/modules/report/reports/03/Report03'
import Report04 from '@/modules/report/reports/04/Report04'
import Report05 from '@/modules/report/reports/05/Report05'
import Report06 from '@/modules/report/reports/06/Report06'
import Report07 from '@/modules/report/reports/07/Report07'
import Report08 from '@/modules/report/reports/08/Report08'
import Report09 from '@/modules/report/reports/09/Report09'
import Report10 from '@/modules/report/reports/10/Report10'

/**
 * Vendor
 */
import VueMeta from 'vue-meta'
import VueFeather from 'vue-feather'
import VueTheMask from 'vue-the-mask'
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import VueNumberFormat from 'vue-number-format'
import vSelect from 'vue-select'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css';
import VueInlineSvg from 'vue-inline-svg'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
const customParseFormat = require('dayjs/plugin/customParseFormat')
const duration = require('dayjs/plugin/duration')
const utc = require('dayjs/plugin/utc')
const isBetween = require('dayjs/plugin/isBetween')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(relativeTime)

import '@/util/ArrayPrototypeHelpers'

export default (() => {

  /**
   * Admin
   */
  Vue.component('AdminHeader', AdminHeader)
  Vue.component('AdminSidebar', AdminSidebar)
  Vue.component('AdminLayout', AdminLayout)

  /**
   * Game
   */
  Vue.component('BaseGameLayout', BaseGameLayout)
  Vue.component('BaseModal', BaseModal)
  Vue.component('GamePuzzle', GamePuzzle)
  Vue.component('GameQuiz', GameQuiz)
  Vue.component('GameGenius', GameGenius)

  /**
   * General / shared components
   */
  Vue.component('BaseLoader', BaseLoader)
  Vue.component('InputWithLabel', InputWithLabel)
  Vue.component('Textarea', Textarea)
  Vue.component('CheckBox', CheckBox)
  Vue.component('Radio', Radio)
  Vue.component('Dropdown', Dropdown)
  Vue.component('StatusLabel', StatusLabel)
  Vue.component('SwitchToggle', SwitchToggle)
  Vue.component('InputFile', InputFile)
  Vue.component('AvoidExitModal', AvoidExitModal)
  Vue.component('GameDifficultyLabel', GameDifficultyLabel)
  Vue.component('ProjectStatusLabel', ProjectStatusLabel)
  Vue.component('SearchInput', SearchInput)
  Vue.component('BooleanLabel', BooleanLabel)
  Vue.component('OrderByHelper', OrderByHelper)
  Vue.component('PuzzleButtonAccessHelper', PuzzleButtonAccessHelper)
  Vue.component('Toast', Toast)
  Vue.component('GameButton', GameButton)
  Vue.component('GameIntroductionModal', GameIntroductionModal)
  Vue.component('VideoAudioControls', VideoAudioControls)
  Vue.component('InputGame', InputGame)
  Vue.component('InputSlider', InputSlider)
  Vue.component('SelectInput', SelectInput)

  /**
   * Reports
   */
  Vue.component('ReportHome', ReportHome)
  Vue.component('ReportWithHeader', ReportWithHeader)
  Vue.component('Report02', Report02)
  Vue.component('Report03', Report03)
  Vue.component('Report04', Report04)
  Vue.component('Report05', Report05)
  Vue.component('Report06', Report06)
  Vue.component('Report07', Report07)
  Vue.component('Report08', Report08)
  Vue.component('Report09', Report09)
  Vue.component('Report10', Report10)

  /**
   * Mixins
   */
   Vue.mixin(ToastMixin)
   Vue.mixin(DragAndDropMixin)
   Vue.mixin(GeneralMixin)

  /**
   * Vendor install
   */
  Vue.use(VueMeta)
  Vue.component('v-select', vSelect)
  Vue.use(VueFeather)
  Vue.use(VueTheMask)
  dayjs.locale('pt-br')
  dayjs.extend(customParseFormat)
  dayjs.extend(isBetween)
  Vue.use(VueToast, { position: 'top', duration: 3000 })
  Vue.component('vue-inline-svg', VueInlineSvg)
  

  Vue.use(VueNumberFormat, {prefix: 'R$ ', decimal: ',', thounsand: '.', precision: 2})

  if (process.env.NODE_ENV === 'productionsssss') {

    Sentry.init({
      debug: false,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new VueIntegration({
          Vue,
          tracing: true,
        }),
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: 1.0,
    })

    // if (store.getters.getterLoggedUser) {
    //   Sentry.setUser({
    //     "id": store.getters.getterLoggedUser.id,
    //     "fullName": store.getters.getterLoggedUser.fullName,
    //     "email": store.getters.getterLoggedUser.email,
    //     "type": store.getters.getterLoggedUserType
    //   });
    // }
  }

})()