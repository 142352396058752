<template>
  <p :class="[difficultyLabel.color]">{{difficultyLabel.label}}</p>
</template>

<script>
export default {
  name: 'GameDifficultyLabel',
  props: ['difficulty'],
  computed: {
    difficultyLabel() {
      if(this.difficulty == 'easy') return { label: 'Fácil', color: 'text-blue'}
      if(this.difficulty == 'normal') return { label: 'Normal', color: 'text-green'}
      return { label: 'hard', color: 'text-red'}
    }
  }
}
</script>