<template>
  <div class="block w-full relative mt-2">
    <div class="relative">
      <div class="w-full flex whitespace-no-wrap mb-1 pl-2">
        <label class="text-lg text-yellow" :for="getId" v-if="label">{{ label }}</label>
        <div class="text-red absolute top-0 right-0 text-lg pl-2" v-if="validation.hasError && validation.label">{{validation.label}}</div>
        <div class="absolute top-0 right-0 text-red w-full text-lg ml-2 w-3vh h-3vh rounded-full flex items-center justify-center bg-green text-white" v-if="validation.checked && validation.filled">
          <feather class="text-white" type="check" size="16"></feather>
        </div>
      </div>
      <input
        v-if="!mask"
        :type="getType"
        :id="getId"
        :autocapitalize="autocapitalize"
        :class="[
          validation.hasError ? 'input-has-error' : '', 
          value ? 'filled' : '',
          inputClasses
        ]"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :placeholder="placeholder || label"
        :disabled="disabled"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        class="w-full text-center appearance-none outline-none text-yellow text-2xl py-2 px-3 focus:border-yellow rounded"
      />
      <input
        v-else
        :type="getType"
        :id="getId"
        :autocapitalize="autocapitalize"
        :class="[
          validation.hasError ? 'input-has-error' : '', 
          value ? 'filled' : '',
          inputClasses
        ]"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :placeholder="placeholder || label"
        :disabled="disabled"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        v-mask="mask"
        class="w-full text-center appearance-none outline-none text-yellow text-2xl py-2 px-3 focus:border-yellow rounded"
      />
      <button 
        v-if="type === 'password'" 
        @click="showPassword = !showPassword"
        :class="[showPassword ? 'text-yellow' : 'text-black', validation.hasError ? 'text-red' : '']"
        class="h-full absolute pin-y top-0 right-0 mr-3 flex items-center pl-2 focus:outline-none">
        <feather :type="showPassword ? 'eye' : 'eye-off'" size="18"></feather>
      </button>
    </div>
  </div>
</template>

<script>

import { StringHelpers } from '@igortrindade/lazyfy'
export default {
  name: "InputGame",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    action: {
      type: Function,
      required: false
    },
    mask: {
      type: [String, Array],
      default: ''
    },
    autocapitalize: {
      type: String,
      default: 'sentences'
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      default: false
    },
    disabled: {
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    },
    inputClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    getType() {
      if(this.type !== 'password') return this.type
      if(this.type === 'password' && this.showPassword) return 'text'
      return this.type
    },

    getId() {
      if(this.id) return this.id
      return StringHelpers.randomString(12)
    }
  },
  methods: {
    makeAction() {
      if(this.action && typeof(this.action) == 'function') this.action()
    }
  }
};
</script>

<style>

  input:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
</style>
