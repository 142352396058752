import ProjectList from '@/modules/admin/project/ProjectList'
import ProjectForm from '@/modules/admin/project/ProjectForm'
import ProjectShow from '@/modules/admin/project/ProjectShow'
import ProjectUserList from '@/modules/admin/project/user-list/ProjectUserList'

export default [
  {
    path: 'projects',
    name: 'ProjectList',
    component: ProjectList,
    meta: {
      breadcrumb: 'Projetos / Lista'
    }
  },
  {
    path: 'projects/add',
    name: 'ProjectAdd',
    component: ProjectForm,
    meta: {
      breadcrumb: 'Projetos / Adicionar'
    }
  },
  {
    path: 'projects/update/:projectId',
    name: 'ProjectUpdate',
    component: ProjectForm,
    meta: {
      breadcrumb: 'Projetos / Editar'
    }
  },
  {
    path: 'projects/show/:projectId',
    name: 'ProjectShow',
    component: ProjectShow,
    meta: {
      breadcrumb: 'Projetos / Visualizar'
    }
  },
  {
    path: 'projects/lista-usuarios/:projectId',
    name: 'ProjectUserList',
    component: ProjectUserList,
    meta: {
      breadcrumb: 'Projetos / Lista de usuários'
    }
  },
]