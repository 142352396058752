<template>
  <div class="w-full">
    <label v-if="label" class="block mb-1">{{label}}</label>
    <label
      class="w-full py-1 px-2 border border-yellow rounded block bg-white flex items-center text-grey relative"
      :class="disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
    >
      <feather type="upload" class="mr-3 text-yellow flex-shrink-0"></feather>
      <span class="whitespace-no-wrap ellipsis" :class="value && value.name ? 'text-black font-bold' : ''">
        <span v-if="value && value.name">{{value.name}}</span>
        <span v-else>Arquivo</span>
      </span>
      <input
        tabindex="0"
        type="file"
        class="hidden-input-file"
        :accept="accept"
        :disabled="disabled"
        @change="addFile($event)"
        name="file"
        data-title="Clique para selecionar ou arraste aqui"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputFile',
  props: {
    value: {
      type: [Object, File, String]
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/png'
    },
    label: {
      type: String,
      default: 'Label'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileName: ''
    }
  },
  methods: {
    addFile(event) {
      if (event.target.files && event.target.files[0]) {
        this.$emit('input', event.target.files[0])
      }
    }
  }
}
</script>

<style>

.hidden-input-file {
  opacity: 0 !important;
  z-index: -1;
  position: absolute;
}
</style>