<template>
  <BaseModal
    ref="baseModal"
    modalDialogClasses="max-w-sm bg-white text-black"
    @onCloseModal="close()"
  >
    <fieldset class="w-full mt-6 text-black" >
      <legend class="h4 font-semi-bold mb-2">Adicionar ou editar dicas</legend>
      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-4 w-full"
          label="Título (opcional)"
          placeholder="Digite aqui"
          v-model="tip.title"
        ></InputWithLabel>
        <Textarea
          class="mt-4 w-full"
          label="Conteúdo (opcional)"
          placeholder="Digite aqui"
          v-model="tip.content"
        ></Textarea>
      </div>
      <div class="w-full flex items-end flex-wrap mt-4">
        <InputFile
          class="lg:max-w-50/100"
          label="Imagem"
          v-model="tip.file"
        ></InputFile>
        <button 
          @click="clearFile(tip, 'file')"
          class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
          Excluir
        </button>
      </div>

      <div class="w-full text-base mt-4" v-if="tip.hasError" v-html="tip.validationPhraseHtml"></div>

      <div class="w-full flex mt-4">
        <button :disabled="tip.hasError" @click="store()" class="button button-yellow px-8">
          Salvar
        </button>
      </div>
    </fieldset>
  </BaseModal>
</template>

<script>
import Tip from '@/models/Tip'
import { radioBooleanOptions } from '@/models/Enums'
export default {
  name: "QuestionQuizQuestionOptionModal",
  props: ['type'],
  data() {
    return {
      tip: new Tip(),
      radioBooleanOptions
    }
  },
  mounted() {
    
    this.$eventBus.$on('openTipModal', ({ id = null } = {}) => {
      this.tip.item_id = this.$route.params.quizQuestionId || this.$route.params.gameId
      if(this.$route.params.quizQuestionId) {
        this.tip.item_type = 'QuizQuestion'
      } else {
        this.tip.item_type = 'Game'
      }
      this.$refs.baseModal.showModal = true
      this.tip.id = id
      if(id) this.getTip()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openTipModal')
  },
  methods: {

    getTip() {
      const query =  `
        {
          tip (
            findBy: { column: "id", value: "${ this.tip.id }"}
          ) {
            id
            item_id
            order
            title
            content
            file_url
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.tip = new Tip(data.tip)
        })
    },

    store() {
      const url = this.tip.id ? `/admin/tips/update/${this.tip.id}` : `/admin/tips/store`
      this.$api.post(url, this.tip.getFormData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success(`Item salvo com sucesso`)
          this.close()
          this.$emit('updated')
        })
    },

    close() {
      this.tip = new Tip()
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>