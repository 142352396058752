<template>
  <div class="w-full flex flex-wrap items-stretch">
    <div 
      class="w-50/100 h-30vh p-2"
      v-for="(option, index) in currentQuizQuestion.options"
      :key="option.id"
    >
      <button
        :class="[index === optionSelectedIndex ? 'bg-black' : 'bg-smoke-yellow']"
        class="w-full h-full p-2 px-3 rounded border border-yellow border-only-corner relative flex items-center justify-start">
        <span></span>
        <span class="-mb-1 h-full flex items-center text-3xl uppercase text-white font-bold self-start" :class="[`text-${geniusColors[index]}`]">{{ geniusColorsLabels[index] }}</span>
        <span class="text-white ml-2">{{option.content}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { getLetterNumber } from '@/util/Functions'
export default {
  name: 'GameGeniusTeamBoard',
  props: {
    currentQuizQuestion: {
      required: true
    },
    optionSelectedIndex: {
      required: true
    }
  },
  data() {
    return {
      geniusColors: ['green', 'red', 'yellow', 'blue'],
      geniusColorsLabels: ['verde', 'vermelho', 'amarelo', 'azul'],
    }
  },
  methods: {
    getLetterNumber(number) {
      return getLetterNumber(number)
    },
  }
}
</script>

<style>

</style>