const BaseModel = class {

  get errors(){
    return this.requireds.filter((req) => {

      if(typeof (this[req.item]) == 'boolean' && !this[req.item]) {
        return false
      }

      if (typeof (req.validation) == 'function') {
        return req.validation(this[req.item], this)
      }

      if (
        typeof (this[req.item]) == 'string' && !this[req.item].length
        || Array.isArray(this[req.item]) && !this[req.item].length
        || typeof (this[req.item]) == 'object' && !this[req.item]
      ) {
        return true
      }

      return false
    })
  }

  get hasError(){
    return (this.errors.length) ? true : false
  }

  get errorPhrase(){
    return {
      init: 'Você precisa informar ',
      end: ' para continuar.'
    }
  }

  get validationPhrase(){
    return this.errorPhrase.init + this.errors.map((erro) => erro.label).join(', ') + this.errorPhrase.end;
  }

  get validationPhraseHtml(){
    return `${this.errorPhrase.init} <b>${this.errors.map((erro) => erro.label).join(', ')}</b> ${this.errorPhrase.end}`;
  }

  get validationBasePhrase() {
    return ''
  }

  getBaseValidationPhrase() {
    return `obrigatório`
  }

  validation = (input) => {
    const requiredFinded = this.errors.filter((item) => item.item == input)
    if (!requiredFinded.length) return { hasError: false, checked: true, filled: true, required: true, item: null, label: null }
    return { hasError: true, checked: false, filled: true, required: true, item: requiredFinded[0].item, label: this.getBaseValidationPhrase(requiredFinded[0].label) }
  }
}

module.exports = BaseModel
