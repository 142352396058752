<template>
  <div class="w-full mb-2 border mb-4 p-4 text-white flex flex-col space-y-6">

    <div class="w-full flex justify-between">
      <h4>Time: {{ team.name }}</h4>
      <h4 class="text-green">Players disponíveis</h4>
    </div>
    <div class="w-full flex flex-wrap space-x-2 overflow-x-auto">
      <div class="flex flex-col justify-center" v-for="player_project in playersAvailableToLogin" :key="player_project.id">
        <h4 class="text-white">{{player_project.player.name}}</h4>
        <button 
          class="text-yellow border border-yellow rounded p-2" 
          @click="$emit('setPin', player_project.pin)"
        >
          {{ player_project.pin }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrayHelpers } from '@igortrindade/lazyfy'
export default {
  props: {
    team: Object,
    project: Object
  },
  data() {
    return {
      room: {
        roomId: '',
        projectSlug: '', 
        teamId: '',
        players: []
      }
    }
  },
  computed: {
    roomId(){
      return `room:projectSlug:${this.project.slug}:teamId:${this.team.id}`
    },
    playersAvailableToLogin() {
      return this.team.players_project.filter((player_project) => {
        return !ArrayHelpers.find(this.room.players, { playerId: player_project.player.id })
      })
    }
  },
  mounted() {
    this.watchRoom()
    window.$socket.on('connect', () => {
      this.watchRoom()
    })

    this.$socket.on(this.roomId, ({ room }) => {
      if(room) this.room = room
    })
  },
  methods: {
    watchRoom() {
      this.$socket.emit('watchRoom', { projectSlug: this.project.slug, teamId: this.team.id })
    }

  }
}
</script>

<style>

</style>