import { connect }  from 'twilio-video'
import Api from '@/util/Api'
import Store from '@/mainVuex'

import { handleLocalParticipantVideo, handleTrackSubscribed, handleTrackUnsubscribed, createVideoTrack, createAudioTrack } from './twilioFunctions'


export default {

  
  async getTwilioPlayerToken(context) {
    
    try {

      const roomId = context.getters.getterRoom.roomId
      const playerId = context.getters.getterPlayer.id
      
      const { data } = await Api.post('/twilio/token', { playerId, roomId })
      
      context.commit('setTwilioPlayerToken', { token: data.token })
      context.dispatch('connectPlayer', { playerId, roomId })
      
    } catch (error) {
      console.log(error)
    }
  },

  async connectPlayer(context) {

    try {

      console.log(`Conectando player à twilio: ${Store.getters.getterPlayer.name}`)
      const roomId = context.getters.getterRoom.roomId
      
      const localAudioTrack = await createAudioTrack(context)
      const localVideoTrack = await createVideoTrack(context)

      const room = await connect(context.getters.getterTwilioPlayerToken, { name: roomId, tracks: [localAudioTrack, localVideoTrack], dominantSpeaker: true })

      window.$room = room

      handleLocalParticipantVideo(context)

      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
        participant.on('trackSubscribed', track => {
          handleTrackSubscribed(participant, track)
          handleLocalParticipantVideo(context)
        })
        participant.on('trackUnsubscribed', track => {
          handleTrackUnsubscribed(participant, track)
          handleLocalParticipantVideo(context)
        })
      })

      // Log new Participants as they connect to the Room
      room.on('participantConnected', participant => {
        participant.on('trackSubscribed', track => {
          handleTrackSubscribed(participant, track)
          handleLocalParticipantVideo(context)
        })
        // participant.on('trackUnsubscribed', track => {
        //   handleTrackUnsubscribed(participant, track)
        //   handleLocalParticipantVideo(context)
        // })
      })

      // Log Participants as they disconnect from the Room
      // room.once('participantDisconnected', participant => {
      //   const element = document.getElementById(participant.identity)
      //   if(element) {
      //     element.remove()
      //   }
      // })

      // Highlight dominant speaker
      room.on('dominantSpeakerChanged', participant => {
        if(participant) {
          context.commit('setRoomDominantSpeaker', participant.identity )
        } else {
          context.commit('setRoomDominantSpeaker', null )
        }
      })

      // Disconnected
      room.on('disconnected', room => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach()
          attachedElements.forEach(element => element.remove())
        })
      })
      
    } catch (error) {
      console.error(error.message)
    }
      
  },

  async toggleAudio(context) {

    window.$room.localParticipant.audioTracks.forEach(publication => {
      if(publication.track.isEnabled) {
        publication.track.disable()
        context.commit('setAudioIsOn', false)
        window.$socket.emit('handleAudioAndVideo', { source: 'audio', status: false })
      } else {
        publication.track.enable()
        context.commit('setAudioIsOn', true)
        window.$socket.emit('handleAudioAndVideo', { source: 'audio', status: true })
      }
    })
  },

  async toggleVideo(context, { forceStop = false } = {}) {

    if(Array.from(window.$room.localParticipant.videoTracks.values()).length || forceStop) {
      window.$room.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop()
        publication.unpublish()
        const attachedElements = publication.track.detach()
        attachedElements.forEach(element => element.remove())
        context.commit('setVideoIsOn', false)
        window.$socket.emit('handleAudioAndVideo', { source: 'video', status: false })
      })
    } else {
      const localVideoTrack = await createVideoTrack(context)
      await window.$room.localParticipant.publishTrack(localVideoTrack)
      context.commit('setVideoIsOn', true)
      window.$socket.emit('handleAudioAndVideo', { source: 'video', status: true })
      handleLocalParticipantVideo(context)
    }
  },

  async changeVideoDevice(context, { deviceId }) {
    const localVideoTrack = await createVideoTrack(context, deviceId)
    handleLocalParticipantVideo(context, localVideoTrack)
  },

  async changeAudioDevice(context, { deviceId }) {
    await createAudioTrack(context, deviceId)
  },

  

}
