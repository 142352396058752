<template>
  <div class="flex items-center">
    <span class="w-4 h-4 block rounded-full mr-2" :class="difficultyLabel.color"></span>
    <p>{{difficultyLabel.label}}</p>
  </div>
</template>

<script>
export default {
  name: 'GameDifficultyLabel',
  props: ['status'],
  computed: {
    difficultyLabel() {
      if(this.status == 'future') return { label: 'Futuro', color: 'bg-yellow'}
      if(this.status == 'online') return { label: 'Online', color: 'bg-green'}
      return { label: 'Executado', color: 'bg-blue'}
    }
  }
}
</script>