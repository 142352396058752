import Api from '@/util/Api'

export default {
  setFinishTeamGame(context, { team }) {
    Api.post('/game/room/finish_team_games', { project_id: team.project_id, team_id: team.id })
      .then(() => {
        context.commit('setTeamIsFinished')
        context.dispatch('refreshLoggedPlayer')
      })
  }
}