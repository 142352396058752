import Api from '@/util/Api'
import EventBus from '@/util/EventBus'
import MainRouter from '@/mainRouter'

export default {

  attemptReportPlayerLogin(context, { two_factor_code, email, projectSlug }) {
    Api.post(`/game/player/auth/check_two_factor_code`, { two_factor_code, email, projectSlug }, { loader: true })
    .then((response) => {

      const { token } = response.data

      context.commit('setLoggedUserToken', { token: token, type: 'player' })
      context.dispatch('getReportLoggedPlayer', { projectSlug, redirect: true })

    })
    .catch((err) => {
      if(err.response.status == 403) {
        EventBus.$emit('openGameToast', { title: 'Ops!', message: err.response.data.message, duration: 5000 })
      }

      if(err.response.status == 404) {
        EventBus.$emit('openGameToast', { title: 'Ops!', message: err.response.data.message, duration: 5000 })
      }
    })
  },
  
  getReportLoggedPlayer(context, { projectSlug, redirect }) {
    Api.get(`/player_project/get_logged_player/${projectSlug}`)
    .then(({ data }) => {
      
      const { player, project, playerProject, team } = data
      
      context.commit('setProject', { project })
      context.commit('setPlayer', { player })
      context.commit('setTeam', { team })
      context.commit('setPlayerProject', { playerProject })

      const userType = player.is_manager ? 'gerencial' : 'time'

      if(redirect) {
        MainRouter.push({ name: 'ReportShow', params: { userType, projectSlug }}, () => {})
      }
      
    })
    .catch((err) => {
      console.log(err)
      if (err.response && [401, 403].includes(err.response.status)) {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Usuário bloqueado pela plataforma'})
        context.dispatch('logoutUser', { showNotification: true, redirect: true })
      } else {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Erro ao efetuar login, verifique sua conexão'})
      }
    })
  }


}
