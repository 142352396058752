import dayjs from 'dayjs'
import MainVuex from '@/mainVuex'
export default {
  getterGame(state) {
    return state.game
  },
  getterTotalGameTime(state, getters) {
    if(!getters.getterGame) return false
    const currentGameTimeInSeconds = dayjs(getters.getterServerTime).diff(getters.getterTeam.current_game_started_at, 'seconds')
    const resolutionTimeMinusPenalties = (60 * getters.getterGame.resolution_time) - getters.getterTeam.current_game_penalties_in_seconds
    const gameAvailableTime = (resolutionTimeMinusPenalties - currentGameTimeInSeconds) 
    if(gameAvailableTime < 0) return '00:00:00'
    const duration = dayjs.duration(gameAvailableTime, "seconds")
    return duration.format('HH:mm:ss')
  },
  getterGameIsFinished(state, getters) {
    if(getters.getterTotalGameTime == '00:00:00') return true
    return false
  },
  getterTotalTeamTime(state, getters) {
    const currentGameTimeInSeconds = dayjs(getters.getterServerTime).diff(getters.getterTeam.games_started_at, 'seconds')
    const resolutionTimeMinusPenalties = (60 * getters.getterProject.total_time) - getters.getterTeam.total_penalties_in_seconds
    const gameAvailableTime = (resolutionTimeMinusPenalties - currentGameTimeInSeconds) 
    if(gameAvailableTime < 0) {
      if(!getters.getterTeam.is_finished) MainVuex.dispatch('setFinishTeamGame', { team: getters.getterTeam })
      return '00:00:00'
    }
    const duration = dayjs.duration(gameAvailableTime, "seconds")
    return duration.format('HH:mm:ss')
  },
  getterProjectIsFinished(state, getters) {
    if(getters.getterTotalTeamTime == '00:00:00') return true
    return false
  },
}