<template>
  <div class="w-full flex" >
    <label class="inline-flex items-center cursor-pointer mr-3" v-for="(item, index) in items" :key="`${item.label}:${index}`">
      <input type="radio" class="hidden" v-model="childValue" :value="item.value" />
      <span class="w-3vh h-3vh flex-shrink-0 rounded-full relative flex items-center justify-center block text-white cursor-pointer" :class="childValue == item.value ? `bg-${bg}` : `border border-${bg}`">
        <feather type="check" size="18" v-if="childValue == item.value"></feather>
      </span>
      <span class="ml-3 text-gray04 cursor-pointer">{{item.label}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      required: true
    },
    items: {
      type: Array,
    },
    label: {
      type: String,
      default: ''
    },
    bg: {
      type: String,
      default: 'yellow'
    }
  },
  computed: {
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue )
      }
    }
  }
}
</script>