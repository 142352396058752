<template>
  <div 
    class="w-full flex flex-col rounded-lg border-3 shadow-sm max-w-20vw"
    :style="{ borderColor: `${chartAvailableColors[index]}50` }"
  >

    <div class="p-2 flex flex-col justify-between">
      <div class="w-full">
        <h2 class="w-full text-center font-bold text-2xl" :style="{ color: `${chartAvailableColors[index]}`}">{{ item.generation }}</h2>
        <h2 class="w-full text-center text-grey mt-1">{{ generationInfo.born_between }}</h2>
      </div>

      <div class="w-full flex justify-center">
        <div class="w-full flex items-center justify-center relative h-20vh w-20vh">
          <div class="w-20vh" ref="generationChart"></div>
          <div class="inset-0 absolute z-20 flex items-center justify-center">
            <span class="pl-1 font-bold text-grey">{{ item.percentage}}%</span>
          </div>
        </div>
      </div>
      <h2 class="w-full text-center text-grey">{{ generationInfo.text }}</h2>
    </div>

    <div v-if="showMarkers" class="w-full bg-smoke-extra-light h-40/100 p-2 rounded-b-lg relative flex flex-col mt-auto relative">
      <div class="border-generation-card-markers" :style="{ backgroundColor: `${chartAvailableColors[index]}`}"></div>
      <h2 class="w-full text-center font-bold" :style="{ color: `${chartAvailableColors[index]}`}">
        Marcos da geração
      </h2>

      <div class="w-full flex flex-col mt-2 space-y-2">
        <div class="flex items-center" v-for="mark in generationInfo.markers" :key="mark">
          <div class="marker-ball" :style="{ backgroundColor: `${chartAvailableColors[index]}`}"></div>
          <p class="ml-2 text-base">{{mark}}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import getGenerationInfo from '@/util/getGenerationInfo'
import { chartAvailableColors } from '@/models/Enums'
import ApexCharts from 'apexcharts'
export default {
  name: 'GenerationCard',
  props: ['item', 'index', 'showMarkers'],
  data() {
    return {
      chartAvailableColors
    }
  },
  computed: {
    generationInfo() {
      return getGenerationInfo(this.item.generation)
    }
  },
  mounted() {
    this.mountChart()
  },
  methods: {
    mountChart() {
      var options = {
        chart: {
          type: 'radialBar',
        },
        colors: [chartAvailableColors[this.index]],
        series: [this.item.percentage],
        labels: [this.item.generation],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 10,
              size: '70%',
          },
            dataLabels: {
              show: false
            }
          }
        },
      }

      const chart = new ApexCharts(this.$refs.generationChart, options)
      chart.render()
    }
  }
}
</script>

<style>
  .marker-ball {
    margin-top: 4px;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 100%;
    flex: none;
  }


  .border-generation-card-markers {
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    width: 40px;
    height: 4px;
    display: block;
    z-index: 10;
    margin-top: -2px;
  }

</style>