<template>
  <div class="w-full h-screen flex flex-wrap items-center justify-center my-auto bg-yellow p-3">
    <BaseLoader></BaseLoader>
    <div class="max-w-sm w-full flex">
      <div class="w-full p-6 bg-white shadow rounded">
        
        <div class="w-full flex justify-center">
          <img src="/img/logo_ideia.svg" width="144px" alt="Ideia ativada logo" />
        </div>

        <router-link :to="{ name: 'AdminLogin' }" class="mt-6 button button-yellow w-full">
          Área administrativa
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameHome',
  metaInfo () {
    return {
      title: `Landing page |`
    }
  }
}
</script>

<style>

</style>