export const reportPlayerComponents = [
  { slug: 'home', title: null, component: 'ReportHome' },
  { slug: 'resumo', title: null, component: 'Report02' },
  { slug: 'diversidade', title: 'Diversidade', component: 'Report03' },
  { slug: 'visao-de-geracoes', title: 'Visão de gerações', component: 'Report04' },
  { slug: 'visao-geracoes-x-escolatidade-x-idioma', title: 'Visão de gerações x Escolaridade x Idioma', component: 'Report05' },
  { slug: 'performance-no-jogo', title: 'Performance de assertividade quando líder de partidas', component: 'Report06' },
  { slug: 'qualidade-de-vida-em-home-office', title: 'Qualidade de vida em Home Office: Trajeto da Residência à Empresa', component: 'Report07' },
  { slug: 'mapa-vulnerabilidade-de-trajeto', title: 'Mapa de Vulnerabilidade de Trajeto', component: 'Report08' },
  { slug: 'tempo-de-descolamento', title: 'Qualidade de vida em Home Office. Trajeto da residência à empresa distância e tempo', component: 'Report09' },
  { slug: 'report-10', title: 'Report10', component: 'Report10' },
]

export const reportManagerComponents = [
  { slug: 'home', title: null, component: 'ReportHome' },
  { slug: 'resumo', title: null, component: 'Report02' },
  { slug: 'diversidade', title: 'Diversidade', component: 'Report03' },
  { slug: 'visao-de-geracoes', title: 'Visão de gerações', component: 'Report04' },
  { slug: 'visao-geracoes-x-escolatidade-x-idioma', title: 'Visão de gerações x Escolaridade x Idioma', component: 'Report05' },
  { slug: 'performance-no-jogo', title: 'Performance de assertividade quando líder de partidas', component: 'Report06' },
  { slug: 'qualidade-de-vida-em-home-office', title: 'Qualidade de vida em Home Office: Trajeto da Residência à Empresa', component: 'Report07' },
  { slug: 'mapa-vulnerabilidade-de-trajeto', title: 'Mapa de Vulnerabilidade de Trajeto', component: 'Report08' },
  { slug: 'tempo-de-descolamento', title: 'Qualidade de vida em Home Office. Trajeto da residência à empresa distância e tempo', component: 'Report09' },
  { slug: 'report-10', title: 'Report10', component: 'Report10' },
]