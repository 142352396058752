<template>
  <div
    :class="[(sideMenuIsOpen) ? 'menu-open w-full h-screen bg-white' : '']"
    class="min-w-24 text-grey side-menu-container items-start max-w-full sm:max-w-40/100 absolute sm:static flex flex-col overflow-y-hidden sm:shadow-sm z-10">
    
    <div class="w-full h-20 p-2 pt-2 pl-4 menu-control" :class="!sideMenuIsOpen ? 'hidden sm:flex' : 'flex'">
      <button
        v-if="sideMenuIsOpen"
        @click="close()"
        aria-label="Close side menu"
        class="cursor-pointer focus:outline-none flex text-black"
      >
        <feather type="arrow-left"></feather>
      </button>
      <button
        v-else
        @click="open()"
        aria-label="open side menu"
        class="cursor-pointer focus:outline-none text-black hidden sm:flex"
      >
        <feather type="menu"></feather>
      </button>
    </div>

    <div class="side-menu-content h-full w-full" :class="sideMenuIsOpen ? 'block' : 'hidden sm:block'">
      <div class="flex-col flex items-left w-full pl-4 pr-4">
        <span
          class="my-2 h-10 text-left flex items-center text-black hover:text-yellow font-bold uppercase logged-user">
          <feather type="user"></feather>
          <span v-if="sideMenuIsOpen" class="ml-2">{{$store.getters.getterLoggedUser.name}}</span>
        </span>
        <router-link
          v-for="item in items" 
          :key="item.route"
          :to="{ name: item.route }"
          :class="[($route.name.toLowerCase().includes(item.group.toLowerCase())) ? 'text-yellow' : '']"
          class="my-2 h-10 text-left flex items-center text-grey hover:text-yellow item-menu" :aria-label="item.label">
          <feather 
            v-if="item.icon" 
            :type="item.icon"
          ></feather>
          <span v-else>
            <img v-if="$route.name.toLowerCase().includes(item.group.toLowerCase())" :src="item.path.replace('.svg', '_yellow.svg')" width="32px" alt=""/>
            <img v-else :src="item.path" width="32px" alt=""/>
          </span>
          <span v-if="sideMenuIsOpen" class="ml-2">{{item.label}}</span>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AdminSideBar',
  data() {
    return {
      sideMenuIsOpen: false,
      items: [
        { label: 'Clientes', route: 'CompanyList', icon: 'users', group: 'company' },
        { label: 'Projetos', route: 'ProjectList', icon: 'file', group: 'project' },
        { label: 'Configuração games', route: 'GameList', path: '/icons/gamepad.svg', group: 'game' },
        { label: 'Sair', route: 'AdminLogin', icon: 'log-out', group: 'logout' },
      ]
    }
  },
  mounted() {
    if(window.innerWidth > 798) {
      this.open()
    }
    this.$eventBus.$on('openSideMenu', ({ open }) => {
      this.sideMenuIsOpen = open
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openSideMenu')
  },
  methods: {
    ...mapActions(['logoutUser']),

    close() {
      this.sideMenuIsOpen = false
    },
    open() {
      this.sideMenuIsOpen = true
    }
  }
}
</script>

<style>

  .side-menu-container {
    min-width: 60px;
  }
  .side-menu-container.menu-open {
    width: 300px !important;
  }

  @media screen and ( max-width: 576px) {
    .side-menu-container.menu-open {
      width: 100% !important;
    }
  }

  @media screen and ( min-width: 576px) {
    .side-menu-container {
      background-color: #FFF;
    }
  }

  

</style>
