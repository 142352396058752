import Api from '@/util/Api'
import EventBus from '@/util/EventBus'
import MainRouter from '@/mainRouter'

export default {

  attemptUserLogin(context, { email, password }) {
    Api.post(`/auth/login`, { email, password }, { loader: true })
    .then((response) => {
      const {token} = response.data
      context.commit('setLoggedUserToken', { token })
      context.dispatch('getLoggedUser', { redirect: true })
    })
    .catch((err) => {
      if(err.response && err.response.status == 401) {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Usuário ou senha incorretos'})
      } else {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Erro ao efetuar login, verifique sua conexão'})
      }
    })
  },

  getLoggedUser(context, { redirect = false } = {}) {
    Api.get(`/auth/getLoggedUser`)
    .then(({ data }) => {
      const { user } = data
      context.commit('setLoggedUser', { user })
      EventBus.$emit('openToast', { title: 'Bem vindo', message: 'Logado com sucesso!' })
      if(!redirect) return
      let urlParams = new URLSearchParams(window.location.search)
      let redirectTo = urlParams.get('redirectTo')
      if(redirectTo){
        MainRouter.push(redirectTo, () => {})
      } else {
        MainRouter.push({ name: 'CompanyList' }, () => {})
      }

    })
    .catch((err) => {
      if (err.response && [401, 403].includes(err.response.status)) {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Usuário bloqueado pela plataforma'})
        context.dispatch('logoutUser', { showNotification: true, redirect: true })
      } else {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Erro ao efetuar login, verifique sua conexão'})
      }
    })
  },

  registerUser(context, { user }) {
    Api.post(`/auth/register`, user, {loader: true})
    .then((response) => {
      const { token } = response.data
      context.commit('setLoggedUserToken', { token })
      context.dispatch('getLoggedUser')
    })
    .catch((err) => {
      console.log(err)
      EventBus.$emit('openToast', { title: 'Ops!', message: 'Erro ao efetuar cadastro'})
    })
  },

  logoutUser(context, { showNotification = true, redirect = false } = {}) {
    context.commit('setLogout')
    if(showNotification) EventBus.$emit('openToast', { title: 'Use alcool em gel!', message: 'Deslogado com sucesso'})
    if(redirect) MainRouter.push('/', () => {})
  }


}
