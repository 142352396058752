<template>
  <div class="w-full">

    <fieldset class="w-full min-w-0">

      <div class="w-full relative">
        <legend class="h4 font-semi-bold mb-2">Lista de usuários</legend>
        <button 
          @click="sendInviteForAll()" 
          class="button button-border-green rounded-full absolute right-0 top-0"
        >Enviar convite para todos</button>
      </div>
      <div class="w-full flex items-end mt-6">
        <div class="w-full">
          <InputWithLabel
            class="w-full"
            label="Pesquise por nome ou time"
            v-model="search"
            @keydown.enter.native="getProjectPlayers()"
          ></InputWithLabel>
        </div>
        <button
          @click="getProjectPlayers()"
          class="ml-3 button button-yellow">
          Pesquisar
        </button>
      </div>
      <div class="w-full max-w-full overflow-x-auto mt-6">
        <table class="w-full table table-striped bg-white shadow-sm whitespace-no-wrap">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Time</th>
              <th>Pin</th>
              <th>É o líder agora</th>
              <th>Convite enviado</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="playerProject in project_players" :key="playerProject.Id">
              <td>{{ playerProject.name }}</td>
              <td>{{ playerProject.email }}</td>
              <td>
                <span>{{ playerProject.team.name }}</span>
              </td>
              <td>{{ playerProject.pin }}</td>
              <td><BooleanLabel :boolean="playerProject.is_leader"></BooleanLabel></td>
              <td>
                <button @click="sendPlayerInvite(playerProject)" 
                  class="button button-border-red rounded-full"
                  :class="{
                    'button-border-red' : !playerProject.invites_sent,
                    'button-border-green' : playerProject.invites_sent,
                  }"
                >
                  <span v-if="!playerProject.invites_sent">Não enviado</span>
                  <span v-else>{{playerProject.last_invite_sent_at}} ({{playerProject.invites_sent}})</span>
                </button>
              </td>
              <td class="flex">
                <button @click="playerSelectedToShow = playerProject" class="focus:outline-none">
                  <feather type="search"></feather>
                </button>
                <button @click="openRemovePlayerModal(playerProject)" class="focus:outline-none text-red ml-3">
                  <feather type="x"></feather>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>

    <Paginator
      class="mt-6"
      v-model="offset" 
      :length="project_players.length" 
      :limit="limit"
      @getItems="getProjectPlayers"
    ></Paginator>

    <ProjectUserModal v-if="playerSelectedToShow" v-model="playerSelectedToShow"></ProjectUserModal>

    <BaseModal ref="modalConfirmRemoveUser" modalDialogClasses="bg-white max-w-md text-black">
      <div class="text-black">
        <h4 class="w-full text-center font-bold">Atenção</h4>
        <div class="w-full flex flex-col items-center justify-center">
          <h4 class="text-center mt-4">Tem certeza que deseja remover este player?</h4>
          <div class="w-full flex justify-center mt-10">
            <button @click="$refs.modalConfirmRemoveUser.showModal = false" class="button button-border-yellow">
              Cancelar
            </button>
            <button @click="removePlayer()" class="button button-border-red ml-4">
              Sim
            </button>
          </div>
        </div>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import Paginator from '@/components/Paginator'
import ProjectUserModal from './ProjectUserModal'
export default {
  name: 'ProjectUserList',
  components: {  ProjectUserModal, Paginator },
  data() {
    return {
      project_players: [],
      playerSelectedToShow: null,
      playersToSelect: [],
      playerProjectIdSelected: null,
      search: '',
      offset: 0,
      limit: 20
    }
  },
  mounted() {
    this.getProjectPlayers()
    this.getPlayersToSelect()
  },
  methods: {

     getPlayersToSelect() {
      const query =  `
        {
          players {
            id
            name
            email
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.playersToSelect = data.players
        })
    },

    filterPlayers(options, search) {
      return options.filter((item) => {
        if(item.name.toLowerCase().includes(search.toLowerCase())) {
          return true
        }
        return false
      })
    },

    getProjectPlayers() {
      this.$api.get(`/admin/player_project/get_project_players/${this.$route.params.projectId}?search=${this.search}&limit=${this.limit}&offset=${this.offset}`)
        .then(({ data }) => {
          this.project_players = data.project_players
        })
    },

    sendPlayerInvite(projectPlayer) {
      this.$api.get(`/admin/player_project/send_invite/${projectPlayer.id}`)
        .then(() => {
          this.getProjectPlayers()
        })
    },
    sendInviteForAll() {
      this.$api.get(`/admin/player_project/send_invite_all/${this.$route.params.projectId}`)
        .then(({ data }) => {
          console.log(data)
        })
    },

    openRemovePlayerModal(player) {
      this.playerProjectIdSelected = player.id
      this.$refs.modalConfirmRemoveUser.showModal = true
    },

    removePlayer() {
      this.$api.get(`/admin/player_project/remove_player/${this.playerProjectIdSelected}`)
        .then(() => {
          this.$toast.success('Player removido com sucesso')
          this.getProjectPlayers()
          this.$refs.modalConfirmRemoveUser.showModal = false
        })
    },

    
  }
}
</script>

<style>

</style>