<template>
  <BaseModal
    ref="gamePuzzleAnswerModal" 
    modalDialogClasses="max-w-md bg-black"
  >
    <div class="w-full flex flex-col">
      <h2 class="w-full text-center font-bold text-xl">Game encerrado</h2>

      <p class="text-center my-2">Este game encerrou o tempo disponível, clique abaixo para prosseguir</p>
      
      <div class="w-full flex justify-center mt-3">
        <GameButton class="w-8vw" label="continuar" @click="redirectToNewStepOrGame()"></GameButton>
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'GameIsFinishedModal',
  computed: {
    getGame() {
      return this.$store.getters.getterGame
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getGameIsFinished() {
      return this.$store.getters.getterGameIsFinished
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
  },
  mounted() {
    this.$eventBus.$on('openGameIsFinishedModal', () => {
      this.$refs.gamePuzzleAnswerModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openGameIsFinishedModal')
  },
  methods: {
    redirectToNewStepOrGame() {
      this.$api.post(`/game/room/next_step_or_game`, {
        project_id: this.getProject.id, 
        game_id: this.getGame.id, 
        team_id: this.getTeam.id,
      })
    }
  }
}
</script>