<template>
  <div class="w-full flex items-center justify-between">
    <button
      @click="prev()" 
      :disabled="value <= 0"
      class="button-sm rounded bg-white shadow-sm">
        Anterior
    </button>
    <button
     @click="next()" 
     :disabled="length < limit"
      class="button-sm rounded bg-white shadow-sm">
        Próximo
    </button>
  </div>
</template>

<script>
export default {
  name: 'paginator',
  props: {
    length: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 12
    }
  },
  methods: {
    next() {
      const newOffset = this.value + this.limit
      this.$emit('input', newOffset)
      this.$emit('getItems')
    },
    prev() {
      if(this.offset <= 0) return false
      const newOffset = this.value - this.limit
      this.$emit('input', newOffset)
      this.$emit('getItems')
    }
  }
}
</script>
