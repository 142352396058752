<template>
  <ReportWithHeader>
    <div class="w-full h-full flex flex-col space-y-8">
      
      <div class="w-full h-full flex items-center space-x-3" v-for="(player, index) in players_distance_from_work" :key="player.id">
        <div class="w-5/100">
          <div class="w-5vh h-5vh block rounded-full" :style="{ backgroundColor: `${chartAvailableColors[index]}`}">
          </div>
        </div>
        <h3 class="w-10/100">{{player.title}}</h3>
        <div class="w-full flex">
          <div class="block h-5vh relative" :style="{ backgroundColor: `${chartAvailableColors[index]}`, width: `${player.percentage}%` }">
            <div class="top-0 right-0 h-full absolute flex items-center whitespace-no-wrap justify-end text-white font-bold">
              <span v-if="player.distance_from_work">{{ player.distance_from_work }}</span>
              <span v-else>{{ player.percentage }}% ({{ player.count }})</span>
            </div>
          </div>
          <div class="player-distance-from-work-bar-triangle" :style="{ borderColor: `transparent transparent transparent ${chartAvailableColors[index]}`}">
          </div>
        </div>
      </div>
    </div>
  </ReportWithHeader>
</template>

<script>
import { chartAvailableColors } from '@/models/Enums'
export default {
  name: 'Report07',
  data() {
    return {
      players_distance_from_work: [],
      chartAvailableColors
    }
  },
  mounted() {
    this.getPlayersDistanceFromWork()
  },
  methods: {
    getPlayersDistanceFromWork() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_distance_from_work')}`)
        .then(({ data }) => {
          this.players_distance_from_work = data.players_distance_from_work
        })
    }
  }
}
</script>

<style>

  .player-distance-from-work-bar-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2.5vh 0 2.5vh 1vh;
  }

</style>