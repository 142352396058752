<template>
  <div class="w-full max-w-full flex flex-wrap items-start" v-if="game.id">
    <fieldset class="w-full mt-6">
      <legend class="h4 font-semi-bold mb-2">Dados do game</legend>
      <div class="w-full flex flex-wrap justify-between items-end">
        <h2 class="font-bold">{{game.name}}</h2>
        <router-link
          :to="{ name: 'GameUpdate', params: { gameId: game.id }}"
          class="button button-border-green focus:outline-none">
          Editar configurações game
        </router-link>
      </div>

      <div class="w-full rounded bg-red text-white p-3 mt-6" v-if="getGameErrors.length">
        <span><strong>Atenção!</strong> Este game possui um ou mais erros no cadastro;</span>
        <ul class="mt-2">
          <li class="ml-1" v-for="(error, index) in getGameErrors" :key="index">- {{error}}</li>
        </ul>
      </div>
      <div class="w-full rounded bg-green text-white p-3 mt-6" v-else>
        <span><strong>Parabéns!</strong> Tudo certo com o cadastro deste game!</span>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-8"/>

    <GameQuizQuestionList @getGame="getGame" :game="game" v-if="['GameQuiz', 'GameGenius'].includes(game.component)"></GameQuizQuestionList>

    <div class="w-full" v-else>
      <GamePuzzleButtonList @getGame="getGame" :game="game"></GamePuzzleButtonList>
      <hr class="border-b-1 border-smoke-light w-full my-10"/>
      <GamePuzzleAnswerOptionList @getGame="getGame" :game="game"></GamePuzzleAnswerOptionList>
      <hr class="border-b-1 border-smoke-light w-full my-10"/>
      <GameTipList @updated="getGame" :item_id="game.id"></GameTipList>
    </div>

  </div>
</template>

<script>
import Game from '@/models/Game'
import GameQuizQuestionList from './quiz_question/GameQuizQuestionList'
import GamePuzzleAnswerOptionList from './puzzle/GamePuzzleAnswerOptionList'
import GamePuzzleButtonList from './puzzle/GamePuzzleButtonList'
import GameTipList from './tips/GameTipList'
export default {
  name: 'GameShow',
  components: { GameQuizQuestionList, GamePuzzleAnswerOptionList, GamePuzzleButtonList, GameTipList },
  data() {
    return {
      game: new Game()
    }
  },
  computed: {
    getGameErrors() {
      const errors = []
      if(['GameQuiz', 'GameGenius'].includes(this.game.component)) {
        if(!this.game.quiz_questions.length) errors.push(`Insira ao menos a pergunta no game`)
        for(const question of this.game.quiz_questions) {
          if(question.tips.length < 3) errors.push(`Pergunta (${question.title}) possui ${question.tips.length} dicas`)
          if(question.options.length != 4) errors.push(`Pergunta (${question.title}) possui ${question.options.length} opções e deve conter exatamente 4 opções`)
          let questionHasCorrectOption = false
          for(const option of question.options) {
            if(option.is_correct) questionHasCorrectOption = true
          }
          if(!questionHasCorrectOption) errors.push(`Pergunta (${question.title}) não possui opção correta de resposta`)
        }
      }

      if(this.game.component == 'GamePuzzle') {
        if(this.game.puzzle_buttons.length < 1) errors.push(`Game deve conter pelo menos 1 botão`)
        if(this.game.puzzle_buttons.length > 8) errors.push(`Game deve conter menos que 8 botões`)
        if(this.game.tips.length < 3) errors.push(`Game possui apenas ${this.game.tips.length} dicas`)
        if(this.game.puzzle_answer_options.length < 1) errors.push(`Game não possui opçoes de respostas`)
      }
      return errors
    }
  },
  mounted() {
    this.getGame()
  },
  methods: {

    getGame() {
      const query =  `
        {
          game (
            findBy: { column: "id", value: "${this.$route.params.gameId}"}
          ) {
            id
            name
            introduction_url
            difficulty
            resolution_time  
            resolution_block_after
            component
            quiz_questions {
              id
              title
              content
              options {
                id
                title
                is_correct
              }
              tips {
                id
                title
              }
            }
            puzzle_answer_options {
              id
              content
            }
            puzzle_buttons {
              id
              title
              content
              access
            }
            tips {
              id
              title
              item_id
              item_type
              content
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.game = new Game(data.game)
        })
    }

  }
}
</script>