
import Vue from 'vue'
import VueRouter from 'vue-router'
import MainVuex from './mainVuex'


/**
 * Modules Landing routes
 */
import landingHomeRoutes from '@/modules/landing/home/routes'

/**
 * Modules Game routes
 */
import gameRoutes from '@/modules/game/routes'

/**
 * Modules Admin routes
 */
import authRoutes from '@/modules/admin/auth/routes'
import adminHomeRoutes from '@/modules/admin/home/routes'
import adminCompanyRoutes from '@/modules/admin/company/routes'
import adminGameRoutes from '@/modules/admin/game/routes'
import adminProjectRoutes from '@/modules/admin/project/routes'
import reportRoutes from '@/modules/report/routes'

/**
* Components
*/
import AdminLayout from '@/modules/admin/layout/AdminLayout'
import LandingLayout from '@/modules/landing/layout/LandingLayout'


Vue.use(VueRouter)

const routes = [

  {
    path: '',
    component: LandingLayout,
    children: [
      ...landingHomeRoutes
    ]
  },

  
  ...gameRoutes,
  
  ...authRoutes,
  
  ...reportRoutes,
  
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      ...adminHomeRoutes,
      ...adminCompanyRoutes,
      ...adminGameRoutes,
      ...adminProjectRoutes,
    ]
  },

  {
    path: '*',
    redirect: { name: 'LandingHome' }
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  setTimeout(() => {
    window.scrollTo(0,0)
    let mainContent = document.getElementById('main-content')
    if(mainContent) mainContent.scrollTop = 0;
  }, 100)

  window.$eventBus.$emit('openSideMenu', { open: false})

  if(to.meta.auth && to.meta.auth.type == 'player' ) {

    let hasAccess = true

    if(!MainVuex.getters.getterProject) hasAccess = false
    if(MainVuex.getters.getterProject && MainVuex.getters.getterProject.slug != to.params.projectSlug) hasAccess = false

    if(!hasAccess) {
      alert('Você não tem acesso à este game')
      router.push('/')
    }
  }

  next()
})

export default router
