<template>
  <BaseModal
    ref="teamPlayersModal"
    title="Room Info"
    modalDialogClasses="max-w-lg bg-black text-white"
  >

    <div class="w-full flex flex-col items-start">

      <div class="w-full flex flex-col">
        <h5 class="text-yellow">Player</h5>
        <h3 class="text-2xl">{{ getPlayer.name }}</h3>
      </div>
      
      <div class="w-full flex flex-col mt-4">
        <h5 class="text-yellow">Time</h5>
        <h3 class="text-2xl">{{ getTeam.name }}</h3>
      </div>
      
      <h5 class="text-yellow mt-4">Player online</h5>
      <div class="w-full flex flex-col">
        <table class=" table-auto">
          <thead>
            <tr>
              <td>Player</td>
              <td>Player Project ID</td>
              <td>Líder</td>
              <td>Líder oculto</td>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-smoke" v-for="roomPlayer in getRoom.players" :key="roomPlayer.playerId">
              <td>{{ roomPlayer.playerName }}</td>
              <td>{{ roomPlayer.playerId }}</td>
              <td>{{ roomPlayer.isLeader }}</td>
              <td>{{ roomPlayer.playerIsHidden }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 class="text-yellow mt-4">Time completo</h5>
      <div class="w-full flex flex-col">
        <table class=" table-auto">
          <thead>
            <tr>
              <td>Player</td>
              <td>Player Project ID</td>
              <td>Líder</td>
              <td>Líder oculto</td>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-smoke" v-for="player_project in getTeamPlayersProject" :key="player_project.id">
              <td>{{ player_project.player.name }}</td>
              <td>{{ player_project.id }}</td>
              <td>{{ player_project.is_leader }}</td>
              <td>{{ player_project.is_hidden }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <router-link class="text-yellow mt-4" :to="{ name: 'GamePlayerAuth', params: { projectSlug: getProject.slug }}">
        Ir para login
      </router-link>

      <button @click="resetTeam()" class="top-0 bottom-0 bg-red text-white">
        Reiniciar time
      </button>
    </div>

  </BaseModal>
</template>

<script>
export default {
  name: 'TeamPlayersModal',
  computed: {
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getRoom() {
      return this.$store.getters.getterRoom
    },
    getTeamPlayersProject() {
      return this.$store.getters.getterTeamPlayersProject
    },
    getTeamPlayers() {
      return this.$store.getters.getterTeamPlayers
    },
  },
  methods: {
    resetTeam() {
      this.$api.get(`/system/reset_team/${this.getTeam.id}`)
        .then(() => {
          this.$router.push({ name: 'GameLobby', params: { projectSlug: this.getProject.slug, teamId: this.getTeam.id }})
          this.$gameToast({ title: 'Atenção', message: 'Time reiniciado com sucesso' })
        })
    }
  }
}
</script>