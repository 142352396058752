<template>
  <BaseGameLayout>
    <div class="w-full h-full px-8 flex flex-wrap items-start overflow-y-auto hide-scrollbar">
      <div class="w-full h-15vh relative flex justify-center items-center">
        <h1 class="text-center text-white text-4xl font-bold">Complete seu cadastro</h1>
      </div>

      <div class="w-full flex justify-center">
        <div class="w-full max-w-xl h-auto flex flex-col bg-yellow-smoke rounded p-4 mb-4 px-2" v-if="player">

          <div class="w-full flex flex-wrap">
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.mobile"
                label="Telefone"
                :mask="['(##) #####-####']"
                placeholder="(##) #####-####"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.birthday"
                label="Data de nascimento"
                :mask="['##/##/####']"
                placeholder="DD/MM/AAAA"
                :validation="player.validation('birthday')"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.cep"
                label="CEP"
                :mask="['#####-###']"
                placeholder="#####-###"
                :validation="player.validation('cep')"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.address"
                label="Endereço"
                placeholder="Rua, avenida, alameda..."
                :validation="player.validation('address')"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.country"
                label="País"
                placeholder="País"
                :validation="player.validation('country')"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.occupation"
                label="Ocupação"
                placeholder="Ocupação"
                :validation="player.validation('occupation')"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.role"
                label="Profissão"
                placeholder="Profissão"
                :validation="player.validation('role')"
              ></InputGame>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <InputGame
                v-model="player.time_in_service"
                label="Tempo em serviço"
                placeholder="Tempo em serviço"
                :validation="player.validation('time_in_service')"
              ></InputGame>
            </div>

            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.distance_from_work"
                :items="playerDistanceFromWork"
                label="Distância do trabalho"
                :validation="player.validation('distance_from_work')"
              ></SelectInput>
            </div>

            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.time_from_work"
                :items="playerTimeFromWork"
                label="Tempo de deslocamento (média)"
                :validation="player.validation('time_from_work')"
              ></SelectInput>
            </div>

            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.type_of_transport_to_work"
                :items="playerTypeOfTransportToWork"
                label="Tipo de transporte"
                :validation="player.validation('type_of_transport_to_work')"
              ></SelectInput>
            </div>

            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.race_color"
                :items="playerRaceColors"
                label="Qual sua cor ou raça?"
                :validation="player.validation('race_color')"
              ></SelectInput>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.education"
                :items="playerSchoolarship"
                label="Formação Acadêmica (nível máximo concluído)"
                :validation="player.validation('education')"
              ></SelectInput>
            </div>
            <div class="w-full md:w-50/100 px-3 block relative mt-2">
              <div class="w-full whitespace-no-wrap mb-1 flex flex-col">
                <div class="text-lg text-yellow ml-2">Línguas (selecione um ou mais)</div>
                <v-select 
                  class="vue-select"
                  label="language"
                  :options="playerLanguages"
                  v-model="player.languages"
                  :close-on-select="false"
                  :multiple="true"
                  :getOptionKey="option => option.language"
                  :closeOnSelect="true"
                >
                </v-select>
              </div>
            </div>

            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.gender"
                :items="playerGender"
                label="Qual seu sexo?"
                :validation="player.validation('gender')"
              ></SelectInput>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.gender_identity"
                :items="playerGenderIdentity"
                label="Qual sua identidade de gênero?"
                :validation="player.validation('gender_identity')"
              ></SelectInput>
            </div>
            <div class="w-full md:w-50/100 px-3">
              <SelectInput
                v-model="player.sex_orientation"
                :items="playerSexOrientation"
                label="Qual sua orientação sexual?"
                :validation="player.validation('sex_orientation')"
              ></SelectInput>
            </div>
            <div class="w-full md:w-50/100 px-3 mt-2">
              <div class="w-full whitespace-no-wrap mb-1 flex flex-col">
                <div class="text-lg text-yellow ml-2">Possui deficiência? Quais?</div>
                <v-select 
                  class="vue-select"
                  label="pcd"
                  :options="playerPcdOptions"
                  v-model="player.pcds"
                  :close-on-select="false"
                  :multiple="true"
                  :getOptionKey="option => option.pcd"
                  :closeOnSelect="true"
                >
                </v-select>

                <pre class="text-white">{{ player.pcd}}</pre>
              </div>
            </div>
          </div>

          <div class="w-full px-6" v-if="player.hasError">
            <p class="w-full text-center text-yellow mt-4 text-base">
              {{ player.validationPhrase }}
            </p>
          </div>

          <div class="w-full flex justify-end mt-8 h-10vh">

            <transition
              mode="out-in"
              enter-active-class="transition ease-out duration-200 transform"
              enter-class="opacity-0 translate-y-full"
              enter-to-class="opacity-100"
              leave-active-class="transition ease-in duration-200 transform"
              leave-class="opacity-100"
              leave-to-class="opacity-0 translate-y-full"
            >
              <GameButton label="Continuar" v-if="!player.hasError" @click="updatePlayer()"></GameButton>
            </transition>
          </div>

        </div>  
      </div>
    </div>
  </BaseGameLayout>
</template>

<script>
import { 
  playerSchoolarship, 
  playerGender, 
  playerGenderIdentity, 
  playerSexOrientation, 
  radioBooleanOptions,
  playerForeignLanguage,
  playerDistanceFromWork,
  playerTimeFromWork,
  playerTypeOfTransportToWork,
  playerLanguages,
  playerRaceColors,
  playerPcdOptions
} from '@/models/Enums'
import Player from '@/models/Player'

export default {
  name: 'GamePlayerForm',
  data() {
    return {
      player: new Player(),
      player_project: null,
      playerSchoolarship,
      playerGender,
      playerGenderIdentity,
      playerSexOrientation,
      radioBooleanOptions,
      playerForeignLanguage,
      playerLanguages,
      playerRaceColors,
      playerDistanceFromWork,
      playerTimeFromWork,
      playerTypeOfTransportToWork,
      playerPcdOptions
    }
  },
  computed: {
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
  },
  mounted() {
    this.getPlayerProject()
  },
  methods: {
    getPlayerProject() {
      this.$api.get(`/player_project/get/${this.$route.params.playerProjectId}`)
        .then(({ data }) => {
          this.player = new Player(data.player_project.player)
          this.player_project = data.player_project
        })
    },

    updatePlayer() {
      this.$api.post(`/game/player/update/${this.player.id}`, this.player)
        .then(() => {
          if(this.getTeam && this.getProject) {
            this.$router.push({ name: 'GameLobby', params: { projectSlug: this.getProject.slug, teamId: this.getTeam.id }})
          } else {
            this.$router.push({ name: 'GamePlayerAuth', params: { projectSlug: this.player_project.project.slug }})
          }
        })
    }
  }
}
</script>