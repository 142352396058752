<template>
  <div class="w-full h-full rounded flex items-stretch">

    <div class="w-35/100 rounded-l text-white relative z-10 shadow">

      <div class="conector-ball"></div>
      <div class="w-full h-full bg-purple rounded-l p-10 flex flex-col absolute">

        <img src="/reports/img_capa.svg" width="90%" class="mb-6"/>
        <h3 class="text-xl italic font-light">Nossa mensagem é:</h3>
        <h1 class="text-6xl mt-6">Ouça <br/>visualmente</h1>
        
        <div class="mt-auto w-full">
          <div class="border-b-3 border-white w-4vw"></div>
          <h4 class="text-sm italic text-white mt-4">Tecnologia e emoção</h4>
          <h4 class="text-sm italic ">Atenção, energia, conteúdo e inovação</h4>
        </div>
      </div>
    </div>
    <div class="w-65/100 rounded-r h-full flex flex-col relative bg-white p-10">
      <h1 class="font-semi-bold text-5xl text-orange">Nossos diferenciais:</h1>
      <h4 class="font-semi-bold mt-6">Técnica de antecipar verdades</h4>
      <h4 class="font-semi-bold mt-2">Laboratório de inteligência de vida</h4>
      <h4 class="font-semi-bold mt-2">Estimulamos à inteligência lógica tanto quanto a inteligência emocional</h4>

      
      <h1 class="text-3xl font-semi-bold text-purple mt-8">Apoio aos pilares curriculares:</h1>
      <h4 class="font-semi-bold mt-2">Excelência acadêmica, socioemocional e cidadania global</h4>

      
      <div class="w-full mt-auto">
        <div class="border-b-3 border-orange w-4vw"></div>
        <h4 class="font-semi-bold italic mt-4">id.eia é o maestro dessa orquestra de gamificação orientada à desenvolvimento humano</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportHome'
}
</script>

<style>
  .conector-ball {
    position: absolute;
    display: block;
    width: 60px;
    height: 120px;
    top: 10vh;
    right: -40px;
    border-bottom-right-radius: 120px;
    border-top-right-radius: 120px;
    background-color: #AD93EB;
    box-shadow: 3px 3px 10px -2px rgba(47, 49, 82, 0.15);
  }
</style>