<template>
  <fieldset class="w-full mt-6">

    <div class="w-full flex justify-between">
      <legend class="h4 font-semi-bold">Times</legend>
      <router-link 
        :to="{ name: 'ProjectUserList', params: { projectId: this.$route.params.projectId }}"
        class="button button-border-yellow focus:outline-none"
      >
        Lista de usuários
      </router-link>
    </div>

      
    <div class="w-full overflow-x-auto max-w-full flex flex-col mt-3">
      <div class="w-full my-2 hidden md:flex">
        <p class="w-25/100 flex items-center pl-2">Time</p>
        <p class="w-15/100 flex items-center pl-2">Tempo de jogo</p>
        <p class="w-15/100 flex items-center pl-2">Jogo atual</p>
        <p class="w-15/100 flex items-center pl-2">Qtd de usuários</p>
        <p class="w-30/100 flex items-center pl-2">Lista de usuários</p>
      </div>
      <div 
        class="w-full bg-white rounded flex flex-wrap shadow-sm my-2 font-bold"
        v-for="(team, index) in teams"
        :key="`project-team-${index}`"
      >
        <div class="block w-full xl:w-25/100 px-2 py-4 flex items-center justify-center xl:border-r border-smoke-light text-center xl:text-left">
          <span>{{ team.name }}</span>
        </div>
        <div class="block w-full xl:w-15/100 px-2 py-4 flex items-center justify-center xl:border-r border-smoke-light text-center xl:text-left">
          <span>{{ team.team_total_time }}</span>
        </div>
        <div class="block w-full xl:w-15/100 px-2 py-4 flex items-center justify-center xl:border-r border-smoke-light text-center xl:text-left">
          <span v-if="team.current_game">{{ team.current_game.name }}</span>
          <span v-else>Não iniciado</span>
        </div>
        <div class="block w-full xl:w-15/100 px-2 py-4 flex items-center justify-center xl:border-r border-smoke-light text-center xl:text-left">
          <span>{{ team.players_project.length }}</span>
        </div>
        <div class="block w-full xl:w-30/100 px-2 py-4 flex items-center justify-center xl:border-r border-smoke-light text-center xl:text-left">
          <button 
            @click="$eventBus.$emit('openProjectTeamUserListModal', team.id)"
            class="button button-border-yellow focus:outline-none">
            <feather type="list"></feather>
          </button>
          <button 
            @click="adminPlayerLogin(team)"
            class="button button-border-yellow focus:outline-none ml-3">
            <feather type="eye"></feather>
          </button>
          <button 
            @click="openTeamToReset(team)"
            class="button button-border-yellow focus:outline-none ml-3">
            <feather type="refresh-cw"></feather>
          </button>
          <button 
            @click="$eventBus.$emit('openAdminProjectTeamChatModal', team.id)"
            class="button button-border-yellow focus:outline-none ml-3">
            <feather type="message-circle"></feather>
          </button>
        </div>
      </div>
    </div>

    <ProjectTeamUserListModal></ProjectTeamUserListModal>

    <BaseModal
      ref="modalTeamReset"
      modalDialogClasses="max-w-sm bg-white"
    >
      <div class="w-full flex flex-col text-black">
        <h2 class="w-full text-center font-bold">Atencão</h2>

        <p class="bg-red  w-full p-2 text-center my-3 text-white font-bold">
          Tem certeza que quer reiniciar o estado do time?
        </p>

        <div class="w-full flex justify-center">
          <button 
            @click="resetTeam()"
            class="button button-border-red focus:outline-none ml-3">
            <feather type="info" class="mr-2"></feather> Reiniciar time
          </button>
        </div>
      </div>
    </BaseModal>

  </fieldset>
</template>

<script>
import ProjectTeamUserListModal from './ProjectTeamUserListModal'
export default {
  name: 'ProjectTeamList',
  components: { ProjectTeamUserListModal },
  props: ['project'],
  data() {
    return {
      teams: [],
      teamSelectedId: null,
    }
  },
  mounted() {
    this.getTeamGames()
  },
  methods: {

    getTeamGames() {
      const query =  `
        {
          project (
            findBy: { column: "id", value: "${this.$route.params.projectId}"}
          ) {
            id
            slug
            teams {
              id
              name
              games_started_at
              total_penalties_in_seconds
              team_total_time
              current_route
              players_project {
                id
              }
              current_game {
                name
              }
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.teams = data.project.teams
        })
    },
    adminPlayerLogin(team) {
      this.$api.get(`/admin/player_project/admin_player_team_login/${team.id}`)
        .then(({ data }) => {
          this.$store.commit('setLoggedUserToken', { token: data.token, type: 'player' })
          this.$store.dispatch('getLoggedPlayer', { projectSlug: this.project.slug, redirect: true })
        })
    },

    openTeamToReset(team) {
      this.teamSelectedId = team.id
      this.$refs.modalTeamReset.showModal = true
    },

    resetTeam() {
      this.$api.get(`/system/reset_team/${this.teamSelectedId}`)
        .then(() => {
          this.$gameToast({ title: 'Atenção', message: 'Time reiniciado com sucesso' })
          this.getTeamGames()
          this.$refs.modalTeamReset.showModal = false
          this.teamSelectedId = null
        })
    }

  }
}
</script>