<template>
  <div class="w-full mt-2 rounded border border-yellow p-2 h-15vh relative border-only-corner flex justify-center relative">
    <span></span>
    <VideoAudioControls></VideoAudioControls>
    <GameButton @click="$eventBus.$emit('openChatModal')" label="Chat" class="w-12vw text-2xl mx-2" :notifications="getUnreadMessages" bgNotifications="bg-red"></GameButton>
    <GameButton @click="$eventBus.$emit('openGameTipModal')" label="Dicas" class="w-12vw text-2xl mx-2" :notifications="getTipsUnlocked" bgNotifications="bg-green"></GameButton>
    <div class="h-full flex items-center absolute right-0 top-0 pr-3">
      <button @click="$eventBus.$emit('openGameIntroductionModal')" class="w-6vh h-6vh rounded-full flex items-center justify-center text-yellow focus:outline-none">
        <feather type="help-circle"></feather>
      </button>
    </div>

    <GameTipModal 
      :game="game"
      :item_id="currentQuizQuestion.id"
      :item_type="'QuizQuestion'"
    ></GameTipModal>

  </div>
</template>

<script>
import GameTipModal from '../tips/GameTipModal'

export default {
  name: 'GamePuzzleActionMenu',
  components: { GameTipModal },
  props: ['game', 'currentQuizQuestion'],
  computed: {
    getTipsUnlocked() {
      return this.$store.state.gamePlay.tipsUnlocked
    },
    getUnreadMessages() {
      return this.$store.state.gameChat.unreadMessages
    },
  }
}
</script>

<style>

</style>