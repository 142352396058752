import ReportAuth from './auth/ReportAuth'
import InReportLayout from '@/modules/report/layout/InReportLayout'
import ReportLayout from '@/modules/report/layout/ReportLayout'

export default [
  {
    path: '/relatorios/:projectSlug?',
    component: ReportLayout,
    children: [
      {
        path: '',
        name: 'ReportAuth',
        component: ReportAuth,
      },

      {
        path: ':userType/:reportType?',
        name: 'ReportShow',
        component: InReportLayout
      },
    ]
  },
]