
export default {
  twilioPlayerToken: null,
  videoIsOn: false,
  audioIsOn: false,
  videoDeviceLabel: '',
  videoDeviceId: '',
  audioDeviceLabel: '',
  audioDeviceId: ''
}
