import BaseModel from './BaseModel'
export default class PuzzleButton extends BaseModel {

  constructor(obj = {}) {
    super()
    this.key = obj.key || null
    this.id = obj.id || null
    this.game_id = obj.game_id || null
    this.content = obj.content || ''
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
  }

  get requireds() {
    return [
      { item: 'content', label: 'conteúdo' }
    ]
  }

}