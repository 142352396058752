<template>
  <div class="w-full flex h-15vh font-londrina-solid">
    <div class="w-15/100 pr-2">
      <div class="border-yellow border-r-2 border-b-2 rounded-r-lg rounded-t-none h-15vh bg-black flex items-center justify-center p-3">
        <img src="/img/logo_ideia_white.svg" class="h-5vh" alt="Logo ideia ativada" />
      </div>
    </div>
    <div class="w-70/100 px-2 h-15vh">
      <div class="border-yellow border-b-2 border-l-2 border-r-2 rounded-l-lg rounded-r-lg rounded-t-none h-15vh bg-smoke-light flex items-center justify-between p-3 relative">
        <div class="w-30/100 flex pl-3">
          <img class="h-10vh" :src="getProject.company.logo_url" />
        </div>
        <div class="w-40/100 flex flex-col w-full" :title="getTeam.id_value">
          <h2 class="font-bold text-2xl text-white text-center">{{getTeam.name}} {{getPlayer.name}}</h2>
        </div>
        <div 
          class="w-auto flex justify-center px-2 focus:outline-none space-x-2 bg-smoke-light rounded" 
          @click="$refs.inclusionModal.$refs.baseModal.showModal = true" 
        >
          <button :title="icon.title" class="w-3vw" v-for="icon in getIconsInTeam" :key="icon.value">
            <img :src="`/icons/${ icon.icon }`" :alt="icon.title"/>
          </button>
        </div>

      </div>
    </div>
    <div class="w-15/100 pl-2 h-15vh">
      <div class="border-yellow border-l-2 border-b-2 rounded-l-lg rounded-t-none h-15vh bg-smoke flex flex-col items-center justify-center p-3">
        <div v-if="getTotalGameTime" class="w-full flex flex-col items-center justify-center">
          <h2 class="text-lg font-bold font-aldrich text-red mb-0 leading-none">
            {{ getTotalGameTime }}
          </h2>
          <p class="text-xxs text-yellow mt-0">Tempo game atual</p>
        </div>
        <div class="w-full flex flex-col items-center justify-center mt-1" v-if="getTotalTeamTime">
          <h2 class="text-sm font-bold font-aldrich text-red mb-0 leading-none">
            {{ getTotalTeamTime }}
          </h2>
          <p class="text-xxs text-yellow mt-0">Tempo total</p>
        </div>
      </div>
    </div>

    <InclusionModal
      ref="inclusionModal"
    ></InclusionModal>

  </div>
</template>

<script>
import InclusionModal from './InclusionModal.vue'
export default {
  name: 'GameHeader',
  components: { InclusionModal },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getServerTime() {
      return this.$store.getters.getterServerTime
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },

    getIconsInTeam() {
      return this.icons.filter((icon) => {
        return this.getTeam.players_project.filter(
          (project_player) => project_player.player.inclusionInfo[icon.value]
        ).length > 0 ? true : false
      })
    }
  },

  data() {
    return {
      icons: [
        { icon: 'pcd.png', title: 'Pessoa com deficiência', value: 'is_pcd' },
        { icon: 'diversidade.png', title: 'Diversidade', value: 'is_diversity' },
        { icon: 'autismo.png', title: 'Autismo', value: 'is_autistic' },
        { icon: 'etnia.png', title: 'Etnia', value: 'is_etnic' },
        { icon: '60.png', title: '60+', value: 'is_elderly' },
      ]
    }
  },
}
</script>

<style>

</style>