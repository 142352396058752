<template>
  <div class="w-full block relative mt-2">
    <div class="w-full flex whitespace-no-wrap mb-1 pl-2">
      <label class="text-lg text-yellow" :for="getId" v-if="label">{{ label }}</label>
      <div class="text-red absolute top-0 right-0 text-lg pl-2" v-if="validation.hasError && validation.label">{{validation.label}}</div>
      <div class="absolute top-0 right-0 text-red w-full text-lg ml-2 w-3vh h-3vh rounded-full flex items-center justify-center bg-green text-white" v-if="validation.checked && validation.filled">
        <feather class="text-white" type="check" size="16"></feather>
      </div>
    </div>
    <select 
      :id="getId"
      class="w-full text-center appearance-none outline-none text-yellow text-2xl py-2 px-3 focus:border-yellow rounded bg-white"
      v-model="childValue"
    >
      <option :value="''" disabled="" selected="">Escolha uma opção</option>
      <option class="p-2" v-for="item in getItems" :value="item[keyValue]" :key="item[keyValue]">{{ item[keyLabel] }}</option>
    </select>
  </div>
</template>

<script>
import { StringHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'SelectInput',
  props: {
    items: {
      required: true
    },
    value: {
      required: true
    },
    label: {
      default: ''
    },
    keyLabel: {
      default: 'label'
    },
    keyValue: {
      default: 'value'
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    }
  },
  computed: {
    childValue: {
      get() {
        return this.value
      },
      set(value) {
        if(this.isString) return this.$emit('input', value)
        const finded = find(this.items, { [this.keyValue]: value })
        this.$emit('input', value)
        this.$emit('change', finded)
      }
    },

    isString() {
      return  this.items.length > 0 && typeof this.items[0] === 'string'
    },

    getItems() {
      if (this.items.length > 0 && typeof this.items[0] === 'string') {
        return this.items.map((item) => { 
          return {
            [this.keyLabel]: item,
            [this.keyValue]: item,
          }
        })
      } else {
        return this.items
      }
    },

    getId() {
      if(this.id) return this.id
      return StringHelpers.randomString(12)
    }
  }
}
</script>