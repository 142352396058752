export default {
  setRoom(state, { room }) {
    state.room = room
  },
  setLastSeenInterval(state, lastSeenInterval) {
    state.lastSeenInterval = lastSeenInterval
  },
  setServerTimeInterval(state, serverTimeInterval) {
    state.serverTimeInterval = serverTimeInterval
  },
  setServerTime(state, serverTime) {
    state.serverTime = serverTime
  },
  setRoomDominantSpeaker(state, player_id) {
    state.roomDominantSpeaker = player_id
  },
  setRoomLeaderId(state, leader_id) {
    state.roomLeaderId = leader_id
  }
}
