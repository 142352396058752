<template>
  <div class="w-full h-full rounded border border-yellow border-only-corner p-2 relative flex">
    <span></span>
    
    <div class="w-60/100 h-full bg-smoke-light rounded text-white flex items-center justify-center">
      <img v-if="currentQuizQuestion.file_url" class="w-full h-full object-contain bg-center rounded" :src="currentQuizQuestion.file_url" alt=""/>
      <p v-else class="text-white" >{{currentQuizQuestion.content}}</p>
    </div>
    <div class="w-40/100 h-full flex items-center justify-center relative p-6">
      <div class='square-box'>
        <div class="square-content flex flex-wrap relative">
          <div 
            class="w-50/100 h-50/100 flex p-0.5" 
            v-for="(option, index) in currentQuizQuestion.options"
            :key="option.id"
          >
            <div class="h-full w-full opacity-80 hover:opacity-100" :id="geniusColors[index]" @click="selectOption(index)"></div>
          </div>
          <div id="panel" class="w-40/100 h-40/100 rounded-full bg-black absolute flex items-center justify-center">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLetterNumber } from '@/util/Functions'
// import { Synth, Recorder} from 'tone'
export default {
  name: 'GameGeniusLeaderBoard',
  props: {
    game: {
      required: true
    },
    quizQuestions: {
      required: true
    },
    currentQuizQuestion: {
      required: true
    },
    optionSelectedIndex: {
      required: true
    },
  },
  data() {
    return {
      geniusColors: ['green', 'red', 'yellow', 'blue'],
      geniusSoundNotes: [261.6, 293.7, 329.6, 392.0],
      options: [],
      nextGameRequestSent: false
    }
  },
  computed: {
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
    getCurrentStep() {
      return this.$route.params.step || 0
    }
  },
  methods: {

    selectOption(index) {
      this.options.push(this.quizQuestions[this.options.length].options[index].id)
      if(this.options.length-1 == this.getCurrentStep) {
        this.storeAnswer()
      }
      window.playSound(`genius-sound-${index}`)
    },
    getLetterNumber(number) {
      return getLetterNumber(number)
    },
    storeAnswer() {
      if(this.nextGameRequestSent) return
      const data = {
        game_id: this.game.id,
        project_id: this.getProject.id,
        team_id: this.getTeam.id,
        game_current_time: this.getTotalGameTime,
        team_current_time: this.getTotalTeamTime,
        options: this.options
      }

      this.$api.post(`/game/answer/genius_store`, data)
        .then(({ data }) => {
          if(data.answer.is_correct) {
            this.redirectToNewStepOrGame()
            this.$socket.emit('openToast', { title: 'Parabéns', message: 'Resposta correta!' })
            setTimeout(() => { window.playSound(`correct-answer`)}, 500)
          } else {
            this.$socket.emit('openToast', { title: 'Ops!', message: 'Resposta errada!' })
            setTimeout(() => { window.playSound(`wrong-answer`)}, 500)
          }
          this.options = []
          this.$socket.emit('refreshLoggedPlayer')
        })
        .catch(() => {
          this.$api.post(`/game/room/go_to_current_game`, { team_id: this.getTeam.id })
        })
    },

    redirectToNewStepOrGame() {
      this.$api.post(`/game/room/next_step_or_game`, {
        project_id: this.getProject.id, 
        game_id: this.game.id, 
        team_id: this.getTeam.id,
      })
    }
  }
}
</script>

<style>

#green {
  background-color: #008000;
  border-radius: 100% 5% 0 5%;
}

#red {
  background-color: #FF0000;
  border-radius: 5% 100% 5% 0;
}

#blue {
  background-color: #0000FF;
  border-radius: 0 5% 100% 5%;
}

#yellow {
  background-color: #FFFF00;
  border-radius: 5% 0 5% 100%;
}

#panel {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.square-box{
    position: relative;
    width: 50vh;
    overflow: hidden;
}
.square-box:before{
    content: "";
    display: block;
    padding-top: 100%;
}
.square-content{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    text-align: center;
}

</style>