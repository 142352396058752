<template>
  <div class="w-full min-w-0">
    <div class="w-full flex flex-wrap lg:flex-no-wrap justify-between items-end">
      <SearchInput
        class="lg:max-w-50/100 search-input"
        v-model="filters.game_name"
        @keydown="getGames"
        :label="'Busca'"
      ></SearchInput>
      <router-link :to="{ name: 'GameAdd' }" class="lg:w-1/6 mt-3 lg:mt-0 button button-yellow whitespace-no-wrap">
        <feather type="plus" class="mr-2"></feather>
        Novo game
      </router-link>
    </div>

    <div class="w-full flex flex-wrap mt-4">
      <div class="w-full lg:w-25/100 lg:pr-3 relative">
        <label>Dificuldade</label>
        <v-select 
          class="vue-select"
          placeholder="Filtre por dificuldade"
          :class="filters.difficulty ? 'filled' : ''"
          label="label"
          :options="gameDifficultiesOptions"
          :reduce="item => item.value"
          v-model="filters.difficulty"
          @input="getGames"
        ></v-select>
      </div>
      <div class="w-full lg:w-25/100 lg:pr-3 relative">
        <label>Tipo</label>
        <v-select 
          class="vue-select"
          placeholder="Filtre por tipo"
          :class="filters.component ? 'filled' : ''"
          label="label"
          :options="gameComponentOptions"
          :reduce="item => item.value"
          v-model="filters.component"
          @input="getGames"
        ></v-select>
      </div>
    </div>

    <div class="w-full mt-4 overflow-x-auto max-w-full min-w-0">
      <table class="table table-striped bg-white shadow-sm whitespace-no-wrap">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Tipo</th>
            <th>Dificuldade</th>
            <th>Quantidade projetos</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="game in games" :key="game.Id">
            <td>{{game.name}}</td>
            <td>{{game.component}}</td>
            <td><GameDifficultyLabel :difficulty="game.difficulty"></GameDifficultyLabel></td>
            <td>{{game.projects.length}}</td>
            <td>
              <div class="flex items-center">
                <router-link class="p-0 flex" :to="{ name: 'GameUpdate', params: { gameId: game.id }}">
                  <feather type="edit"></feather>
                </router-link>
                <router-link class="ml-2 flex" :to="{ name: 'GameShow', params: { gameId: game.id }}">
                  <feather type="search"></feather>
                </router-link>
                <button class="ml-1" ><feather @click="setGameToToggle(game)" type="x" size="28"></feather></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Paginator
      class="mt-2"
      v-model="offset" 
      :length="games.length" 
      :limit="limit"
      @getItems="getGames"
    ></Paginator>

    <BaseModal
      ref="toggleGameModal"
      modalDialogClasses="max-w-sm bg-white text-black"
      @onCloseModal="clearSelectedGame"
      title="Atenção"
    >
      <div class="w-full flex flex-col items-center justify-center text-black" v-if="gameSelectedToToggle">
        <h4 class="text-center mt-4 space-x-1">
          <span>Tem certeza que deseja</span>
          <span class="font-bold text-red" v-if="gameSelectedToToggle.is_active">excluir</span>
          <span class="font-bold text-green" v-else>ativar</span>
          <span>o game?</span>
        </h4>
        <div class="w-full flex justify-center mt-4">
          <button @click="clearSelectedGame()" class="button button-border-yellow">
            Cancelar
          </button>
          <button @click="toggleGameActive(gameSelectedToToggle.id)" class="button button-border-red ml-4">
            Sim
          </button>
        </div>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import Paginator from '@/components/Paginator'
import PaginationInfo from '@/models/PaginationInfo'
import { gameComponentOptions, gameDifficultiesOptions } from '@/models/Enums'
export default {
  name: 'GameList',
  components: { Paginator },
  data() {
    return {
      gameComponentOptions,
      gameDifficultiesOptions,
      filters: {
        game_name: '',
        difficulty: '',
        component: '',
      },
      orderBy: {
        column: 'name',
        direction: 'asc'
      },
      offset: 0,
      limit: 12,
      modalGamesShow: false,
      games: [],
      paginationInfo: new PaginationInfo(),
      gameSelectedToToggle: null
    }
  },
  mounted() {
    this.getGames()
  },
  methods: {

    getGames() {
      const query =  `
        {
          games (
            where: [
              ${this.filters.game_name ? `{ column: "name", operation: "ILIKE", value: "%${this.filters.game_name}%" }` : ''}
              ${this.filters.difficulty ? `{ column: "difficulty", operation: "=", value: "${this.filters.difficulty}" }` : ''}
              ${this.filters.component ? `{ column: "component", operation: "=", value: "${this.filters.component}" }` : ''}
              { column: "is_active", operation: "=", value: "1" }
            ]
            orderBy: [{ column: "is_active", order: "DESC" }, { column: "${this.orderBy.column}", order: "${this.orderBy.direction}" }]
            paginate: { offset: ${this.offset}, limit: ${this.limit} }
          ) {
            id
            name
            component
            difficulty
            is_active
            projects {
              id
              name
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.games = data.games
        })
    },

    setGameToToggle(game) {
      this.gameSelectedToToggle = game
      this.$refs.toggleGameModal.showModal = true
    },

    clearSelectedGame() {
      this.gameSelectedToToggle = null
      this.$refs.toggleGameModal.showModal = false
    },

    toggleGameActive(game_id) {
      this.$api.get(`/admin/games/toggle_active/${game_id}`)
        .then(() => {
          this.$toast.success('Jogo atualizado com sucesso')
          this.getGames()
          this.clearSelectedGame()
        })
        .catch(() => {
          this.$toast.error('Erro ao atualizar o jogo, por favor confira.')
        })
    }
  }
}
</script>

<style>

</style>