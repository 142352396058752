<template>
  <div id="app" class="w-full f-ull bg-grey-light">
    <div class="w-full flex h-full">

      <BaseLoader></BaseLoader>

      <div class="w-full h-auto overflow-y-auto bg-white">
        <div class="w-full bg-grey-light min-h-full" id="main-content">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingLayout',
  metaInfo () {
    return {
      title: `Admin |`
    }
  },
}
</script>