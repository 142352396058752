<template>
  <div id="app" class="font-open-sans h-screen flex">
    <Toast></Toast>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MainApp',
  metaInfo: {
    titleTemplate: '%s Idéia Ativada'
  }
}
</script>

<style>
  @import "./css/index.css";
</style>
