<template>
  <span :class="[orderBy.column == item ? 'text-blue' : 'text-white']">
    <feather
      size="18"
      @click="$emit('updateOrderBy', item)"
      class="cursor-pointer hover:text-black"
      :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
    ></feather>
  </span>
</template>

<script>
export default {
  name: 'OrderByHelper',
  props: {
    item: {
      type: String,
      required: true
    },
    orderBy: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>