<template>
  <fieldset class="w-full" v-if="game.component == 'GamePuzzle'">
    <legend class="h4 font-semi-bold mb-2">Botões do enigma</legend>
    <div class="w-full flex">
      <router-link
        :to="{ name: 'GamePuzzleButtonAdd' }"
        class="button button-border-yellow bg-white">
        <feather type="plus"></feather>
        <span class="ml-2">Adicionar botão</span>
      </router-link>
    </div>

    <div class="w-full mt-8" v-if="game.puzzle_buttons.length">
      <div class="w-full">
        <div class="w-full my-2 hidden lg:flex">
          <p class="lg:w-40/100 flex items-center pl-4">Título Botão</p>
          <p class="lg:w-40/100 flex items-center pl-4">Acesso</p>
          <p class="lg:w-20/100 flex items-center pl-4">Ações</p>
        </div>

        <div class="w-full overflow-x-auto max-w-full flex flex-col p-2">
          <div 
            class="w-full bg-white rounded flex flex-wrap shadow-sm my-2"
            v-for="(item, index) in game.puzzle_buttons"
            :key="`game-puzzle-item-${index}`"
          >
            <div class="block w-full lg:w-40/100 p-4 lg:border-r border-smoke-light">
              <h5 class="font-bold">{{item.title}}</h5>
            </div>
            <div class="block w-full lg:w-40/100 p-4 lg:border-r border-smoke-light">
              <PuzzleButtonAccessHelper :access="item.access"></PuzzleButtonAccessHelper>
            </div>
            <div class="block w-full lg:w-20/100 p-4 flex items-center justify-center">
              <router-link 
                :to="{ name: 'GamePuzzleButtonUpdate', params: { puzzleButtonId: item.id }}"
                class="button button-border-yellow focus:outline-none ml-3">
                Editar
              </router-link>
              <button 
                @click="itemSelected = item"
                class="button button-border-red focus:outline-none ml-3">
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="itemSelected != null" class="modal-wrapper">
      <div class="modal-dialog">
        <button 
          @click="itemSelected = null"
          class="modal-exit-button">
          <feather type="x" size="20"></feather>
        </button>
        <h4 class="w-full text-center font-bold">Atenção</h4>
        <div class="w-full flex flex-col items-center justify-center">
          <h4 class="text-center mt-4">Tem certeza que deseja excluir?</h4>

          <div class="w-full flex justify-center mt-10">
            <button @click="itemSelected = null" class="button button-border-yellow">
              Cancelar
            </button>
            <button @click="deleteItem()" class="button button-border-red ml-4">
              Sim
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </fieldset>
</template>

<script>
export default {
  name: 'GamePuzzleButtonList',
  props: ['game'],
  data() {
    return {
      itemSelected: null
    }
  },
  methods: {
    deleteItem() {
      this.$api.delete(`/admin/puzzle_buttons/destroy/${this.itemSelected.id}`)
        .then(() => {
          this.itemSelected = null
          this.$emit('getGame')
        })
    }
  }
}
</script>