<template>
  <fieldset class="w-full" v-if="['GameQuiz', 'GameGenius'].includes(game.component)">
    <legend class="h4 font-semi-bold mb-2">Perguntas</legend>
    <div class="w-full flex">
      <button
        @click="$eventBus.$emit('openQuizQuestionModal')"
        class="button button-border-yellow bg-white">
        <feather type="plus"></feather>
        <span class="ml-2">Adicionar pergunta</span>
      </button>
    </div>

    <div class="w-full mt-8" v-if="game.quiz_questions.length">
      <div class="w-full">
        <div class="w-full my-2 hidden lg:flex">
          <p class="w-60/100 flex items-center pl-4">Pergunta</p>
          <p class="w-40/100 flex items-center pl-4">Ações</p>
        </div>

        <div class="w-full overflow-x-auto max-w-full flex flex-col">
          <div 
            class="w-full bg-white rounded flex flex-wrap shadow-sm my-2 cursor-move"
            v-for="(question, index) in game.quiz_questions"
            :key="`game-puzzle-question-${index}`"
            draggable="true"
            @dragstart="dragStart(index, game.quiz_questions)"
            @dragover.prevent
            @dragend="dragEnd"
            @drop="dragFinish(index, game.quiz_questions)"
          >
            <div class="block w-full lg:w-60/100 p-4 lg:border-r border-smoke-light">
              <h5 class="font-bold">{{question.title}}</h5>
            </div>
            <div class="block w-full lg:w-40/100 p-4 flex items-center justify-center">
              <button
                @click="$eventBus.$emit('openQuizQuestionModal', { quiz_question_id: question.id })"
                class="button button-border-yellow focus:outline-none">
                Editar
              </button>
              <router-link
                :to="{ name: 'GameQuizQuestionShow', params: { quizQuestionId: question.id }}"
                class="button button-border-yellow focus:outline-none mx-2">
                Visualizar
              </router-link>
              <button 
                @click="$refs.quizQuestionConfirmDeleteModal.showModal = true; quizQuestionSelected = question"
                class="button button-border-red focus:outline-none">
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseModal
      ref="quizQuestionConfirmDeleteModal"
      modalDialogClasses="max-w-sm bg-white text-black"
      @onCloseModal="quizQuestionSelected = null"
      title="Atenção"
    >
      <div class="w-full flex flex-col items-center justify-center text-black">
        <h4 class="text-center mt-4">Tem certeza que deseja excluir?</h4>
        <div class="w-full flex justify-center mt-4">
          <button @click="quizQuestionSelected = null" class="button button-border-yellow">
            Cancelar
          </button>
          <button @click="deleteQuizQuestion()" class="button button-border-red ml-4">
            Sim
          </button>
        </div>
      </div>
    </BaseModal>

    <GameQuizQuestionModal @updated="$emit('getGame')"></GameQuizQuestionModal>
    
  </fieldset>
</template>

<script>
import GameQuizQuestionModal from './GameQuizQuestionModal'
export default {
  name: 'GamePuzzleButtonList',
  components: { GameQuizQuestionModal },
  props: ['game'],
  data() {
    return {
      quizQuestionSelected: null,
      questionsToReoder: [],
      dragAndDropUrl: `/admin/quiz_questions/sync_order`
    }
  },
  methods: {
    deleteQuizQuestion() {
      this.$api.delete(`/admin/quiz_questions/destroy/${this.quizQuestionSelected.id}`)
        .then(() => {
          this.quizQuestionSelected = null
          this.$emit('getGame')
        })
        .catch((err) => {
          if(err.response.data) {
            this.$toast.error(err.response.data.message)
          }
        })
    },

    callbackDragAndDrop() {
      this.$emit('getGame')
    }
  }
}
</script>