<template>
  <div class="block w-full">
    <div class="">
      <label :for="id" v-if="label" class="block mb-1">{{label}}</label>
      <textarea
        :type="getType"
        :id="id"
        :autocapitalize="autocapitalize"
        :class="{'input-has-error' : validation.hasError, 'filled' : value}"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :placeholder="placeholder"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        class="block appearance-none rounded bg-white focus:shadow-sm outline-none w-full border-2 border-grey-light focus:border-yellow placeholder-grey bg-white text-grey-darker text-lg py-2 px-3"
      ></textarea>
    </div>
    <p class="text-red w-full pl-3" v-if="validation.hasError && validation.label">{{validation.label}}</p>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    action: {
      type: Function,
      required: false
    },
    mask: {
      type: String,
      default: ''
    },
    autocapitalize: {
      type: String,
      default: 'sentences'
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    getType() {
      if(this.type !== 'password') return this.type
      if(this.type === 'password' && this.showPassword) return 'text'
      return this.type
    }
  },
  methods: {
    makeAction() {
      if(this.action && typeof(this.action) == 'function') this.action()
    }
  }
};
</script>

<style>

  textarea + label {
    transition: flex 0.4s ease-out;
  }
  textarea:focus + label {
    color: #FFC700 !important;
    font-weight: 600;
  }
  textarea:focus + label, textarea.filled + label {
    align-items: flex-start;
    margin-top: 6px;
  }

  textarea:focus:not(.input-has-error) {
    border-color: #FFC700 !important;
  }

  textarea.input-has-error + label {
    color: #ff0f4a !important;
  }

  .input-has-error {
    border-color: #ff0f4a !important;
  }
</style>
