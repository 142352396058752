<template>
  <div class="w-full flex flex-wrap items-start">
    
    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Status</legend>

      <div class="w-full flex justify-between">
        <ProjectStatusLabel :status="project.Status"></ProjectStatusLabel>
        <div class="flex">
          <router-link
            :to="{ name: 'GamePlayerAuth', params: { projectSlug: project.slug }}"
            target="_blank"
            class="button button-border-green focus:outline-none">
            Acessar game
          </router-link>

          <button
            @click="goToProjectReport()"
            target="_blank"
            class="button button-border-green focus:outline-none ml-2">
            Acessar Relatório
          </button>
        </div>

      </div>

      <div class="w-full flex mt-4">
        <button @click="getProjectCsvReport('players', 'usuários')" class="button button-border-yellow focus:outline-none">
          <feather type="users" class="mr-2"></feather>
          Relatório players
        </button>

        <button @click="getProjectCsvReport('teams', 'times')" class="ml-3 button button-border-yellow focus:outline-none">
          <feather type="command" class="mr-2"></feather>
          Relatório times
        </button>
      </div>
      <div class="w-full flex mt-4">
        <button @click="resetTeams()" class="button button-border-red focus:outline-none">
          <feather type="users" class="mr-2"></feather>
          Reiniciar times (atenção)
        </button>
      </div>
    </fieldset>

    <fieldset class="w-full mt-6">
      <legend class="h4 font-semi-bold mb-2">Dados projeto</legend>

      <div class="w-full grid grid-cols-2 gap-4">
        <InputWithLabel
          :label="'Nome do projeto'"
          v-model="project.name"
          disabled
        ></InputWithLabel>
        <InputWithLabel
          :label="'Nome do projeto'"
          v-model="project.company.name"
          disabled
        ></InputWithLabel>
        <InputWithLabel
          :label="'Data do evento'"
          v-model="project.event_start_at"
          disabled
        ></InputWithLabel>
        <InputWithLabel
          :label="'Tempo total por time (minutos)'"
          v-model="project.total_time"
          disabled
        ></InputWithLabel>
      </div>

    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-10"/>

    <ProjectImportUserList @getProject="getProject"></ProjectImportUserList>

    <ProjectTeamList ref="projectTeamList" :project="project" @getProject="getProject"></ProjectTeamList>

    <hr class="border-b-1 border-smoke-light w-full my-10"/>
    
    <ProjectGameList :project="project" @getProject="getProject"></ProjectGameList>

    <AdminProjectTeamChatModal :project="project"></AdminProjectTeamChatModal>

  </div>
</template>

<script>
import Project from '@/models/Project'
import ProjectTeamList from './ProjectTeamList'
import ProjectGameList from './ProjectGameList'
import ProjectImportUserList from './ProjectImportUserList'
import AdminProjectTeamChatModal from './team-chat/AdminProjectTeamChatModal'
import DownloadCSV from '@/util/DownloadCSV'
export default {
  name: 'ProjectForm',
  components: { ProjectTeamList, ProjectGameList, ProjectImportUserList, AdminProjectTeamChatModal },
  data() {
    return {
      project: new Project(),
    }
  },
  mounted() {
    this.getProject()
  },
  methods: {

    getProject() {

      const query =  `
        {
          project (
            findBy: { column: "id", value: "${this.$route.params.projectId}"}
          ) {
            id
            name
            slug
            event_start_at
            total_time
            max_users
            user_quantity
            room_quantity
            contact_name
            contact_email
            contact_phone
            contact_source
            company {
              name
            }
            teams {
              id
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.project = new Project(data.project)
          this.$refs.projectTeamList.getTeamGames()
        })
    },

    getProjectCsvReport(type, label) {
      this.$api.get(`/admin/report/project_${type}/${this.project.id}`)
        .then(({ data }) => {

          DownloadCSV({ file_name: `Relatório ${label}: ${this.project.name}`, csv_data: data.report_csv_data })
            .then(() => {
              console.log('CSV gerado com sucesso')
            })
        })
    },

    goToProjectReport() {

      if(!this.project.teams.length) return alert('Projeto não possui times para elaborar relatório')

      this.$api.get(`/admin/player_project/admin_player_team_login/${this.project.teams[0].id}`)
        .then(({ data }) => {
          this.$store.commit('setLoggedUserToken', { token: data.token, type: 'player' })
          this.$store.dispatch('getLoggedPlayer', { projectSlug: this.project.slug, redirect: false })
            .then(() => {
              this.$router.push({ name: 'ReportShow', params: { projectSlug: this.project.slug, userType: 'gerencial' }})
            })
          
        })
    },
    
    resetTeams() {
      this.project.teams.forEach((team) => {
        this.$api.get(`/system/reset_team/${team.id}`)
          .then(() => {
            })
      })
      this.$gameToast({ title: 'Atenção', message: 'Times reiniciados com sucesso' })
    }

    
  }
}
</script>