import { createLocalVideoTrack, createLocalAudioTrack }  from 'twilio-video'
import { isMobile } from '@/util/Functions'

export const handleLocalParticipantVideo = (context, localVideoTrack = null) => {
  setTimeout(() => {

    if(!localVideoTrack) {
      localVideoTrack = Array.from(window.$room.localParticipant.videoTracks.values())[0].track
    } else {
      const tracks = Array.from(window.$room.localParticipant.videoTracks.values())
      tracks.forEach((track) => {
        track.unpublish()
      })
  
      window.$room.localParticipant.publishTrack(localVideoTrack)
    }

    // Get and display the player
    const loggedUserPlayerCard = document.getElementById(`video-el-` + context.getters.getterPlayer.id)
    if(loggedUserPlayerCard) {
      loggedUserPlayerCard.innerHTML = ''
      loggedUserPlayerCard.appendChild(localVideoTrack.attach())
    }
  }, 300)
}

export const handleTrackSubscribed = (participant, track) => {
  console.log(`handleTrackSubscribed idt: ${participant.identity}`)
  const playerCard = document.getElementById(`video-el-` + participant.identity)
  if(playerCard) {
    playerCard.innerHTML = ''
    setTimeout(() => {
      playerCard.appendChild(track.attach())
    }, 200)
  }
}

export const handleTrackUnsubscribed = (participant, track) => {
  console.log(`handleTrackUnsubscribed idt: ${participant.identity}`)
  const attachedElements = track.detach()
  attachedElements.forEach(element => {
    element.remove()
  })
}

export const createAudioTrack = async (context, deviceId = null) => {

  deviceId = deviceId  || context.state.audioDeviceId || null

  const options = {}

  if(deviceId) {
    options.deviceId = { exact: deviceId }
  }

  const localAudioTrack = await createLocalAudioTrack(options)

  localAudioTrack.on('enabled', () => {
    context.commit('setAudioIsOn', true)
  })
  localAudioTrack.on('started', () => {
    context.commit('setAudioIsOn', true)
  })
  localAudioTrack.on('disabled', () => {
    context.commit('setAudioIsOn', false)
  })
  localAudioTrack.on('stopped', () => {
    context.commit('setAudioIsOn', false)
  })

  if(deviceId){
    const tracks = Array.from(window.$room.localParticipant.audioTracks.values())
    tracks.forEach((track) => {
      track.unpublish()
    })
    window.$room.localParticipant.publishTrack(localAudioTrack)
  }

  context.commit('setAudioDeviceLabel', localAudioTrack?.mediaStreamTrack?.label)

  return localAudioTrack
}

export const createVideoTrack = async (context, deviceId = null) => {

  deviceId = deviceId  || context.state.videoDeviceId

  const options = { width: 320 }

  if(deviceId) {
    options.deviceId = { exact: deviceId }
  }

  const localVideoTrack = await createLocalVideoTrack(options)

  localVideoTrack.on('enabled', () => {
    context.commit('setVideoIsOn', true)
  })
  localVideoTrack.on('started', () => {
    context.commit('setVideoIsOn', true)
  })
  localVideoTrack.on('disabled', () => {
    context.commit('setVideoIsOn', false)
  })
  localVideoTrack.on('stopped', () => {
    context.commit('setVideoIsOn', false)
  })

  context.commit('setVideoDeviceLabel', localVideoTrack?.mediaStreamTrack?.label, deviceId)

  return localVideoTrack
}

export const generalHandlers = () => {
  // Leave the Room when the "beforeunload" event is fired.
  window.onbeforeunload = () => {
    window.$room.disconnect()
    window.$socket.off('room')
    window.$socket.emit('leaveRoom')
  };

  if (isMobile) {
    // TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
    // In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
    window.onpagehide = () => {
      window.$room.disconnect()
    };

    // On mobile browsers, use "visibilitychange" event to determine when
    // the app is backgrounded or foregrounded.
    document.onvisibilitychange = async () => {
      if (document.visibilityState === 'hidden') {
        // When the app is backgrounded, your app can no longer capture
        // video frames. So, stop and unpublish the LocalVideoTrack.
        window.$room.localParticipant.tracks.forEach(publication => {
          publication.track.stop()
          publication.track.unpublish()
        })
      } else {
        // When the app is foregrounded, your app can now continue to
        // capture video frames. So, publish a new LocalVideoTrack.
        // localTracks = await createLocalVideoTrack(connectOptions.video);
      }
    };
  }

  // Handle disconnection
  window.onbeforeunload = () => {
    window.$room.disconnect()
    window.$socket.off('room')
    window.$socket.emit('leaveRoom')
  }

  if (isMobile) {
    // TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
    // In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
    window.onpagehide = () => {
      window.$room.disconnect()
    }

    // On mobile browsers, use "visibilitychange" event to determine when
    // the app is backgrounded or foregrounded.
    document.onvisibilitychange = async () => {
      if (document.visibilityState === 'hidden') {
        // When the app is backgrounded, your app can no longer capture
        // video frames. So, stop and unpublish the LocalVideoTrack.
        window.$room.localParticipant.tracks.forEach(publication => {
          publication.track.stop()
          publication.track.unpublish()
        })
      } else {
        // When the app is foregrounded, your app can now continue to
        // capture video frames. So, publish a new LocalVideoTrack.
        // localTracks = await createLocalVideoTrack(connectOptions.video)
      }
    }
  }
}