<template>
  <div class="w-full max-w-full flex flex-wrap items-start">
    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Dados iniciais</legend>
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">        
        <InputWithLabel
          class="w-full lg:w-50/100 md:pr-4"
          label="Nome Game"
          placeholder="Digite aqui"
          v-model="game.name"
        ></InputWithLabel>
        <div class="w-full md:w-50/100 md:pl-4">
          <label>Tipo de game</label>
          <v-select 
            class="vue-select"
            :class="game.component ? 'filled' : ''"
            label="label"
            :options="gameComponentOptions"
            :reduce="item => item.value"
            v-model="game.component"
          ></v-select>
        </div>
      </div>

      <div class="w-full flex flex-wrap mt-4 items-end">
        <div class="w-full lg:w-50/100">
          <InputFile 
            class="md:pr-4"
            v-model="game.introduction_file"
            label="Arquivo introdução"
            @change.native="checkIntroductionIsUrl(true)"
            :disabled="game.introduction_is_url"
          ></InputFile>
        </div>
        <div class="w-full lg:w-50/100 flex flex-col lg:px-6">
          <SwitchToggle 
            v-model="game.introduction_is_url" 
            @change.native="checkIntroductionIsUrl()"
            label="URL"
          ></SwitchToggle>
          <InputWithLabel
            :disabled="!game.introduction_is_url"
            class="w-full lg:flex-1"
            placeholder="Insira o link da introdução aqui"
            v-model="game.introduction_url"
          ></InputWithLabel>
        </div>
      </div>

      <div class="w-full flex items-end flex-wrap mt-4">
        <InputFile
          class="lg:max-w-50/100 md:pr-4"
          label="Imagem Background"
          v-model="game.background_file"
        ></InputFile>
        <button 
          @click="clearBackground()"
          class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
          Excluir
        </button>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-4"/>

    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Dados finais</legend>

      <div class="w-full flex flex-wrap sm:flex-no-wrap items-end">
        <div class="w-full md:w-25/100">
          <label>Dificuldade</label>
          <v-select 
            class="vue-select"
            :class="game.difficulty ? 'filled' : ''"
            label="label"
            :options="gameDifficultiesOptions"
            :reduce="item => item.value"
            v-model="game.difficulty"
          ></v-select>
        </div>
        <div class="w-full md:w-25/100 md:pl-4">
          <InputWithLabel
            class="w-full"
            label="Tempo aproximado de resolução (minutos)"
            placeholder="00"
            v-model="game.resolution_time"
            :mask="'##'"
          ></InputWithLabel>
        </div>
        <div class="w-full md:w-25/100 md:pl-4">
          <InputWithLabel
            class="w-full"
            label="Tempo de penalidade (segundos)"
            placeholder="00"
            v-model="game.penalty_time"
            :mask="'##'"
          ></InputWithLabel>
        </div>
        <div class="w-full md:w-25/100 md:pl-4">
          <InputWithLabel
            class="w-full"
            label="Tempo travamento (minutos)"
            placeholder="00"
            v-model="game.resolution_block_after"
            :mask="'##'"
          ></InputWithLabel>
        </div>
      </div>
    </fieldset>

    <div class="w-full text-base mt-4 mb-2" v-if="game.hasError" v-html="game.validationPhraseHtml"></div>

    <div class="w-full flex">
      <button v-if="!isEdit" @click="store()" :disabled="game.hasError" class="button button-yellow px-8">
        Criar
      </button>
      <button v-else @click="update()" :disabled="game.hasError" class="button button-yellow px-8">
        Atualizar
      </button>
      <button class="button button-red ml-6 px-8">
        Cancelar
      </button>
    </div>

    <AvoidExitModal></AvoidExitModal>

  </div>
</template>

<script>
import Game from '@/models/Game'
import { gameDifficultiesOptions, gameComponentOptions } from '@/models/Enums'
export default {
  name: 'GameForm',
  data() {
    return {
      game: new Game(),
      gameComponentOptions,
      gameDifficultiesOptions,
      isEdit: false
    }
  },
  mounted() {
    if(this.$route.params.gameId) {
      this.isEdit = true
      this.getGame()
    }
  },
  methods: {
    
    checkIntroductionIsUrl(forceFile = false) {
      if(this.game.introduction_is_url && !forceFile) {
        this.game.introduction_file = new File(["foo"], "", { type: "text/plain" })
      } else {
        this.game.introduction_is_url = false
        this.game.introduction_url = ''
      }
    },

    clearBackground() {
      this.game.background_file = new File(["foo"], "", { type: "text/plain" })
    },

    getGame() {
      const query =  `
        {
          game (
            findBy: { column: "id", value: "${this.$route.params.gameId}"}
          ) {
            id
            name
            component
            introduction_url
            introduction_file_url
            background_file_url
            difficulty
            resolution_time  
            resolution_block_after
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.game = new Game(data.game)
        })
    },

    setGameType(value) {
      const finded = find(this.gameTypeOptions, { value })
      this.game.component = finded.component
    },

    store() {
      this.$eventBus.$emit('setRouteShouldBeIntercepted', false)
      const formData = this.game.getFormData
      this.$api.post(`/admin/games/store`, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$router.push({ name: 'GameList' })
          this.$toast.success('Jogo adicionado com sucesso')
          this.$eventBus.$emit('setRouteShouldBeIntercepted', true)
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar o jogo, por favor confira.')
          this.$eventBus.$emit('setRouteShouldBeIntercepted', true)
        })
    },
    update() {
      this.$eventBus.$emit('setRouteShouldBeIntercepted', false)
      const formData = this.game.getFormData
      this.$api.post(`/admin/games/update/${this.game.id}`, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$router.push({ name: 'GameList' })
          this.$toast.success('Jogo atualizado com sucesso')
          this.$eventBus.$emit('setRouteShouldBeIntercepted', true)
        })
        .catch(() => {
          this.$toast.error('Erro ao atualizar o jogo, por favor confira.')
          this.$eventBus.$emit('setRouteShouldBeIntercepted', true)
        })
    }
  }
}
</script>