<template>
  <label class="inline-flex items-center cursor-pointer">
    <input type="checkbox" class="hidden" v-model="childValue" />
    <span class="w-3vh h-3vh flex-shrink-0 rounded-full relative flex items-center justify-center block text-white cursor-pointer" :class="childValue ? `bg-${bg}` : `border border-${bg}`">
      <feather type="check" size="18" v-if="childValue"></feather>
    </span>
    <span class="ml-3 text-gray04 cursor-pointer">{{label}}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    bg: {
      type: String,
      default: 'yellow'
    }
  },
  computed: {
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue )
      }
    }
  }
}
</script>