<template>
  <BaseModal
    ref="baseModal"
    modalDialogClasses="max-w-md bg-white text-black"
  >

    <div class="w-full min-w-0">

      <h2 class="w-full text-center my-2 text-black font-bold">Lista de usuários</h2>

      <div class="w-full overflow-x-auto">
        <table class="table table-bordered table-hover table-striped text-black">
          <thead>
            <tr>
              <td>Nome</td>
              <td>Email</td>
              <td>Pin</td>
              <td>É líder?</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player_project in players_project" :key="player_project.id">
              <td>{{ player_project.player.name }}</td>
              <td>{{ player_project.player.email }}</td>
              <td>{{ player_project.pin }}</td>
              <td><BooleanLabel :boolean="player_project.is_leader"></BooleanLabel></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>  


  </BaseModal>  
</template>

<script>
export default {
  name: "ProjectTeamUserListModal",
  data() {
    return {
      players_project: [],
      team_id: null
    }
  },
  mounted() {
    this.$eventBus.$on('openProjectTeamUserListModal', (team_id) => {
      this.team_id = team_id
      this.getPlayerProjects()
      this.$refs.baseModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openProjectTeamUserListModal')
  },
  methods: {
    getPlayerProjects() {
      const query = `
        {
          players_project (
            where: [{
              column: "team_id", operation: "=", value: "${this.team_id}"
            }]
          ) {
            is_leader
            pin
            can_be_leader
            player {
              id
              name
              email
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.players_project = data.players_project
        })
    }
  }
}
</script>

<style>

</style>