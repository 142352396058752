<template>
  <div class="w-full">
    <fieldset class="w-full">
      <legend class="h4 font-semi-bold mb-2">Sincronizar lista de players</legend>
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <InputFile
          label="Arquivo lista usuários (.csv)"
          accept=".csv"
          v-model="projectUserListImportFile.file"
          :placeholder="projectUserListImportFile.file && projectUserListImportFile.file.name ? projectUserListImportFile.file.name : 'Insira um arquivo'"
        ></InputFile>
        <button 
          :disabled="!projectUserListImportFile.file || !projectUserListImportFile.file.name"
          @click="importUserList()"
          class="button button-yellow ml-4"
        >Importar</button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import ProjectUserListImportFile from '@/models/ProjectUserListImportFile'
export default {
  name: 'ProjectUserList',
  data() {
    return {
      projectUserListImportFile: new ProjectUserListImportFile(),
    }
  },
  methods: {
    
    importUserList() {
      this.$api.post(`/admin/player_project/sync_players/${this.$route.params.projectId}`, this.projectUserListImportFile.getFormData, { loader: true })
        .then(() => {
          this.$toast.success('Importado com sucesso')
          this.$emit('getProject')
        })
        .catch((err) => {
          this.$toast.error(err.response.data.Message)
          this.$toast.error('Erro ao importar lista')
        })
    },

    
  }
}
</script>

<style>

</style>