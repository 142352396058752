<template>
  <div class="w-full flex flex-wrap items-stretch">
    <div 
      class="w-50/100 h-30vh p-2"
      v-for="(option, index) in getQuizOptions"
      :key="option.id"
      :title="`${option.is_correct ? 'Correta' : 'Incorreta'}`"
    >
      <button
        :class="[index === optionSelectedIndex ? 'bg-black' : 'bg-smoke-yellow']"
        class="w-full h-full p-2 px-3 rounded border border-yellow border-only-corner relative flex items-center justify-start">
        <span></span>
        <span class="-mb-1 h-full flex items-center text-3xl uppercase text-white font-bold self-start">
          {{ getLetterNumber(index) }}
        </span>
        <span class="text-white ml-2">{{option.content}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { getLetterNumber } from '@/util/Functions'
export default {
  name: 'GameQuizTeamBoard',
  props: {
    currentQuizQuestion: {
      required: true
    },
    optionSelectedIndex: {
      required: true
    }
  },
  computed: {

    getTeam() {
      return this.$store.getters.getterTeam
    },
    getRoom() {
      return this.$store.getters.getterRoom
    },
    getQuizOptions() {
      return this.getRoom.roomOptionsOrder.map((order) => this.currentQuizQuestion.options[order])
    }
  },
  methods: {
    getLetterNumber(number) {
      return getLetterNumber(number)
    }
  }
}
</script>

<style>

</style>