<template>
  <div class="w-full h-full rounded border border-yellow border-only-corner p-2 relative flex">
    <span></span>
    
    <div class="w-85/100 h-full bg-smoke-light rounded text-white flex items-center justify-center">
      <img v-if="currentQuizQuestion.file_url" class="w-full h-full object-contain bg-center rounded" :src="currentQuizQuestion.file_url" alt=""/>
      <p v-else class="text-white" >{{currentQuizQuestion.content}}</p>
    </div>
    <div class="w-15/100 h-full flex flex-col">
      <div 
          class="w-full flex-1 p-2"
          v-for="(option, index) in getQuizOptions"
          :key="option.id"
          :title="`${option.is_correct ? 'Correta' : 'Incorreta'}`"
        >
          <button
            @click="selectOption(option, index)"
            :class="[index === optionSelectedIndex ? 'bg-black' : 'bg-smoke-yellow']"
            class="w-full h-full p-1 px-3 rounded border border-yellow border-only-corner relative flex items-center justify-center">
            <span v-if="index !== optionSelectedIndex" class="-mb-1 text-xl uppercase text-white font-bold">{{getLetterNumber(index)}}</span>
            <span v-else class="-mb-1 text-sm uppercase text-white font-bold">CONFIRMAR</span>
          </button>
        </div>
    </div>
  </div>
</template>

<script>
import { getLetterNumber } from '@/util/Functions'
export default {
  name: 'GameQuizLeaderBoard',
  props: {
    game: {
      required: true
    },
    quizQuestions: {
      required: true
    },
    currentQuizQuestion: {
      required: true
    },
    optionSelectedIndex: {
      required: true
    }
  },
  data() {
    return {
      nextGameRequestSent: false
    }
  },
  computed: {
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getRoom() {
      return this.$store.getters.getterRoom
    },
    getCurrentStep() {
      return this.$route.params.step || 0
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
    getQuizOptions() {
      return this.getRoom.roomOptionsOrder.map((order) => this.currentQuizQuestion.options[order])
    }
  },
  mounted() {
    window.$socket.emit('gameSelectOptionIndex', { index: false })
  },
  methods: {
    selectOption(option, index) {
      if(index !== this.optionSelectedIndex) {
        window.$socket.emit('gameSelectOptionIndex', { index })
        return
      }
      this.storeAnswer(option)
    },
    getLetterNumber(number) {
      return getLetterNumber(number)
    },
    storeAnswer(option) {
      if(this.nextGameRequestSent) return
      const data = {
        game_id: this.game.id,
        project_id: this.getProject.id,
        team_id: this.getTeam.id,
        game_current_time: this.getTotalGameTime,
        team_current_time: this.getTotalTeamTime,
        quiz_question_id: this.currentQuizQuestion.id,
        quiz_question_option_id: option.id
      }

      this.$api.post(`/game/answer/quiz_store`, data)
        .then(({ data }) => {
          if(data.answer.is_correct) {
            this.$socket.emit('openToast', { title: 'Parabéns', message: 'Resposta correta!' })
            this.redirectToNewStepOrGame()
            window.playSound(`correct-answer`)
          } else {
            this.$socket.emit('openToast', { title: 'Ops!', message: 'Resposta errada!' })
            window.playSound(`wrong-answer`)
          }
          window.$socket.emit('gameSelectOptionIndex', { index: -1 })
          this.$socket.emit('refreshLoggedPlayer')
        })
        .catch(() => {
          window.$socket.emit('gameSelectOptionIndex', { index: -1 })
          this.$gameToast({ title: 'Ops!', message: 'Resposta errada!' })
          this.$api.post(`/game/room/go_to_current_game`, { team_id: this.getTeam.id })
        })
    },

    redirectToNewStepOrGame() {
      this.$api.post(`/game/room/next_step_or_game`, {
        project_id: this.getProject.id, 
        game_id: this.game.id, 
        team_id: this.getTeam.id,
      })
    }
  }
}
</script>