

export default {
  setTwilioPlayerToken(state, { token }) {
    state.twilioPlayerToken = token
  },
  setVideoIsOn(state, value) {
    state.videoIsOn = value
  },
  setAudioIsOn(state, value) {
    state.audioIsOn = value
  },
  setVideoDeviceLabel(state, label, deviceId) {
    state.videoDeviceLabel = label
    if(deviceId) state.videoDeviceId = deviceId
  },
  setAudioDeviceLabel(state, label, deviceId) {
    state.audioDeviceLabel = label
    if(deviceId) state.audioDeviceId = deviceId
  },
}
