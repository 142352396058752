import Api from '@/util/Api'
import EventBus from '@/util/EventBus'
import MainRouter from '@/mainRouter'

export default {

  attemptPlayerLogin(context, { pin, projectSlug }) {
    Api.post(`/player_project/login`, { pin, projectSlug }, { loader: true })
    .then((response) => {

      const { token } = response.data

      context.commit('setLoggedUserToken', { token: token, type: 'player' })
      context.dispatch('getLoggedPlayer', { projectSlug, redirect: true })

    })
    .catch((err) => {
      if(err.response && err.response.status == 401) {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Usuário ou senha incorretos'})
      } else {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Erro ao efetuar login, verifique sua conexão'})
      }
    })
  },
  
  getLoggedPlayer(context, { projectSlug, redirect = false }) {
    Api.get(`/player_project/get_logged_player/${projectSlug}`)
    .then(({ data }) => {
      
      const { player, project, playerProject, team } = data
      
      context.commit('setProject', { project })
      context.commit('setPlayer', { player })
      context.commit('setTeam', { team })
      context.commit('setPlayerProject', { playerProject })
      
      context.dispatch('setupRoom')
      context.dispatch('setupGeneralUserSocket')
      

      if(redirect && !player.is_filled) {
        return MainRouter.push({ name: 'GamePlayerForm', params: { playerProjectId: playerProject.id }}, () => {})
      }

      if(redirect) {
        return MainRouter.push({ name: 'GameLobby', params: { projectSlug, teamId: team.id }}, () => {})
      }
      
    })
    .catch((err) => {
      if (err.response && [401, 403].includes(err.response.status)) {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Usuário bloqueado pela plataforma'})
        context.dispatch('logoutUser', { showNotification: true, redirect: true })
      } else {
        EventBus.$emit('openToast', { title: 'OPS!', message: 'Erro ao efetuar login, verifique sua conexão'})
      }
    })
  },

  refreshLoggedPlayer(context) {
    Api.get(`/player_project/get_logged_player/${context.getters.getterProject.slug}`)
    .then(({ data }) => {
      const { player, project, playerProject, team } = data
      context.commit('setProject', { project })
      context.commit('setPlayer', { player })
      context.commit('setTeam', { team })
      context.commit('setPlayerProject', { playerProject })
    })
  },

  logoutPlayer(context, { showNotification = true, redirect = false } = {}) {
    
    context.commit('setLogout')
    context.commit('setPlayer', { player: null})
    context.commit('setProject', { project: null})
    context.commit('setPlayerProject', { playerProject: null})

    window.$socket.off('room')
    window.$socket.off('goToNextGame')
    window.$socket.emit('leaveRoom')
    if(window.$room) {
      context.dispatch('toggleVideo', { forceStop: true })
      window.$room.disconnect()
    }
    
    clearInterval(context.getters.getterLastSeenInterval)

    if(showNotification) EventBus.$emit('openToast', { title: 'Use alcool em gel!', message: 'Deslogado com sucesso'})
    if(redirect) MainRouter.push('/', () => {})
  },

  setupGeneralUserSocket(context) {

    // Remove listeners before add it again to avoid duplication
    window.$socket.off('roomRedirect')
    window.$socket.off('refreshLoggedPlayer')
    window.$socket.off('openToast')
    
    // Add general sockets
    window.$socket.on('roomRedirect', ({ route }) => {
      context.dispatch('refreshLoggedPlayer')
      if(MainRouter.currentRoute.name !== 'GamePlayerForm') MainRouter.push(route, () => {})
    })
    window.$socket.on('refreshLoggedPlayer', () => {
      context.dispatch('refreshLoggedPlayer')
    })
    window.$socket.on('openToast', ({ title, message }) => {
      window.$vueInstance.$gameToast({ title, message })
    })

  }

}
