<template>
  <div class="px-0.1 py-0.1 flex items-center justify-center block shadow-sm rounded-sm text-white font-semi-bold text-base" :class="status ? 'bg-green' : 'bg-grey'">
    <span v-if="status">{{ positiveLabel }}</span>
    <span v-else>{{ negativeLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'StatusLabel',
  props: {
    status: {
      type: Boolean,
      required: true
    },
    positiveLabel: {
      type: String,
      default: 'Sim'
    },
    negativeLabel: {
      type: String,
      default: 'Não'
    },
  }
}
</script>