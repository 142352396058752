<template>
  <div class="w-full h-full flex flex-col">

    <div class="w-full flex border-b border-smoke-extra-light">
      <div class="w-25/100 flex items-center justify-center p-2">
        <img class="h-5vh" :src="getProject.company.logo_url" />
      </div>
      <div class="w-50/100 border-l border-r border-smoke-extra-light flex items-center justify-center relative">
        <div class="border-title"  v-if="getReportTitle"></div>
        <h2 class="font-bold text-xl text-black text-center">
          <span v-if="getReportTitle">{{getReportTitle}}</span>
          <div v-else class="flex flex-col">
            <span class="text-base font-light">Nome da empresa</span>
            <span>{{getProject.company.name}}</span>
          </div>
        </h2>
      </div>
      <div class="w-25/100  flex items-center justify-center">
        <h2 class="font-bold text-xl text-black text-center">
          <span v-if="!$store.getters.getterPlayerIsManager">{{getTeam.name}}</span>
          <span v-else>Geral</span>

          <SwitchToggle :value="$store.getters.getterPlayerIsManager" @input="$store.commit('setPlayerIsManager', !$store.getters.getterPlayerIsManager)" label="Manager" />
        </h2>
      </div>
    </div>

    <div :class="[bodyClasses]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { reportManagerComponents, reportPlayerComponents } from '../reportList'
import { find } from '@/util/Functions'
export default {
  name: 'ReportWithHeader',
  props: {
    bodyClasses: {
      default: 'w-full h-full flex flex-col p-6'
    },
    title: {
      default: null
    }
  },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getReportRoutes() {
      if(this.$route.params.userType == 'gerencial') {
        return reportManagerComponents
      } else {
        return reportPlayerComponents
      }
    },
    getRouteReport() {
      if(!this.$route.params.reportType || this.$route.params.reportType == 'home') {
        return this.getReportRoutes[0]
      }
      const route = find(this.getReportRoutes, { slug: this.$route.params.reportType})
      if(route) {
        return route
      } else {
        return this.getReportRoutes[0]
      }
      
    },
    getReportTitle() {
      if(this.title) return this.title
      return this.getRouteReport.title
    }
  },
  mounted() {
    if(this.getProject) this.$store.dispatch('getReportLoggedPlayer', ({ projectSlug: this.getProject.slug }))
  }
}
</script>

<style>

.border-title {
  position: absolute;
  bottom: 0;
  left: calc(50% - 40px);
  width: 80px;
  height: 4px;
  display: block;
  background-color: #FBBD0E;
  z-index: 10;
  margin-bottom: -2px;
}

</style>