<template>
  <BaseModal
    ref="gameChatModal"
    title="Chat"
    modalDialogClasses="max-w-xl bg-black text-white"
    @onCloseModal="closeChat"
  >
    <div 
      class="w-full h-60vh overflow-y-auto hide-scrollbar flex flex-wrap py-2 relative" 
      id="chat-messages-container"
      ref="chatMessagesContainer"
    >
      <div class="w-full flex flex-wrap trasition duration-300" :class="[isFinishedLoad ? 'opacity-100' : 'opacity-0']">
        <div 
          v-for="(message, index) in team_messages"
          :key="index"
          class="w-full flex flex-col mb-3 flex-shrink-0"
          :class="[getMessageClasses(message)]"
        >
          <div class="rounded-sm border border-grey bg-yellow-smoke p-2" >
            {{message.content}}
          </div>
          <h4 class="mt-1" v-if="message.from_type == 'player'">{{message.playername}}</h4>
          <h4 class="mt-1" v-else>{{message.username}} <span class="ml-1 text-yellow">líder oculto</span></h4>
          <p class="text-grey text-xs italic">{{message.created_at}}</p>
        </div>
      </div>
    </div>

    <button 
      v-if="containerHasScroll"
      class="absolute right-0 bottom-0 mb-20 mr-10 rounded-sm border border-grey bg-smoke p-1 flex items-center justify-center"
      @click="scrollToBottom()"
    >
      <feather type="chevron-down"></feather>
    </button>

    <div class="w-full h-10vh flex">
      <div class="w-full relative">
        <input
          type="text"
          id="message-input"
          placeholder="Insira sua mensagem"
          v-model="content"
          @keydown.enter="sendMessage()"
          autocomplete="off"
          autocapitalize="sentences"
          ref="input"
          class="block appearance-none outline-none w-full h-full border-2 rounded-sm border-grey focus:border-green-dark bg-black text-grey-darker text-lg p-3"
        />
        <button 
          @click="sendMessage()" 
          :class="[content != '' ? 'text-yellow' : 'text-grey']"
          :disabled="isSending"
          class="h-full absolute pin-y top-0 right-0 flex items-center pr-4 focus:outline-none"
        >
          <feather type="send"></feather>
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import dayjs from 'dayjs'
import { ArrayHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'GameChatModal',
  data() {
    return {
      content: '',
      team_messages: [],
      isSending: false,
      isFinishedLoad: false,
      offset: 0,
      chatDoesntHaveMoreMessagesForNow: false,
      isLoadingMoreMessages: false,
      containerHasScroll: false
    }
  },
  computed: {
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    hasScrollBottom() {
      let chatMessagesContainer = document.getElementById('chat-messages-container')
      if(chatMessagesContainer) {
        return chatMessagesContainer.scrollHeight - chatMessagesContainer.scrollTop === chatMessagesContainer.clientHeight
      }
      return false
    }
  },
  mounted() {
    this.$eventBus.$on('openChatModal', () => {
      this.$refs.gameChatModal.showModal = true
      this.getTeamMessages(true, true)
      this.isFinishedLoad = false
      setTimeout(() => {
        let chatMessagesContainer = document.getElementById('chat-messages-container')
        if(chatMessagesContainer) {
          chatMessagesContainer.scrollIntoView(false)
          chatMessagesContainer.addEventListener('scroll', this.infiniteScroll)
        }
      }, 100)
      this.$store.commit('setUnreadMessages', 0)
    })

    this.$socket.on('messageReceived', () => {
      try {
        this.getTeamMessages(false, true)
        window.playSound('receive-message')
        this.$store.commit('setUnreadMessages', (this.$store.state.gameChat.unreadMessages+1))
      } catch (error) {
        console.log(error)
      }
    })

    
  },
  beforeDestroy() {
    this.$eventBus.$off('openChatModal')
    this.$socket.off('messageReceived')
    let chatMessagesContainer = document.getElementById('chat-messages-container')
    if(chatMessagesContainer) {
      chatMessagesContainer.removeEventListener('scroll', this.infiniteScroll)
    }
  },
  methods: {

    getTeamMessages(firstLoad, messageReceived = false) {
      if(this.isLoadingMoreMessages) return
      if(this.team_messages.length > 0) {
        this.isLoadingMoreMessages = true
      }
      this.$api.get(`/game/team_message/get/${ this.getTeam.id }?offset=${ messageReceived ? 0 : this.offset }`)
        .then(({ data }) => {

          data.team_messages = data.team_messages.map(message => {
            message.timestamp = dayjs(message.created_at, "DD/MM/YYYY HH:mm:ss").valueOf()
            return message
          })


          this.chatDoesntHaveMoreMessagesForNow = data.team_messages.length == 0
          this.isLoadingMoreMessages = false
          setTimeout(() => {
            if(this.offset === 0) {
              this.scrollToBottom(firstLoad)
              this.mergeMessages(data.team_messages)
            } else {
              this.scrollToTheSameMessage(data.team_messages)
            }

            this.offset += 10
          }, 10)
        })
    },

    mergeMessages(messages) {
      this.team_messages = ArrayHelpers.uniqueByKey([...messages, ...this.team_messages], 'id').sort((a, b) => a.timestamp - b.timestamp)
    },

    scrollToBottom(firstLoad) {
      setTimeout(() => {
        let chatMessagesContainer = document.getElementById('chat-messages-container')
        if(firstLoad) {
         chatMessagesContainer.scrollTo({
            top: chatMessagesContainer.scrollHeight,
            left: 0,
          })
        } else {
          if(chatMessagesContainer) chatMessagesContainer.scrollTo({
            top: chatMessagesContainer.scrollHeight,
            left: 0,
            behavior: 'smooth'
          })
        }
        setTimeout(() => {
          this.isFinishedLoad = true
        }, 200)
      },100)
    },

    scrollToTheSameMessage(newMessages) {
      setTimeout(() => {
        let chatMessagesContainer = document.getElementById('chat-messages-container')
        if(chatMessagesContainer)  {

          //get height of the element
          let heightBefore = chatMessagesContainer.scrollHeight
          let currentScroll = chatMessagesContainer.scrollTop

          this.mergeMessages(newMessages)
          
          setTimeout(() => {
            let heightAfter = chatMessagesContainer.scrollHeight - (heightBefore - currentScroll)
            chatMessagesContainer.scrollTo({
              top: heightAfter,
              left: 0,
            })
          }, 1)
        }
      }, 50)
    },

    getMessageClasses(message) {
      if(message.from_type == 'user' && message.from_id == this.getPlayer.from_user_id) return 'items-end'
      if(message.from_id != this.getPlayer.id) return 'items-start'
      return 'items-end'
    },

    sendMessage() {
      if(this.content == '' || this.isSending) return
      this.isSending = true
      this.$api.post(`/game/team_message/store`, { 
        content: this.content, 
        team_id: this.getTeam.id,
        type: this.getPlayer.from_user_id ? 'user' : 'player'
      })
        .then(() => {
          try {
            this.content = ''
            this.scrollToBottom()
            this.getTeamMessages(false, true)
            this.isSending = false
            window.$socket.emit('messageReceived')
            window.playSound('send-message')
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          this.isSending = false
        })
    },

    infiniteScroll(event) {
      this.containerHasScroll = (event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) >= event.target.clientHeight
      if (this.team_messages.length && event.target.scrollTop == 0 && !this.chatDoesntHaveMoreMessagesForNow) {
        this.getTeamMessages()
      }
    },

    closeChat() {
      this.offset = 0
      this.isFinishedLoad = false
    }
  }
}
</script>