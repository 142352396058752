export default {

  data() {
    return {
      dragAndDropDragging: -1,
      dragAndDropUrl: ''
    }
  },
  methods: {
    /**
    * Drag and drop optionals
    */
    dragStart(which) {
      this.dragAndDropDragging = which
    },
    dragEnd() {
      this.dragAndDropDragging = -1
    },
    dragFinish(index, items){
      this.moveItem(this.dragAndDropDragging, index, items);
    },
    moveItem(from, to, items) {
      items.splice(to, 0, items.splice(from, 1)[0])
      this.remapItems(items)
    },
    reOrderGames(from, to, items) {
      items.splice(to, 0, items.splice(from, 1)[0]);
      this.remapItems(items)
    },
    remapItems(items) {
      const orderedItems = items.map( (item, index) => {
        return {
          id: item.id,
          order: index
        }
      })
      this.$api.post(this.dragAndDropUrl, { items: orderedItems })
        .then(() => {
          if(this.callbackDragAndDrop) this.callbackDragAndDrop()
        })
    },
  }
}