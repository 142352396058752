// import Api from '@/util/Api'
// import EventBus from '@/util/EventBus'
// import MainRouter from '@/mainRouter'
import { handleLocalParticipantVideo, handleTrackSubscribed } from '@/modules/game/player/vuex/twilioFunctions'

export default {

  setupRoom(context) {

    /**
     * Join room
     */
    window.$socket.emit('joinRoom', { 
      playerId: context.getters.getterPlayer.id, 
      playerProjectId: context.getters.getterPlayerProject.id, 
      playerName: context.getters.getterPlayer.name, 
      playerGeneration: context.getters.getterPlayer.generation, 
      playerCountry: context.getters.getterPlayer.country, 
      projectSlug: context.getters.getterProject.slug,
      teamId: context.getters.getterTeam.id,
      isLeader: context.getters.getterPlayerProject.is_leader
    })

    /**
     * Update room from socket
     */
    window.$socket.on('room', ({ room }) => {
      // console.log('room', room)
      // const oldRoom = JSON.parse(JSON.stringify(context.getters.getterRoom))
      // context.dispatch('checkRoomTracks', { oldRoom, updatedRoom: room }, { isRoot: true })
      context.commit('setRoom', { room })
    })

    /**
     * Update room from socket
     */
    window.$socket.on('connect', () => {
      if(context.getters.getterPlayer) {
        console.log('Dispachando setupRoom de novo devido à reconexão')
        context.dispatch('setupRoom')
      }
    })

    /**
     * Player last seen update interval
     */
    clearInterval(context.state.lastSeenInterval)
    const lastSeenInterval = setInterval(() => {
      if(context.getters.getterPlayer) {
        window.$socket.emit('updateLastSeen')
      } else {
        clearInterval(context.state.lastSeenInterval)
      }
    }, 5000)
    context.commit('setLastSeenInterval', lastSeenInterval)

    /**
     * Socket ping to get the server time
     */
    clearInterval(context.state.serverTimeInterval)
    context.commit('setServerTimeInterval', null)
    const serverTimeInterval = setInterval(() => {
      if(context.getters.getterPlayer) {
        window.$socket.emit('serverTimePing')
      } else {
        clearInterval(context.state.serverTimeInterval)
      }
    }, 1000)
    context.commit('setServerTimeInterval', serverTimeInterval)

    /**
     * Update server time from server
     */
    window.$socket.on('serverTimePong', ({ serverTime }) => {
      context.commit('setServerTime', serverTime)
    })

    /**
     * Update room leader id
     */
    window.$socket.on('setRoomLeaderId', ({ leader_id }) => {
      context.commit('setRoomLeaderId', leader_id)
    })

  },

  checkRoomTracks(context) {
    if(window.$room) {
      console.log('Starting check room tracks from: ', context.getters.getterPlayer.id)
      try {
        const participantsToUpdatePosition = Array.from(window.$room.participants.values())
          for(const participantToUpdatePosition of participantsToUpdatePosition) {
            console.log('participantToUpdatePosition', participantToUpdatePosition)
            if(participantToUpdatePosition.identity == context.getters.getterPlayer.id) {
              handleLocalParticipantVideo(context)
            } else {
              Array.from(participantToUpdatePosition.tracks.values()).forEach((publication) => {
                handleTrackSubscribed(participantToUpdatePosition, publication.track)
              })
            }
          }
      } catch (err) {
        console.log(err)
      }
    }
  }


}
