import BaseModel from './BaseModel'
import { getFormData } from '@/util/Functions'
export default class Game extends BaseModel {
  constructor(obj = {}) {
    super()
    this.key = obj.key || null
    this.id = obj.id || null
    this.name = obj.name || ''
    this.genius_mode = obj.genius_mode || false
    this.difficulty = obj.difficulty || 'normal'
    this.resolution_time = obj.resolution_time || 25
    this.resolution_block_after = obj.resolution_block_after || 16
    this.component = obj.component || 'GameQuiz'
    this.penalty_time = obj.penalty_time || 10
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
    
    this.introduction_is_url = obj.introduction_url != '' || false
    this.introduction_url = obj.introduction_url || ''
    
    this.introduction_file = obj.introduction_file || new File(["foo"], "", { type: "text/plain" })
    this.introduction_file_url = obj.introduction_file_url || ''
    this.introduction_file_path = obj.introduction_file_path || ''
    
    this.background_file = obj.background_file || new File(["foo"], "", { type: "text/plain" })
    this.background_file_url = obj.background_file_url || ''
    this.background_file_path = obj.background_file_path || ''

    this.quiz_questions = obj.quiz_questions || []
    this.puzzle_buttons = obj.puzzle_buttons || []
    this.puzzle_answer_options = obj.puzzle_answer_options || []
    this.tips = obj.tips || []

  }


  get requireds() {
    return [
      { item: 'name', label: 'nome' },
      { item: 'difficulty', label: 'dificuldade' },
      { item: 'resolution_time', label: 'tempo de resolução' },
      { item: 'resolution_block_after', label: 'tempo de travamento' },
      { item: 'component', label: 'tipo do game' },
      { item: 'introduction_file', label: 'image de introdução ou url', validation(value, obj) {
          if(obj.introduction_file_url || obj.introduction_url) return false
          if(!obj.introduction_file_url && !value.name) return true
          return false
        } 
      },
      { item: 'background_file', label: 'imagem background', validation(value, obj) {
        if(obj.introduction_file_url) return false
        if(!obj.background_file_url && !value.name) return true
        return false
      } 
    }
    ]
  }

  get getFormData() {
    return getFormData(this)
  }
    
}