<template>
  <div class="relative inline-block text-left z-50" v-click-outside="close">

    <div>
      <button @click="toggle()" type="button" :class="[buttonBg]" class="inline-flex justify-center w-full rounded-full px-4 py-3 font-medium hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 shadow-sm" id="options-menu" aria-haspopup="true" aria-expanded="true">
        <slot name="button"></slot>
      </button>
    </div>

    <transition
        enter-active-class="transition ease-out duration-100 transform"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition ease-in duration-100 transform"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
      <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-sm shadow-lg" v-if="show">
        <div class="rounded-sm bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <slot name="items"></slot>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>

export default {
  name: 'Dropdown',
  props: {
    buttonBg: {
      type: String,
      default: 'bg-white'
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
    close() {
      this.show = false
    }
  }
}
</script>
