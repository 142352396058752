<template>
  <div class="w-full h-full flex space-x-2 absolute transition transform duration-300 easy-in" id="personality-card">
    <div class="w-50/100 h-full rounded card-left-bg shadow-sm flex items-center relative">
      <h4 class="absolute top-0 right-0 text-base italic mt-2 mr-2 text-white">Hemisfério esquerdo lógico</h4>
      <img src="/reports/10/brain_left.svg" class="absolute right-0 flex-none max-h-80/100" />
      <div class="w-60/100 h-full max-h-80/100 flex flex-col pl-6 pr-0">
        <div class="w-full h-full flex justify-end items-center text-white border-b border-smoke-white-light pr-8">
          <h4 class="text-lg -mb-1">Executor(a)</h4>
          <h4 class="text-6xl ml-3">{{ personality.executor }}%</h4>
        </div>
        <div class="w-full h-full flex justify-end items-center text-white border-b border-smoke-white-light pr-8">
          <h4 class="text-lg -mb-1">Planejador(a)</h4>
          <h4 class="text-6xl ml-3">{{ personality.planner }}%</h4>
        </div>
        <div class="w-full h-full flex justify-end items-center text-white border-b border-smoke-white-light pr-8">
          <h4 class="text-lg -mb-1">Raciocínio lógico</h4>
          <h4 class="text-6xl ml-3">{{ personality.rational }}%</h4>
        </div>
        <div class="w-full h-full flex justify-end items-center text-white border-b border-smoke-white-light pr-8">
          <h4 class="text-lg -mb-1">Orientado(a) à resultados</h4>
          <h4 class="text-6xl ml-3">{{ personality.oriented }}%</h4>
        </div>
        <div class="w-full h-full flex justify-end items-center text-white pr-8">
          <h4 class="text-lg -mb-1">Trabalho em equipe</h4>
          <h4 class="text-6xl ml-3">{{ personality.team_work }}%</h4>
        </div>
      </div>
    </div>
    <div class="w-50/100 h-full rounded card-right-bg shadow-sm flex items-center justify-end relative">
      <h4 class="absolute top-0 left-0 text-base italic mt-2 ml-2 text-white">Hemisfério direito criativo</h4>
      <img src="/reports/10/brain_right.svg" class="absolute left-0 flex-none max-h-80/100" />
      <div class="w-60/100 h-full max-h-80/100 flex flex-col pr-6 pr-0">
        <div class="w-full h-full flex items-center text-white border-b border-smoke-white-light pl-8">
          <h4 class="text-6xl mr-3">{{ personality.communicator }}%</h4>
          <h4 class="text-lg -mb-1">Comunicador(a)</h4>
        </div>
        <div class="w-full h-full flex items-center text-white border-b border-smoke-white-light pl-8">
          <h4 class="text-6xl mr-3">{{ personality.work_under_pressure }}%</h4>
          <h4 class="text-lg -mb-1">Trabalha sob pressão</h4>
        </div>
        <div class="w-full h-full flex items-center text-white border-b border-smoke-white-light pl-8">
          <h4 class="text-6xl mr-3">{{ personality.influent }}%</h4>
          <h4 class="text-lg -mb-1">Influente</h4>
        </div>
        <div class="w-full h-full flex items-center text-white border-b border-smoke-white-light pl-8">
          <h4 class="text-6xl mr-3">{{ personality.emotional }}%</h4>
          <h4 class="text-lg -mb-1">Emocional equilibrado</h4>
        </div>
        <div class="w-full h-full flex items-center text-white pl-8">
          <h4 class="text-6xl mr-3">{{ personality.creative }}%</h4>
          <h4 class="text-lg -mb-1">Criativo(a)</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Report10PlayerPersonality',
  props: ['player', 'team'],
  data() {
    return {
      personality: {
        executor: 0,
        planner: 0,
        rational: 0,
        oriented: 0,
        team_work: 0,
        communicator: 0,
        work_under_pressure: 0,
        influent: 0,
        emotional: 0,
        creative: 0
      }
    }
  },
  watch: {
    'player': function() {
      this.getPersonality()
    } 
  },
  methods: {
     getPersonality() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_project_personality')}/${this.player.id}`)
        .then(({ data }) => {
          this.personality = data.personality
        })
    },
  }
}
</script>



<style>

  .card-left-bg {
    background-color: #8B7EC4;
  }

  .card-right-bg {
    background-color: #EF9B44;
  }

</style>