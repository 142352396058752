<template>
  <div 
    class="w-full h-screen flex flex-wrap items-center justify-center my-auto bg-yellow p-3 transition-background-position" 
    :style="getReportBg"
  >
    
    <BaseLoader></BaseLoader>
    <router-view></router-view>

  </div>
</template>

<script>
import { randomInt } from '@/util/Functions'
export default {
  name: 'LandingLayout',
  metaInfo () {
    return {
      title: `Relatório |`
    }
  },

  data() {
    return {
      reportBg: null
    }
  },
  watch: {
    '$route.fullPath': function() {
      this.generateReportBg()
    }
  },

  computed: {
    getReportBg() {
      if(this.reportBg) return this.reportBg
      const left = randomInt(50, 0)
      const top = randomInt(50, 0)
      return {
        backgroundImage: `url(/img/report_bg.png)`,
        backgroundSize: `150%`,
        backgroundPosition: `${left}% ${top}%`,
      }
    }
  },

  methods: {
    generateReportBg() {
      const left = randomInt(50, 0)
      const top = randomInt(50, 0)
      this.reportBg = {
        backgroundImage: `url(/img/report_bg.png)`,
        backgroundSize: `150%`,
        backgroundPosition: `${left}% ${top}%`,
      }
    }
  }
}
</script>

<style>
  @import "../../../css/game/index.css";

  .transition-background-position {
    -webkit-transition:background-position 1s ease-in-out;
    -moz-transition:background-position 1s ease-in-out;
    -o-transition:background-position 1s ease-in-out;
    transition:background-position 1s ease-in-out;
  }
</style>