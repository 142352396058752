import CompanyList from '@/modules/admin/company/CompanyList'
import CompanyForm from '@/modules/admin/company/CompanyForm'
export default [
  {
    path: 'companies',
    name: 'CompanyList',
    component: CompanyList,
    meta: {
      breadcrumb: 'Dashboard / Cliente / Lista'
    }
  },
  {
    path: 'companies/add',
    name: 'CompanyAdd',
    component: CompanyForm,
    meta: {
      breadcrumb: 'Dashboard / Cliente / Novo cliente'
    }
  },
  {
    path: 'companies/update/:companyId',
    name: 'CompanyUpdate',
    component: CompanyForm,
    meta: {
      breadcrumb: 'Dashboard / Cliente / Atualizar cliente'
    }
  }
]