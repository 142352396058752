<template>
  <fieldset class="w-full">
    <legend class="h4 font-semi-bold mb-2">Games</legend>
    <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
      <div class="w-full">
        <label>Selecione um game</label>
        <v-select 
          class="vue-select"
          placeholder="Selecione um game"
          :class="gameSelected ? 'filled' : ''"
          label="name"
          :options="gamesToSelect"
          :filter="filterGames"
          v-model="gameSelected"
        >
          <span slot="no-options">Não localizamos nenhum game para adicionar</span>
        </v-select>
      </div>
      <button
        @click="addGame()" 
        class="button button-yellow ml-4">
        Adicionar game
      </button>
    </div>

    <div class="w-full overflow-x-auto max-w-full flex flex-col mt-6">
      <div class="w-full my-2 hidden lg:flex">
        <p class="w-40/100 flex items-center pl-4">Game</p>
        <p class="w-20/100 flex items-center pl-4">Dificuldade</p>
        <p class="w-20/100 flex items-center pl-4">Tempo</p>
        <p class="w-20/100 flex items-center pl-4">Excluir</p>
      </div>
      <div 
        class="w-full bg-white rounded flex flex-wrap shadow-sm my-2 font-bold cursor-move"
        v-for="(game, index) in games"
        :key="`project-game-${index}`"
        draggable="true"
        @dragstart="dragStart(index)"
        @dragover.prevent
        @dragend="dragEnd"
        @drop="dragFinish(index, games)"
      >
        <div class="block w-full lg:w-40/100 p-4 flex items-center justify-center lg:border-r border-smoke-light text-center lg:text-left">
          <span class="flex mr-2">
            {{game.order}}
            <button class="border-2 p-1 flex items-center ml-1" @click="reOrderGames(index, index-1, games)" :disabled="index <1"><feather type="arrow-up" size="20"></feather></button>
            <button class="border-2 p-1 flex items-center ml-1" @click="reOrderGames(index, index+1, games)" :disabled="index >= games.length-1"><feather type="arrow-down" size="20"></feather></button>
          </span>
          <h5 class="w-full lg:text-left">{{game.name}}</h5>
        </div>
        <div class="block w-full lg:w-20/100 p-4 flex items-center justify-center lg:border-r border-smoke-light">
          <GameDifficultyLabel :difficulty="game.difficulty"></GameDifficultyLabel>
        </div>
        <div class="block w-full lg:w-20/100 p-4 flex items-center justify-center lg:border-r border-smoke-light">
          <h5 class="w-full text-center">{{game.resolution_time}}</h5>
        </div>
        <div class="block w-full lg:w-20/100 p-4 flex items-center justify-center">
          <button 
            @click="removeGame(game.id)"
            class="button button-border-red focus:outline-none">
            Excluir
          </button>
        </div>
      </div>
    </div>

  </fieldset>
</template>

<script>
export default {
  name: 'ProjectGameList',
  props: ['project'],
  data() {
    return {
      games: [],
      gamesToSelect: [],
      gameSelected: null,
      dragging: null
    }
  },
  mounted() {
    this.getProjectGames()
    this.dragAndDropUrl = `/admin/project_game/sync_order/${this.$route.params.projectId}`
  },
  methods: {

    getGamesToSelect() {
      this.gameSelected = null
      const gamesSelectedIds = this.games.map((game) => `"${game.id}"`)

      const query =  `
        {
          games (
            whereNotIn: [
              { column: "id", value: [${gamesSelectedIds}] }
            ]
            where: [
              { column: "is_active", operation: "=", value: "true" }
            ]
            orderBy: [
              { column: "name", order: "ASC" }
            ]
          ) {
            id
            name
            component
            difficulty
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.gamesToSelect = data.games
        })
    },

    filterGames(options, search) {
      return options.filter((item) => {
        if(item.name.toLowerCase().includes(search.toLowerCase())) {
          return true
        }
        return false
      })
    },

    getProjectGames() {
      const query =  `
        {
          project (
            findBy: { column: "id", value: "${this.$route.params.projectId}"}
          ) {
            id
            games {
              id
              name
              difficulty
              resolution_time
              is_active
              pivot {
                order
              }
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.games = data.project.games
          this.getGamesToSelect()
        })
    },

    addGame() {
      this.games.push(this.gameSelected)
      this.remapItems(this.games)
    },

    removeGame(gameId) {
      this.$api.post(`/admin/project_game/remove_game/${this.$route.params.projectId}`, { gameId })
        .then(() => {
          this.getProjectGames()
        })
    },

    callbackDragAndDrop() {
      this.getProjectGames()
    }

  }
}
</script>