<template>
  <ReportWithHeader>
    <div class="w-full flex flex-col p-4 px-8">
      <div class="w-full flex space-x-6">
        <ItemCard 
          class="w-full"
          title="Data do Desafio"
          :subtitle="getProject.event_start_at_date"
          bg_color="bg-purple"
          icon="/reports/02/icon_desafio.svg"
        ></ItemCard>
        <ItemCard 
          class="w-full"
          title="Nome do time"
          subtitle="Os Ativados"
          bg_color="bg-peach"
          icon="/reports/02/icon_name.svg"
        ></ItemCard>
        <ItemCard 
          class="w-full"
          title="Nome do Desafio"
          :subtitle="getProject.name"
          bg_color="bg-yellow-card"
          icon="/reports/02/icon_nome_desafio.svg"
        ></ItemCard>
      </div>
      <div class="w-full flex space-x-6 mt-4">
        <ItemCard 
          class="w-full"
          title="Ranking"
          subtitle="3º lugar"
          bg_color="bg-purple"
          icon="/reports/02/icon_ranking.svg"
        ></ItemCard>
        <ItemCard 
          class="w-full"
          title="Qtd. Participantes"
          :subtitle="projectPlayerCount"
          bg_color="bg-peach"
          icon="/reports/02/icon_qtd.svg"
        ></ItemCard>
        <ItemCard 
          class="w-full"
          title="Diversidades"
          bg_color="bg-yellow-card"
          icon="/reports/02/icon_dvs.svg"
        >
          <img class="h-3vh" src="/icons/box_inclusao.svg" />
        </ItemCard>
      </div>
    </div>

  </ReportWithHeader>
</template>

<script>
import ItemCard from './ItemCard'
export default {
  name: 'ReportResume',
  components: {
    ItemCard
  },
  data() {
    return {
      projectPlayerCount: 0
    }
  },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    }
  },
  mounted() {
    this.getProjectPlayerCount()
  },
  methods: {
    getProjectPlayerCount() {
      const query = `
        {
          project ( 
            findBy: { column: "id", value: "${this.getProject.id}" } 
          ) {
            id
            name
            players {
              id
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.projectPlayerCount = data.project.players.length
        })
    }
  }
}
</script>

<style>

.bg-yellow-card {
  background-color: #fece66;
}

</style>