<template>
  <ReportWithHeader>
    <div class="w-full flex space-x-6 h-full h-40vh">
      <GenerationCard v-for="(item, index) in generationPercentage" :key="index" :item="item" :index="index"></GenerationCard>
    </div>
    <div class="w-full h-30vh mt-4 flex items-stretch">

      <div class="w-70/100 flex flex-wrap space-y-4 h-full">
        <div class="w-full flex" v-for="(generation, index_generation) in items" :key="`generation_${index_generation}`">
          <div class="w-15/100 h-full text-base flex flex-col items-start justify-center font-bold">
            <h4 :style="{ color: getGenerationInfo(generation.title).color }">{{generation.title}}</h4>
            <h4 class="text-grey">({{ getGenerationInfo(generation.title).born_between }})</h4>
          </div>

          <div class="w-15/100 flex flex-col text-base space-y-1">
            <h5 class="h-full font-semi-bold text-right ellipsis">Língua Estrangeira</h5>
            <h5 class="h-full font-semi-bold text-right ellipsis">Escolaridade</h5>
          </div>

          <div class="w-70/100 flex flex-col px-2 h-full space-y-1">
            <div class="w-full flex h-full">
              <div 
                class="block flex text-base items-center justify-center text-white font-semi-bold" 
                v-for="(foreign_language, index_foreign_language) in generation.foreign_language" 
                :key="`index_foreign_language_${index_foreign_language}`"
                :style="{ backgroundColor: getForeignLanguage(foreign_language.title).color, width: `${foreign_language.percentage}%` }"
              >
                {{ foreign_language.count }}
              </div>
            </div>
            <div class="w-full flex h-full">
              <div 
                class="block flex text-base items-center justify-center text-white font-semi-bold" 
                v-for="(education, index_education) in generation.education" 
                :key="`index_education_${index_education}`"
                :style="{ backgroundColor: getEducation(education.title).color, width: `${education.percentage}%` }"
              >
                {{ education.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-30/100 border border-smoke-light rounded p-2 flex flex-col space-y-1">
        <div class="w-full flex items-center space-x-2 text-base" v-for="index in playerSchoolarship.length" :key="index">
          <div class="item-color" :style="{ backgroundColor: `${playerSchoolarship[index-1].color}`}"></div>
          <p class="w-90/100">
            Escolaridade: {{ playerSchoolarship[index-1].label }}
            /
            Idioma estrangeiro: {{ playerForeignLanguage[index-1].label }}
          </p>
        </div>
      </div>
    </div>
  </ReportWithHeader>
</template>

<script>
import GenerationCard from '../shared/GenerationCard.vue'
import { chartAvailableColors, playerSchoolarship, playerForeignLanguage } from '@/models/Enums'
import getGenerationInfo from '@/util/getGenerationInfo'
import { find } from '@/util/Functions'
export default {
  name: 'Report05',
  components: { GenerationCard },
  data() {
    return {
      generationPercentage: [],
      items: [],
      chartAvailableColors,
      playerSchoolarship,
      playerForeignLanguage
    }
  },
  mounted() {
    this.getGenerationPercentage()
    this.getGenerationStudyForeignLanguage()
  },
  methods: {
    getGenerationPercentage() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_generation_report')}`)
        .then(({ data }) => {
          this.generationPercentage = data.items
        })
    },

    getGenerationStudyForeignLanguage() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_generation_study_foreign_language_report')}`)
        .then(({ data }) => {
          this.items = data.items
        })
    },

    getGenerationInfo(generation) {
      return getGenerationInfo(generation)
    },

    getForeignLanguage(foreign_language) {
      return find(this.playerForeignLanguage, { value: foreign_language})
    },
    getEducation(education) {
      return find(this.playerSchoolarship, { value: education })
    }
  }
}
</script>

<style>
  .item-color {
    margin-top: 4px;
    width: 12px;
    height: 12px;
    display: block;
    flex: none;
    border-radius: 2px;
  }

</style>