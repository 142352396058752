<template>
  <div class="w-full relative">
    <input
      type="text"
      :value="value"
      :placeholder="placeholder || label"
      @blur="$emit('blur')"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="$emit('keydown', $event.target.value)"
      class="block appearance-none rounded bg-white focus:shadow-sm outline-none w-full border-2 border-grey-light focus:border-yellow placeholder-grey bg-white text-grey-darker text-lg py-2 px-3 pl-10"
    />
    <button
      class="h-full absolute pin-y top-0 left-0 mr-3 flex items-center pl-2 focus:outline-none">
      <feather type="search" class="text-yellow" size="22"></feather>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: ['value', 'placeholder', 'label']
}
</script>