<template>
  <BaseGameLayout>
    <div class="w-full h-full flex flex-wrap items-center">
      <div class="w-40/100 px-10 flex flex-col justify-center items-center">
        <img src="/img/logo_ideia_white.svg" class="max-h-full" width="244px" alt="Logo ideia ativada" />
        <InputGame
          v-model="pin"
          placeholder="Insira o pin"
          type="tel"
          :mask="'########'"
          @keydown.enter="login()"
        ></InputGame>

        <button @click="login()" class="button-lg button-game button-black mt-6 w-full border-only-corner relative">
          <span>Entrar</span>
        </button>
      </div>

      <div class="w-60/100 flex flex-col pr-4 ">
        <GameTeamAuthCard
          v-for="team in project.teams" 
          :key="team.id"
          :team="team"
          :project="project"
          @setPin="pin = $event"
        />
      </div>
    </div>
  </BaseGameLayout>
</template>

<script>
import GameTeamAuthCard from './GameTeamAuthCard.vue'
export default {
  name: 'GameHome',
  components: { GameTeamAuthCard },
  metaInfo () {
    return {
      title: `${this.project.name} |`
    }
  },
  data() {
    return {
      pin: '',
      project: { name: '', teams: [] },
    }
  },
  computed: {
    getAllProjectPlayers() {
      const players = []
      this.project.teams.map((team) => {
        team.players_project.map((pp) =>{
           players.push({ team, ...pp })
        })
      })
      return players
    }
  },
  mounted() {
    this.getProject()
    this.$store.dispatch('logoutPlayer')
  },
  methods: {
    getProject() {

      const query =  `
        {
          project (
            findBy: { column: "slug", value: "${this.$route.params.projectSlug}"}
          ) {
            id
            name
            slug
            teams {
              id
              name
              players_project {
                id
                pin
                player {
                  id
                  name
                }
              }
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.project = data.project
        })
    },

    login() {
      this.$store.dispatch('attemptPlayerLogin', { pin: this.pin, projectSlug: this.$route.params.projectSlug })
    }
    
  }
}
</script>

<style>

</style>