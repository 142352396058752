import BaseModel from './BaseModel'
import { getFormData } from '@/util/Functions'

export default class Tip extends BaseModel {
  
  constructor(obj = {}) {
    super()
    this.key = obj.key || null
    this.id = obj.id || null
    this.item_id = obj.item_id || null
    this.item_type = obj.item_type || ''
    this.title = obj.title || ''
    this.content = obj.content || ''
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
    
    this.file = obj.file || new File(["foo"], "", { type: "text/plain" })
    this.file_url = obj.file_url || ''
    this.file_path = obj.file_path || ''

  }

  get requireds() {
    return [
      { item: 'title', label: 'título' },
      { item: 'file', label: 'conteúdo ou imagem', validation(value, obj) {
        if(obj.content && value.name) {
          this.label = 'escolha APENAS uma opção entre conteúdo ou imagem'
          return true
        }
        if(obj.content) return false
        if(!value.name && !obj.file_url) return true
          return false
        } 
      }
    ]
  }

  get getFormData() {
    return getFormData(this)
  }
    
}