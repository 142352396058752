import { chartAvailableColors } from '@/models/Enums'

export default (generation) => {
  if(generation == 'Baby Boomers')
      return { 
        born_between: '1946 - 1964',
        text: 'Força de trabalho e dedicação.',
        color: chartAvailableColors[0],
        markers: [
          'Guerra do Vietnã',
          'Explosão do Rock and Roll',
          'Surgimento da TV em cores'
        ]
      }
    if(generation == 'Geração X')
      return { 
        born_between: '1965 - 1980',
        text: 'Força, Foco e Aposentadoria.',
        color: chartAvailableColors[1],
        markers: [
          'Golpe Militar de 1964',
          'Neil Armstrong pisar na Lua',
          'Surgimento do computedor pessoal, da internet, do celulaar e do email'
        ]
      }
    if(generation == 'Geração Y')
      return { 
        born_between: '1981 - 1996',
        text: 'Dinheiro não é tudo!',
        color: chartAvailableColors[2],
        markers: [
          'Maior desastre atômico do mundo em Chernobyl',
          'Queda do muro de Berlim'
        ]
      }
    if(generation == 'Geração Z')
      return { 
        born_between: '1997 - 2012',
        text: 'Nós vamos mudar o mundo!',
        color: chartAvailableColors[3],
        markers: [
          'Atentado terrorista ao World Trade Center nos EUA',
          'A internet ficar Mobile'
        ]
      }
    return { 
      born_between: '1946 - 1964',
      text: 'Força de trabalho e dedicação.',
      color: chartAvailableColors[0],
      markers: [
        'Guerra do Vietnã',
        'Explosão do Rock and Roll',
        'Surgimento da TV em cores'
      ]
    }
}