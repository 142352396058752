<template>
  <div id="app" class="w-full f-ull bg-grey-light">
    <div class="w-full flex h-full">

      <BaseLoader></BaseLoader>
      <AdminSidebar></AdminSidebar>

      <div class="w-full h-auto overflow-y-auto bg-white">
        <AdminHeader></AdminHeader>
        <div class="w-full p-3 bg-grey-light min-h-full pb-10 flex justify-center" id="main-content">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminLayout',
  metaInfo () {
    return {
      title: `Admin |`
    }
  },
}
</script>