import ApolloClient, { InMemoryCache} from 'apollo-boost'
import { gql } from 'apollo-boost';

const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_BASE_URL + `/graphql`,
  cache: new InMemoryCache( { addTypename: false })
})

/**
 * graphql
 * query
 * fetchPolicy: 'cache-first' || 'network-only'
 */
export default async ({ query, fetchPolicy = 'network-only' }) => {
  const response = await apolloClient.query({ query: gql`${query}`, fetchPolicy })
  return response
}