import BaseModel from './BaseModel'
import { getFormData } from '@/util/Functions'
export default class PuzzleButton extends BaseModel {

  constructor(obj = {}) {
    super()
    this.key = obj.key || null
    this.id = obj.id || null
    this.game_id = obj.game_id || null
    this.left_position = obj.left_position || 0
    this.top_position = obj.top_position || 0
    this.access = obj.access || 'all'
    this.label = obj.label || ''
    this.title = obj.title || ''
    this.content = obj.content || ''
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
    
    this.file = obj.file || new File(["file"], "", { type: "text/plain" })
    this.file_url = obj.file_url || ''
    this.file_path = obj.file_path || ''
    
    this.icon = obj.icon || new File(["icon"], "", { type: "text/plain" })
    this.icon_url = obj.icon_url || ''
    this.icon_path = obj.icon_path || ''

  }

  get requireds() {
    return [
      { item: 'left_position', label: 'posicionamento', validation: value => value <= 0 },
      { item: 'access', label: 'acesso do botão' },
      { item: 'title', label: 'título do botão' },
      { item: 'file', label: 'conteúdo ou imagem do botão', validation(value, obj) {
        if(obj.content && value.name) {
          this.label = 'escolha APENAS uma opção entre conteúdo ou imagem'
          return true
        }
        if(obj.content) return false
        if(!value.name && !obj.file_url) return true
          return false
        } 
      },
      { item: 'icon', label: 'título (sigla) ou imagem do botão', validation(value, obj) {
        if(obj.label && value.name) {
          this.label = 'escolha APENAS uma opção entre conteúdo ou imagem'
          return true
        }
        if(obj.label) return false
        if(!value.name && !obj.icon_url) return true
          return false
        } 
      }
    ]
  }

  get getFormData() {
    return getFormData(this)
  }
    
}