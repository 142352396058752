import { getFormData } from '@/util/Functions'
export default class ProjectUserListImportFile {
  constructor(obj = {}) {
    this.file = obj.file|| new File(["foo"], "", { type: "text/plain" })
  }

  get getFormData() {
    return getFormData(this)
  }
    
}