import BaseModel from './BaseModel'
import Company from '@/models/Company'
import dayjs from 'dayjs'
import { getFormData } from '@/util/Functions'
export default class Project extends BaseModel {
  constructor(obj = {}) {
    super()
    this.key = obj.key || null
    this.id = obj.id || null
    this.slug = obj.slug || ''
    this.name = obj.name || ''
    this.company_id = obj.company_id || null
    this.event_start_at = obj.event_start_at || ''
    this.total_time = obj.total_time || 120
    this.url_questions = obj.url_questions || ''
    this.max_users = obj.max_users || 5
    this.user_quantity = obj.user_quantity || 4
    this.room_quantity = obj.room_quantity || 4
    this.contact_name = obj.contact_name || ''
    this.contact_phone = obj.contact_phone || ''
    this.contact_email = obj.contact_email || ''
    this.contact_source = obj.contact_source || ''
    this.status = obj.status || ''
    this.has_penalties = obj.has_penalties || false
    
    this.background_music = obj.background_music || new File(["foo"], "", { type: "text/plain" })
    this.background_music_url = obj.background_music_url || ''
    this.background_music_path = obj.background_music_path || ''

    
    if(this.event_start_at) {
      this.event_start_at_date = obj.event_start_at_date || dayjs(this.event_start_at, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
      this.event_start_at_hour = obj.event_start_at_hour || dayjs(this.event_start_at, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')
    }
    
    /**
     * Relationships
     */
    this.company = obj.company || new Company()
    this.games = obj.games || []
    this.teams = obj.teams || []
    
  }

  get getEventStartAtToStore() {
    return dayjs(`${this.event_start_at_date} ${this.event_start_at_hour}`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
  }

  get getFormData() {
    return getFormData(this)
  }

  get requireds() {
    return [
      { item: 'name', label: 'nome' },
      { item: 'company_id', label: 'cliente / empresa' },
      { item: 'event_start_date', label: 'data do evento' },
      { item: 'event_start_hour', label: 'hora do evento' },
      { item: 'room_quantity', label: 'quantidade de usuários por sala' }
    ]
  }

}
