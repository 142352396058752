<template>
  <div class="w-full h-screen relative overflow-hidden font-londrina-solid">
    <div class="absolute w-full block h-screen overflow-hidden z-0">
      <div class="bg-gradient-game"></div>
    </div>
    <div class="w-full flex h-full max-h-full z-10 relative">
      <BaseLoader></BaseLoader>
      <div class="w-full max-h-full h-auto overflow-y-auto" id="main-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GameLayout'
}
</script>

<style>
  @import "../../../css/game/index.css";
</style>