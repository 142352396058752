<template>
  <BaseModal
    ref="gamePuzzleAnswerModal" 
    modalDialogClasses="max-w-md bg-black"
  >
    <div class="w-full flex flex-col">
      <h2 class="w-full text-center font-bold text-xl">Insira a senha</h2>

      <div class="w-full">
        <InputGame
          v-model="answer_attempt"
          placeholder="senha"
          @keydown="checkAnswer()"
        ></InputGame>
      </div>
      
      <div class="w-full flex justify-center mt-3">
        <GameButton class="max-w-8vw" label="Verificar" :disabled="answer_attempt == ''" @click="checkAnswer()"></GameButton>
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'GamePuzzleAnswerModal',
  props: ['game'],
  data() {
    return {
      answer_attempt: '',
      nextGameRequestSent: false
    }
  },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
  },
  mounted() {
    this.$eventBus.$on('openGamePuzzleAnswerModal', () => {
      this.$refs.gamePuzzleAnswerModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openGamePuzzleAnswerModal')
  },
  methods: {
    checkAnswer() {
      if(!this.answer_attempt || this.nextGameRequestSent) return
      const payload = {
        game_id: this.game.id,
        project_id: this.getProject.id,
        team_id: this.getTeam.id,
        game_current_time: this.getTotalGameTime,
        team_current_time: this.getTotalTeamTime,
        answer_attempt: this.answer_attempt
      }

      this.$api.post(`/game/answer/puzzle_store`, payload)
        .then(({ data }) => {
          if(data.answer.is_correct) {
            this.redirectToNewStepOrGame()
            this.$socket.emit('openToast', { title: 'Parabéns', message: 'Resposta correta!' })
            window.playSound(`correct-answer`)
          } else {
            this.$socket.emit('openToast', { title: 'Ops!', message: `Resposta errada: ${this.answer_attempt}` })
            this.$refs.gamePuzzleAnswerModal.showModal = false
            this.answer_attempt = ''
            this.$socket.emit('refreshLoggedPlayer')
            window.playSound(`wrong-answer`)
          }
        })
        .catch(() => {
          this.$api.post(`/game/room/go_to_current_game`, { team_id: this.getTeam.id })
        })
    },
    redirectToNewStepOrGame() {
      this.$api.post(`/game/room/next_step_or_game`, {
        project_id: this.getProject.id, 
        game_id: this.game.id, 
        team_id: this.getTeam.id,
      })
    }
  }
}
</script>