<template>
  <div class="w-full flex flex-col justify-center items-center mb-4 relative" :id="playerProject.player_id">

    <div 
      v-if="!getRoomPlayer(playerProject.player_id)"
      class="w-full rounded-sm shadow bg-smoke w-10vw max-w-10vw h-15vh max-h-15vh flex justify-center items-center relative">
      <h2>OFF-LINE</h2>
    </div>

    <div v-else class="">
      <div class="absolute right-0 top-0 flex mr-2 mt-0 z-40">
        <span :class="[getRoomPlayer(playerProject.player_id).video ? 'text-green' : 'text-red']" class="p-0.5 flex items-center justify-center">
          <feather :type="getRoomPlayer(playerProject.player_id).video ? 'video' : 'video-off'" size="18"></feather>
        </span>
        <span :class="[getRoomPlayer(playerProject.player_id).audio ? 'text-green' : 'text-red']" class="p-0.5 flex items-center justify-center">
          <feather :type="getRoomPlayer(playerProject.player_id).audio ? 'mic' : 'mic-off'" size="18"></feather>
        </span>
      </div>
      <div 
        v-if="!getRoomPlayer(playerProject.player_id).video"
        class="video-wrapper w-full rounded-sm shadow bg-smoke-white-light w-10vw max-w-10vw h-15vh max-h-15vh flex justify-center items-center relative"
      >
        <feather type="video-off" size="24"></feather>
      </div>
      <div 
        v-else
        class="video-wrapper w-full rounded-sm shadow bg-smoke-white-light w-10vw max-w-10vw h-15vh max-h-15vh flex object-contain box-content"
        :class="{
          'player-video' : playerProject.id == getPlayer.id,
          'pulse-player-is-dominant-speaker' : playerProject.player_id == getRoomDominantSpeaker
        }"
        :id="`video-el-${playerProject.player_id}`"
        >
      </div>
    </div>


    <!-- PLAYER INFO -->
    <div class="w-full relative flex justify-center items-center">
      <feather type="star" size="16" class="text-yellow mr-1" :fill="'#FBBD0E'" v-if="playerProject.is_leader"></feather>
      <h5 class="text-white font-bold max-w-full ellipsis">{{ playerProject.player.name }}</h5>
    </div>
    <h5 class="text-yellow text-sm leading-none">
      {{ getPlayerInfo(playerProject) }}
    </h5>

  </div>
</template>

<script>
export default {
  name: 'GamerCard',
  props: {
    playerProject: {
      type: Object,
      required: true
    }
  },
  computed: {
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getRoomDominantSpeaker() {
      return this.$store.getters.getterRoomDominantSpeaker
    },
    getRoomLeaderId(){
      return this.$store.getters.getterRoomLeaderId
    }
  },
  methods: {
    toggleSource(source) {
      if(this.playerProject.playerId != this.getplayerProject.id) return
      if(source == 'audio') this.$store.dispatch(`toggleAudio`)
      if(source == 'video') this.$store.dispatch(`toggleVideo`)
    },

    getPlayerInfo(playerProject) {
      if(!playerProject.player.country && !playerProject.player.generation) return null
      if(playerProject.player.country && !playerProject.player.generation) return playerProject.player.country
      if(!playerProject.player.country && playerProject.player.generation) return playerProject.player.generation
      return `${ playerProject.player.country } - ${ playerProject.player.generation }`
    },

    getRoomPlayer(playerId) {
      return this.$store.getters.getterRoom.players.findInArray({ playerId })
    }
  }
}
</script>

<style>

  .video-wrapper video {
    object-fit: cover;
    width: 10vw !important;
    height: 15vh !important;
    border-radius: 4px;
  }

  .player-video video {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg);
  }

  .pulse-player-is-dominant-speaker {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
    animation: pulse 1.2s infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

</style>