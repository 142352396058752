<template>
  <div class="w-full h-3/4 flex items-center justify-center">
    
    <img src="/img/logo_ideia.svg" width="244" alt="Logo ideia ativada" />
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>

</style>