<template>
  <BaseGameLayout>
    <div class="w-full h-full px-8 py-6 flex flex-wrap">
      <div class="w-full h-10vh relative flex justify-center items-center">
        <div class="absolute h-full flex left-0" v-if="company">
          <img class="h-10vh" :src="company.logo_url" />
        </div>
        <h1 class="text-center text-white text-3xl font-bold">Ranking times</h1>
        <div class="absolute h-full flex right-0" v-if="company">
          <img src="/img/logo_ideia_white.svg" class="h-10vh" alt="Logo ideia ativada" />
        </div>
      </div>

      <div class="w-full h-65vh flex flex-col bg-yellow-smoke border border-yellow border-only-corner border-only-corner-lg rounded relative">
        <span></span>
        <div class="w-full h-15vh bg-smoke-white border-b border-black flex items-center p-3 text-black" v-if="team">
          <div class="w-60/100 flex flex-col leading-none">
            <h3 class="text-2xl leading-none">{{team.name}}</h3>
            <div class="flex items-center">
              <h3 class="text-3xl mr-2 text-yellow leading-none">{{team.rank}}°</h3>
              <span class="font-light text-sm">Posição do seu time no ranking</span>
            </div>
          </div>
          <div class="w-40/100 flex justify-end">
            <h3 class="text-3xl">{{team.total_time}}</h3>
          </div>
        </div>

        <div class="w-full p-3 flex flex-col overflow-y-auto">
          <div 
            class="w-full p-2 flex border-b border-smoke-light text-white" 
            v-for="rankedTeam in teams" 
            :key="rankedTeam.id"
            :class="[$route.params.teamId !=rankedTeam.id ? 'text-white' : 'text-yellow']"
          >
            <div class="w-20/100">
              <h1 class="text-3xl">{{rankedTeam.rank}}°</h1>
            </div>
            <div class="w-60/100">
              <h1 class="text-2xl">{{rankedTeam.name}}</h1>
            </div>
            <div class="w-20/100 flex justify-end">
              <h1 class="text-2xl">{{rankedTeam.total_time}}</h1>
            </div>
          </div>
        </div>
            
      </div>  
    </div>
  </BaseGameLayout>
</template>

<script>
export default {
  name: 'GameTeamRanking',
  metaInfo () {
    return {
      title: `Ranking ${this.company ? this.company.name : ''} | `
    }
  },
  data() {
    return {
      team: null,
      teams: [],
      company: null
    }
  },
  mounted() {
    this.getProjectTeamsRanked()
    this.getTeamRanked()
    this.getProjectCompany()
  },
  methods: {
    getProjectTeamsRanked() {
      this.$api.get(`/game/team/get_project_teams_ranked/${this.$route.params.teamId}`)
        .then(({ data }) => {
          this.teams = data.teams
        })
    },
    getTeamRanked() {
      this.$api.get(`/game/team/get_team_ranked/${this.$route.params.teamId}`)
        .then(({ data }) => {
          this.team = data.team
        })
    },

    getProjectCompany() {
      const query = `
        {
          team ( findBy: { column: "id", value: "${this.$route.params.teamId}" }) {
            id
            project {
              company {
                id
                name
                logo_url
              }
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.company = data.team.project.company
        })
    }
  }
}
</script>