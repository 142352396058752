const dayjs = require('dayjs')

module.exports.cpfValidator = (value) => {
  if(typeof value != 'string') {
    value = value.toString()
  }
  if (value.length < 14) return true

  let cpf = value.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return true;
  var result = false;
  [9, 10].forEach(function (j) {
    var soma = 0, r;
    cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
      soma += parseInt(e) * ((j + 2) - (i + 1));
    });
    r = soma % 11;
    r = (r < 2) ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = true;
  });
  return result;
}

module.exports.bdayValidator = (value) => {
  if (value.length < 10) return true
  let bday = dayjs(value, 'DD/MM/YYYY');
  if (!bday.isValid()) return true
  if (dayjs().diff(bday, 'years') < 18 || dayjs().diff(bday, 'years') > 100) return true;
}

module.exports.emailValidator = (value) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(String(value).toLowerCase());
}

module.exports.phoneValidator = (value) => {
  if (value.length < 14) return true
}

module.exports.cepValidator = (value) => {
  if (value.length < 9) return true
}

module.exports.minValueValidator = (value, min = 0) => {
  if (value < min) return true
}

module.exports.minLength = (value, min = 6) => {
  if (value.length < min) return true
}

module.exports.inputConfirmation = (first, second) => {
  if (first !== second) return true
}
