<template>
  <BaseModal
    ref="baseModal"
    modalDialogClasses="max-w-sm bg-white text-black"
    @onCloseModal="close()"
  >
    <div class="w-full max-w-full flex flex-wrap items-start text-black">
      <fieldset class="w-full mt-6">
        <legend class="h4 font-semi-bold mb-2">Pergunta quiz</legend>
        <div class="w-full flex flex-wrap">
          <InputWithLabel
            class="mt-4 w-full"
            label="Título pergunta"
            placeholder="Digite aqui"
            v-model="quizQuestion.title"
          ></InputWithLabel>
          <Textarea
            class="mt-4 w-full"
            label="Conteúdo pergunta"
            placeholder="Digite aqui"
            v-model="quizQuestion.content"
          ></Textarea>
        </div>
        <div class="w-full flex items-end flex-wrap mt-6">
            <InputFile
              class="lg:max-w-50/100"
              label="Imagem"
              v-model="quizQuestion.file"
            ></InputFile>
          <button 
            @click="clearFile(quizQuestion, 'file')"
            class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
            Excluir
          </button>
        </div>
      </fieldset>

      <div class="w-full text-base mt-4" v-if="quizQuestion.hasError" v-html="quizQuestion.validationPhraseHtml"></div>

      <div class="w-full flex mt-6">
        <button :disabled="quizQuestion.hasError" @click="store()" class="button button-yellow px-8">
          <span v-if="quiz_question_id">Atualizar</span>
          <span v-else>Criar</span>
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import QuizQuestion from '@/models/QuizQuestion'
export default {
  name: "GameQuizQuestionModal",
  data() {
    return {
      quizQuestion: new QuizQuestion(),
      quiz_question_id: null
    }
  },
  mounted() {
    this.quizQuestion.game_id = this.$route.params.gameId
    this.$eventBus.$on('openQuizQuestionModal', ({ quiz_question_id = null } = {}) => {
      this.$refs.baseModal.showModal = true
      this.quiz_question_id = quiz_question_id
      if(quiz_question_id) this.getQuizQuestion()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openQuizQuestionModal')
  },
  methods: {

    getQuizQuestion() {
      const query =  `
        {
          quiz_question (
            findBy: { column: "id", value: "${ this.quiz_question_id }"}
          ) {
            id
            game_id
            title
            content
            file_url
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.quizQuestion = new QuizQuestion(data.quiz_question)
        })
    },

    store() {
      const formData = this.quizQuestion.getFormData
      
      const url = this.quiz_question_id ? `/admin/quiz_questions/update/${this.quizQuestion.id}` : `/admin/quiz_questions/store`
      this.$api.post(url, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success('Pergunta adicionada com sucesso')
          this.$refs.baseModal.showModal = false
          this.$emit('updated')
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar pergunta')
        })
    },

    close() {
      this.quiz_question_id = null
      this.quizQuestion = new QuizQuestion()
    }
  }
}
</script>