<template>
  <div class="w-full" v-if="game">
    <component 
      :is="game.component" 
      :game="game"
      @getGame="getGame"
    ></component>

    <GameChatModal></GameChatModal>
    <GameIsFinishedModal></GameIsFinishedModal>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import Game from '@/models/Game'
import GameChatModal from '@/modules/game/chat/GameChatModal'
import GameIsFinishedModal from '@/modules/game/play/GameIsFinishedModal'
export default {
  name: 'GamePlay',
  components: { GameChatModal, GameIsFinishedModal },
  metaInfo () {
    return {
      title: `${this.game.name} | `
    }
  },
  data() {
    return {
      game: new Game(),
      remainTimestamp: null
    }
  },
  computed: {
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getRoom() {
      return this.$store.getters.getterRoom
    },
    getRemainTime() {
      return dayjs.utc(this.remainTimestamp).format('HH:mm:ss')
    },
    getMaxPlayersInTeam() {
      return this.offlinePlayers.length + this.getRoom.players.length
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
    getGameIsFinished() {
      return this.$store.getters.getterGameIsFinished
    }
  },
  mounted() {
    this.getGame()
    this.$store.commit('setTipsUnlocked', 0)
  },
  methods: {

    updateRoomParticipants() {
      window.room.participants.forEach((participant) => {
        const playerCard = document.getElementById(participant.identity)
        const participantTrack = Array.from(participant.tracks.values())[0].track
        playerCard.innerHTML = ''
        setTimeout(() => {
          playerCard.appendChild(participantTrack.attach())
        }, 500)
      })
    },

    initTimer(){
      this.timeInterval = setInterval(() => {
        this.remainTimestamp = dayjs(this.getProject.event_start_at).diff(dayjs())
        this.checkGameIsFinished()
      }, 1000)

    },

    getGame() {
      const query = `
        {
          game (
            findBy: { column: "slug", value: "${this.$route.params.gameSlug}" }
          ) {
            id
            name
            component
            difficulty
            resolution_time
            resolution_block_after
            background_file_url
            introduction_file_url
            introduction_url
            penalty_time
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.game = data.game
          this.$store.commit('setGame', data.game)
          this.checkGameIsFinished()
        })
    },

    checkGameIsFinished() {
      if(this.getGameIsFinished) {
        this.$eventBus.$emit('openGameIsFinishedModal')
      }
    }
  }
}
</script>

<style>

</style>