<template>
  <div class="modal-wrapper">
    <div class="modal-dialog max-w-md">
      <button 
        @click="$emit('input', null)"
        class="modal-exit-button">
        <feather type="x" size="20"></feather>
      </button>
      <h4 class="w-full text-center font-bold">Detalhes usuário</h4>
      <div class="w-full flex flex-wrap" v-if="user">
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Nome</label>
          <p class="text-lg font-bold">{{user.name}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>Email</label>
          <p class="text-lg font-bold">{{user.email}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Time</label>
          <p class="text-lg font-bold">{{user.team.name}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>É líder</label>
          <BooleanLabel class="font-bold" :boolean="user.is_leader"></BooleanLabel>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Telefone</label>
          <p class="text-lg font-bold">{{user.mobile}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>CEP</label>
          <p class="text-lg font-bold">{{user.cep}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Endereço</label>
          <p class="text-lg font-bold">{{user.address}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>País</label>
          <p class="text-lg font-bold">{{user.country}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Educação</label>
          <p class="text-lg font-bold">{{user.education}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>Ocupação</label>
          <p class="text-lg font-bold">{{user.occupation}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Cargo</label>
          <p class="text-lg font-bold">{{user.role}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>Tempo de serviço</label>
          <p class="text-lg font-bold">{{user.time_in_service}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Gênero</label>
          <p class="text-lg font-bold">{{user.gender}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>Orientação sexual</label>
          <p class="text-lg font-bold">{{user.sex_orientation}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col">
          <label>Cor</label>
          <p class="text-lg font-bold">{{user.race_color}}</p>
        </div>
        <div class="my-2 w-full md:w-50/100 flex-col md:text-right">
          <label>PCD</label>
          <p class="text-lg font-bold">{{user.pcd}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectUserModal',
  props: ['value'],
  computed: {
    user() {
      return this.value
    }
  } 
}
</script>

<style>

</style>