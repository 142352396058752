export default {
  getterPlayerIsManager(state) {
    return state.player_is_manager_input
  },
  getterReportApiRoute: (state, getters, rootState, rootGetters) => (report_type) => {
    const project_slug = rootGetters.getterProject.slug
    const team_id = rootGetters.getterTeam.id
    const is_manager = getters.getterPlayerIsManager
    return `/advanced_report/${report_type}/${project_slug}/${team_id}/${is_manager}`
  }
}
