export const buttonAccessOptions = [
  { value: 'all', label: 'Todos' },
  { value: 'leader', label: 'Líder' },
  { value: 'players', label: 'Apenas jogadores' }
]

export const gameDifficultiesOptions = [
  { value: 'easy', label: 'Fácil' },
  { value: 'normal', label: 'Normal' },
  { value: 'hard', label: 'Difícil' }
]

export const gameComponentOptions = [
  { value: 'GameQuiz', label: 'Quiz' },
  { value: 'GamePuzzle', label: 'Enigma' },
  { value: 'GameGenius', label: 'Genius' },
]

export const companySizeOptions = [
  { value: 'small', label: 'Pequena' },
  { value: 'medium', label: 'Média' },
  { value: 'big', label: 'Grande' },
]

export const radioBooleanOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

export const playerSchoolarship = [
  { value: 'Ensino médio', label: 'Ensino médio', color: '#8705AD' },
  { value: 'Licenciatura', label: 'Licenciatura', color: '#4AD6A0' },
  { value: 'Bacharelado', label: 'Bacharelado', color: '#93B7BE ' },
  { value: 'MBA', label: 'MBA', color: '#D65108' },
  { value: 'Mestrado', label: 'Mestrado', color: '#AD831F' },
  { value: 'Doutorado', label: 'Doutorado', color: '#FBB413' }
]

// Player language level based on
// https://www.sprachcaffe.com/portugues/niveis-de-idiomas.htm
export const playerForeignLanguage = [
  { value: 'Não possui', label: 'Não possui', color: '#8705AD' },
  { value: 'Básico', label: 'Básico', color: '#4AD6A0' },
  { value: 'Avançado', label: 'Avançado', color: '#AD831F' },
  { value: 'Fluente', label: 'Fluente', color: '#FBB413' }
]

export const playerLanguages = [
  { language: 'Inglês Nível Básico' },
  { language: 'Inglês Nível Intermediário' },
  { language: 'Inglês Nível Avançado' },
  { language: 'Espanhol Nível Básico' },
  { language: 'Espanhol Nível Intermediário' },
  { language: 'Espanhol Nível Avançado' },
  { language: 'Alemão Nível Básico' },
  { language: 'Alemão Nível Intermediário' },
  { language: 'Alemão Nível Avançado' },
  { language: 'Francês Nível Básico' },
  { language: 'Francês Nível Intermediário' },
  { language: 'Francês Nível Avançado' },
  { language: 'Japonês Nível Básico' },
  { language: 'Japonês Nível Intermediário' },
  { language: 'Japonês Nível Avançado' }
]

export const playerGender = [
  'Feminino',
  'Masculino',
  'Outro',
]

export const playerGenderIdentity = [
  'Mulher cisgênera',
  'Homem cisgênero',
  'Mulher transexual/transgênera',
  'Homem transexual/transgênero',
  'Não binário',
  'Outro',
  'Prefiro não me classificar'
]

export const playerSexOrientation = [
  'Heterossexual',
  'Homossexual',
  'Bissexual',
  'Pansexual',
  'Assexual',
  'Outro',
  'Prefiro não me classificar',
]

export const playerRaceColors = [
  'Amarela',
  'Branca',
  'Indígena',
  'Parda',
  'Preta',
  'Outra',
]

export const playerPcdOptions = [
  { pcd: 'Não' },
  { pcd: 'Baixa visão ou visão subnormal' },
  { pcd: 'Surdez' },
  { pcd: 'Física' },
  { pcd: 'Intelectual' },
  { pcd: 'Outra' },
]

export const playerTypeOfTransportToWork = [
  'Transporte público',
  'Aplicativos',
  'Particular',
  'Deslocamento ativo (Bike ou a pé)',
]

export const playerDistanceFromWork = [
  `até 5 km`,
  `10 km`,
  `20 km`,
  `30 km`,
  `40 km`,
  `mais que 50 km`
]

export const playerTimeFromWork = [
  `até 10 minutos`,
  `20 minutos`,
  `30 minutos`,
  `40 minutos`,
  `50 minutos`,
  `mais que 60 minutos`
]

export const chartAvailableColors = [
  '#D74167',
  '#AD93EB',
  '#fd8369',
  '#24aaf2',
  '#FBBD0E',
  '#16B96E',
  '#E7B000',
  '#737273',
  '#00649a',
  '#2E2E2E',
  
  //Repeat
  '#D74167',
  '#AD93EB',
  '#fd8369',
  '#24aaf2',
  '#FBBD0E',
  '#16B96E',
  '#E7B000',
  '#737273',
  '#00649a',
  '#2E2E2E',

  //Repeat
  '#D74167',
  '#AD93EB',
  '#fd8369',
  '#24aaf2',
  '#FBBD0E',
  '#16B96E',
  '#E7B000',
  '#737273',
  '#00649a',
  '#2E2E2E',
]
