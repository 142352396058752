<template>
  <div class="w-full flex flex-col" v-if="currentQuizQuestion">

    <div class="w-full h-60vh">
      <GameQuizLeaderBoard 
        v-if="getPlayerProject.is_leader"
        :project="getProject"
        :game="game"
        :currentQuizQuestion="currentQuizQuestion"
        :optionSelectedIndex="optionSelectedIndex"
        :quizQuestions="quizQuestions"
      ></GameQuizLeaderBoard>
      <GameQuizTeamBoard 
        :currentQuizQuestion="currentQuizQuestion"
        :optionSelectedIndex="optionSelectedIndex"
        v-else
      ></GameQuizTeamBoard>
    </div>

    <GameQuizActionMenu 
      :game="game" 
      @getQuizQuestions="getQuizQuestions"
      :currentQuizQuestion="currentQuizQuestion"
    ></GameQuizActionMenu>

    <GameIntroductionModal :game="game"></GameIntroductionModal>

  </div>
</template>

<script>
import { shufleArray } from '@/util/Functions'
import GameQuizTeamBoard from './GameQuizTeamBoard'
import GameQuizLeaderBoard from './GameQuizLeaderBoard'
import GameQuizActionMenu from './GameQuizActionMenu'
export default {
  name: 'GameQuiz',
  components: { GameQuizTeamBoard, GameQuizLeaderBoard, GameQuizActionMenu },
  props: ['game'],
  data() {
    return {
      optionSelectedIndex: false,
      quizQuestions: []
    }
  },
  computed: {
    randomOptions() {
      return shufleArray(this.game.options.map((item) => item))
    },
    currentQuizQuestion() {
      return this.quizQuestions[this.getCurrentStep] ? this.quizQuestions[this.getCurrentStep] : null
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getCurrentStep() {
      return this.$route.params.step || 0
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
  },

  mounted() {
    this.getQuizQuestions()
    window.$socket.on('gameSelectOptionIndex', ({ index }) => {
      this.optionSelectedIndex = index
    })
  },
  methods: {
  
    getQuizQuestions() {
      const query = `
        {
          game (
            findBy: { column: "slug", value: "${this.$route.params.gameSlug}" }
          ) {
            id
            name
            quiz_questions {
              id
              order
              title
              content
              file_url
              options {
                id
                order
                title
                content
                is_correct
              }
              tips {
                id
                title
                content
                file_url
                team_tips (
                  where: [
                    { column: "team_id", operation: "=", value: "${this.getTeam.id}" }
                  ]
                ) {
                  id
                }
              }
            }
          }
        }
      `

      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.quizQuestions = data.game.quiz_questions
        })
    }
  }
}
</script>

<style scoped>

.options-label {
  hyphens: auto;
}

</style>