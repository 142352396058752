<template>

  <transition
      enter-active-class="transition ease-in-out duration-300 transform"
      enter-class="opacity-0 -translate-y-48"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-300 transform"
      leave-class="opacity-100"
      leave-to-class="opacity-0 translate-y-48"
    >
    <div v-if="show" class="origin-top fixed top-0 right-0 mt-5 mr-15 z-20 p-1 w-25vw">

      <div 
        class="rounded border bg-black border-yellow object-cover card-image-cover"
        :class="{ 'shake' : shouldUpdate }"
      >
        <div class="w-full rounded-t px-3 py-2 border-b border-grey relative flex items-center">
          <h3 class="w-full text-center whitespace-no-wrap ellipsis font-bold text-yellow">{{toast.title}}</h3>
          <button @click="closeToast()" class="z-10 absolute right-0 mr-1 w-6 h-6 flex items-center justify-center rounded-full bg-black focus:outline-none p-1">
            <feather type="x" class="text-yellow" size="18"></feather>
          </button>
        </div>
        <div class="w-full rounded-b p-3">
          <h4 class="text-center font-semi-bold text-yellow">{{toast.message}}</h4>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      show: false,
      toast: {
        type: 'default',
        title: '',
        message: ''
      },
      timeout: null,
      shouldUpdate: false
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getterLoggedUser
    },
    getBackground() {
      let status
      if(!this.getUser) status = 'max'
      if(this.getUser && this.getUser.gamesFinished <= 10) status = 'max'
      if(this.getUser && this.getUser.gamesFinished > 10 && this.getUser.gamesFinished < 30) status = 'medium'
      return `url(/img/bg-${status}-virus.png)`
    }
  },
  mounted() {
    this.$eventBus.$on('openGameToast', this.openGameToast)
  },
  beforeDestroy() {
    this.$eventBus.$off('openGameToast')
  },
  methods: {
    openGameToast({ type = 'default', title = '', message= '', duration = 3000, keep = false}) {
      if(this.show) this.shake()
      this.toast.type = type
      this.toast.title = title
      this.toast.message = message
      this.show = true
      if(!keep) {
        if(this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.closeToast()
        }, duration)
      }
    },
    closeToast() {
      this.type = ''
      this.title = ''
      this.message = ''
      this.show = false
      if(this.timeout) clearTimeout(this.timeout)
    },

    shake() {
      this.shouldUpdate = true
      setTimeout(() => {
        this.shouldUpdate = false
      }, 300)
    }
  }

}
</script>

<style>

  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(3px, 2px, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, -2px, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 2px, 0);
    }
  }
</style>