<template>
  <fieldset class="w-full">
    <legend class="h4 font-semi-bold mb-2">Opções de resposta do enigma</legend>
    <div class="w-full flex">
      <button
        @click="$eventBus.$emit('openPuzzleAnswerOptionModal')"
        class="button button-border-yellow bg-white">
        <feather type="plus"></feather>
        <span class="ml-2">Adicionar opção de resposta</span>
      </button>
    </div>

    <div class="w-full mt-8" v-if="game.puzzle_answer_options.length">
      <div class="w-full">
        <div class="w-full my-2 hidden lg:flex">
          <p class="lg:w-80/100 flex items-center pl-4">Resposta</p>
          <p class="lg:w-20/100 flex items-center pl-4">Ações</p>
        </div>

        <div class="w-full overflow-x-auto max-w-full flex flex-col">
          <div 
            class="w-full bg-white rounded flex flex-wrap shadow-sm my-2"
            v-for="(item, index) in game.puzzle_answer_options"
            :key="`game-puzzle-item-${index}`"
          >
            <div class="block w-full lg:w-80/100 p-4 lg:border-r border-smoke-light">
              <h5 class="font-bold">{{item.content}}</h5>
            </div>
            <div class="block w-full lg:w-20/100 p-4 flex items-center justify-center">
              <button 
                @click="$refs.gamePuzzleAnswerOptionConfirmDeleteModal.showModal = true; itemSelected = item"
                class="button button-border-red focus:outline-none ml-3">
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseModal
      ref="gamePuzzleAnswerOptionConfirmDeleteModal"
      modalDialogClasses="max-w-sm bg-white text-black"
      @onCloseModal="quizQuestionSelected = null"
      title="Atenção"
    >
      <div class="w-full flex flex-col items-center justify-center text-black">
        <h4 class="text-center mt-4">Tem certeza que deseja excluir?</h4>

        <div class="w-full flex justify-center mt-4">
          <button @click="itemSelected = null" class="button button-border-yellow">
            Cancelar
          </button>
          <button @click="deleteItem()" class="button button-border-red ml-4">
            Sim
          </button>
        </div>
      </div>
    </BaseModal>

    <GamePuzzleAnswerOptionModal @update="$emit('getGame')"></GamePuzzleAnswerOptionModal>

  </fieldset>
</template>

<script>
import GamePuzzleAnswerOptionModal from './GamePuzzleAnswerOptionModal'
export default {
  name: 'GamePuzzleAnswerOption',
  components: { GamePuzzleAnswerOptionModal },
  props: ['game'],
  data() {
    return {
      itemSelected: null
    }
  },
  methods: {
    deleteItem() {
      this.$api.delete(`/admin/puzzle_answer_options/destroy/${this.itemSelected.id}`)
        .then(() => {
          this.itemSelected = null
          this.$emit('getGame')
        })
    }
  }
}
</script>