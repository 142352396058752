<template>
  <ReportWithHeader>
    <div class="w-full h-full flex items-center justify-center">

      <div class="w-50/100 grid grid-cols-3 gap-20">
        <div 
          class="assertiveness-card col-span-1 rounded-md bg-white shadow p-2 flex flex-col items-center justify-center hover:z-50 hover:shadow-md transition-all duration-300" 
          v-for="(player_assetiveness, index) in players_assertiveness" 
          :key="index"
          @mouseover="cardInHover = index"
          @mouseleave="cardInHover = -1"
          :style="{ transform: `scale(${ getScale(player_assetiveness.percentage, index) })`, zIndex: (player_assetiveness.percentage / 100) }"
        >
          <div class="assertiveness-circle w-6vh h-6vh rounded-full relative flex items-center justify-center border-2 border-orange">
            <h3 class="text-base">{{ player_assetiveness.percentage}}%</h3>
          </div>
          <h3 class="w-full text-center ellipsis text-sm mt-1">{{ player_assetiveness.name}}</h3>
        </div>
      </div>
    </div>
  </ReportWithHeader>
</template>

<script>
export default {
  name: 'Report06',
  data() {
    return {
      players_assertiveness: [],
      cardInHover: -1
    }
  },
  mounted() {
    this.getPlayerAssertiveness()
  },
  methods: {
    getPlayerAssertiveness() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('assertiveness_when_is_leader')}`)
        .then(({ data }) => {
          this.players_assertiveness = data.players_assertiveness
        })
    },
    getScale(percentage, index) {
      const factor = (this.cardInHover == index) ? 1.3 : 0.9
      return (percentage / 100 + factor)
    }
  }
}
</script>

<style>
  .item-color {
    margin-top: 4px;
    width: 12px;
    height: 12px;
    display: block;
    flex: none;
    border-radius: 2px;
  }

  .align-card-center {
    justify-content: center;
    align-content: center;
  }

  .assertiveness-card:hover .assertiveness-circle {
    background-color: #FBBD0E;
    color: #FFF;
  }

</style>