<template>
  <ReportWithHeader bodyClasses="w-full h-full flex flex-col">
    
    <div class="w-full h-full flex flex-col">
      <div class="w-full h-40vh min-h-40vh bg-yellow-smoke relative" id="street_frame">
        <vue-inline-svg 
          src="/reports/09/street.svg" 
          width="100%"
          height="100%"
          @loaded="getPlayersDistanceFromWork()"
        />
        

        <div class="absolute z-25 flex" 
          v-for="(item, index) in players_time_from_work" 
          :key="index"
          :style="{ top: `${item.top}px`, left: `${item.left}px` }"
        >
          <img class="flex-none mr-1" src="/reports/09/pin.svg" width="48" />
          <div class="flex flex-col">
            <h3 class="font-bold">{{ item.time_from_work }}</h3>
            <h4 v-if="item.percentage">{{ item.percentage }}% ({{ item.count }})</h4>
            <h4 v-else>{{ item.title }}</h4>
          </div>
        </div>
      </div>

      <div class="w-full h-full flex flex-col items-center justify-center p-4 space-y-4">
        <p class="text-center">
          Um detalhe bastante relevante é que o regime de home office costuma propiciar tanto um aumento de produtividade como queda de gastos por funcionário para a companhia. É o que mostram diversos estudos.
        </p>
        <p class="text-center">
          Um deles, realizado na Univerdade de Stanford, ganhou destaque no portal da revista Exame. Essa pesquisa apontou um crescimento de 13% de produtivdade, diminuição de acidentes de trajeto, além de diminuição de doenças entre os profissionais e redução de investimentos necessários para a manutenção do colaborador.
        </p>
        <p class="text-center">
          Ou seja, é uma estratégia que até mesmo contribui para que o orçamento do escritório se mantenha do modo ideal.
        </p>
        <p class="font-bold text-center">
          O aumento da concentração figura também como um dos benefícios do trabalho remoto.
        </p>
      </div>

    </div>
  </ReportWithHeader>
</template>

<script>
// import { playerTimeFromWork } from '@/models/Enums'
export default {
  name: 'Report09',
  data() {
    return {
      players_time_from_work: []
    }
  },
  computed: {
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getTeam() {
      return this.$store.getters.getterTeam
    }
  },
  methods: {

    getPlayersDistanceFromWork() {

      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_time_from_work')}`)
        .then(({ data }) => {
          
          for(const [index, item] of data.players_time_from_work.entries()) {
            const frame = document.getElementById(`street_frame`)
            const pin = document.getElementById(`street_pin_${index}`)
            item.left = pin.getBoundingClientRect().left - frame.getBoundingClientRect().left
            item.top = pin.getBoundingClientRect().top - (frame.getBoundingClientRect().top + 60)
          }

          this.players_time_from_work = data.players_time_from_work
        })
    }
  }

}
</script>

<style>

</style>