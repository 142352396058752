<template>
  <ReportWithHeader>
    <div class="w-full h-full flex flex-col space-y-8">
      
      <div class="w-full h-full flex items-center rounded border border-smoke-light" v-for="(per_age, index) in work_transit_per_age" :key="index">

        <div class="w-20/100 rounded-l bg-smoke-extra-light p-3 flex flex-col items-center justify-center h-full text-base">
          <span class="font-bold">Faixa etária</span>
          <span>{{ per_age.title }}</span>
        </div>
        <div class="w-full p-3 flex flex-col items-start space-y-2 rounded-r">
          <div class="w-full text-base" v-if="!per_age.items.length">
            --
          </div>
          <div class="w-full flex" v-for="(item, index_item) in per_age.items" :key="item.type_of_transport_to_work">
            <div class="w-30/100 text-base font-bold flex items-center space-x-2">
              <img :src="getTypeOfTransportIcon(item.type_of_transport_to_work, index_item)" width="32" />
              
              <span>{{ item.type_of_transport_to_work }}</span>
            </div>
            <div class="w-70/100">
              <div class="w-full flex">
                <div class="block h-4vh relative" :style="{ backgroundColor: `${purpleColors[index_item]}`, width: `${item.percentage}%` }">
                  <div class="top-0 right-0 h-full absolute flex items-center whitespace-no-wrap justify-end text-white font-bold">
                    <span>{{ item.percentage}} %</span>
                  </div>
                </div>
                <div class="type-of-transport-bar-triangle" :style="{ borderColor: `transparent transparent transparent ${purpleColors[index_item]}`}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ReportWithHeader>
</template>

<script>

import { round } from '@/util/Functions'
import { playerTypeOfTransportToWork } from '@/models/Enums'

const purpleColors = [
  // '#D5B9DE',
  '#E19CF5',
  '#C756E8',
  '#8705AD',
  '#4C0063'
]

export default {
  name: 'Report08',
  data() {
    return {
      work_transit_per_age: [],
      playerTypeOfTransportToWork,
      purpleColors
    }
  },
  mounted() {
    this.getPlayersDistanceFromWork()
  },
  methods: {
    getPlayersDistanceFromWork() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_work_transit_per_age')}`)
        .then(({ data }) => {
          this.work_transit_per_age = data.work_transit_per_age
        })
    },

    getBarColor(index, length) {
      const newIndex =  round(99 / length * (index + 1), 0)
      console.log(newIndex)
      return `#4C0063${newIndex == 99 ? '' : newIndex}`
    },

    getTypeOfTransportIcon(type_of_transport_to_work, index) {
      if(type_of_transport_to_work == 'Transporte público') return `/reports/08/public_${index}.svg`
      if(type_of_transport_to_work == 'Aplicativos') return `/reports/08/app_${index}.svg`
      if(type_of_transport_to_work == 'Particular') return `/reports/08/car_${index}.svg`
      return `/reports/08/ativo_${index}.svg`
    }
  }
}
</script>

<style>
  .type-of-transport-bar-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2vh 0 2vh 1vh;
  }
</style>