<template>
  <BaseModal
    ref="teamNameEditModal" 
    modalDialogClasses="max-w-md bg-black"
  >
    <div class="w-full flex flex-col">
      <h2 class="w-full text-center font-bold text-xl">Insira o nome do time</h2>

      <div class="w-full">
        <input
          aria-required="true"
          class="w-full text-center appearance-none outline-none text-yellow text-3xl p-3 focus:border-yellow rounded mt-6"
          v-model="teamName"
          placeholder="Nome do time"
          type="text"
          @keydown.enter="checkAnswer()"
        />
      </div>
      
      <div class="w-full flex justify-center mt-3">
        <GameButton 
          :disabled="teamName == ''"
          @click="updateTeamName()" 
          label="Salvar"
          class="w-12vw text-2xl mx-2"
        ></GameButton>
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'GameTeamNameEditModal',
  props: ['game'],
  data() {
    return {
      teamName: '',
    }
  },
  computed: {
    getTeam() {
      return this.$store.getters.getterTeam
    },
  },
  mounted() {
    this.$eventBus.$on('openTeamNameEditModal', () => {
      this.$refs.teamNameEditModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openTeamNameEditModal')
  },
  methods: {
    updateTeamName() {
      this.$api.post(`/game/team/update/${this.getTeam.id}`, { name: this.teamName })
        .then(({ data }) => {
          this.$gameToast({ title: 'Parabéns', message: data.message })
          this.$store.dispatch('refreshLoggedPlayer')
          this.$socket.emit('refreshLoggedPlayer')
          this.$refs.teamNameEditModal.showModal = false
        })
    }
  }
}
</script>