<template>
  <div v-if="modalAvoidExitShow" class="modal-wrapper">
    <div class="modal-dialog">
      <button 
        @click="modalAvoidExitShow = false"
        class="modal-exit-button">
        <feather type="x" size="20"></feather>
      </button>
      <h4 class="w-full text-center font-bold">{{ title }}</h4>
      <div class="w-full flex flex-col items-center justify-center">
        <h4 class="text-center mt-4">{{ message }}</h4>

        <div class="w-full flex justify-center mt-10">
          <button @click="modalAvoidExitShow = false" class="button button-border-yellow">
            Cancelar
          </button>
          <router-link to="/" class="button button-border-red ml-4">
            Sim
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvoidExitModal',
  props: {
    title: {
      type: String,
      default: 'Atenção!'
    },
    message: {
      type: String,
      default: 'Você tem certeza que deseja sair sem salvar as alterações?'
    }
  },
  data() {
    return {
      modalAvoidExitShow: false,
      routerShouldBeIntercepted: true,
      routeIntercepted: null,
      originalPush: null
    }
  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {

    handleBeforeUnload(event) {
      event.preventDefault()
      event.returnValue = ''
    },
    handleChange() {

      // const that = this
      // this.originalPush = this.$router.push;

      // this.$router.push = function(location) {

      //   if(!that.routerShouldBeIntercepted || location.forceRedirect) {
      //     return that.originalPush.call(this, location)
      //   }
      //   if(!that.modalAvoidExitShow) {
      //     window.unsaved = false
      //     that.routeIntercepted = location
      //     that.modalAvoidExitShow = true
      //     return
      //   }
      //   that.$router.push = that.originalPush
      //   that.$eventBus.$emit('exittedFromModal')
      //   return that.originalPush.call(this, that.routeIntercepted)

      // }
    }
  }
}
</script>