<template>
  <div class="w-full max-w-full flex flex-wrap items-start">
    <fieldset class="w-full mt-6">
      <legend class="h4 font-semi-bold mb-2">Botão Enigma</legend>
      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-4 w-full"
          label="Título botão"
          placeholder="Digite aqui"
          v-model="puzzleButton.title"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-4 w-full"
          label="Label botão"
          placeholder="Digite aqui"
          v-model="puzzleButton.label"
        ></InputWithLabel>
        <Textarea
          class="mt-4 w-full"
          label="Conteúdo botão"
          placeholder="Digite aqui"
          v-model="puzzleButton.content"
        ></Textarea>
      </div>

      <label class="mt-4 inline-block">Posicionamento do botão</label>
      <div class="w-full h-40vh w-70vh relative bg-smoke-light rounded-sm mt-1" ref="gamePreview">
        <img class="object-cover h-full w-full rounded-sm" :src="game.background_file_url" />
        <div
          draggable="true"
          @dragend="dragend($event)"
          :style="{
            left: puzzleButton.left_position + '%',
            top: puzzleButton.top_position + '%',
          }"
          class="absolute h-7vh w-7vh border-3 border-white rounded-full bg-smoke-light flex items-center justify-center cursor-pointer"
        >
        </div>
      </div>

      <div class="w-full mt-4">
        <label>Acesso</label>
        <v-select 
          class="vue-select"
          :class="puzzleButton.access ? 'filled' : ''"
          label="label"
          :options="buttonAccessOptions"
          :reduce="item => item.value"
          v-model="puzzleButton.access"
        ></v-select>
      </div>
      <div class="w-full flex items-end flex-wrap mt-6">
          <InputFile
            class="lg:max-w-50/100"
            label="Arquivo"
            v-model="puzzleButton.file"
          ></InputFile>
        <button 
          @click="clearFile(puzzleButton, 'file')"
          class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
          Excluir
        </button>
      </div>
      <div class="w-full flex items-end flex-wrap mt-6">
          <InputFile
            class="lg:max-w-50/100"
            label="Ícone"
            v-model="puzzleButton.icon"
          ></InputFile>
        <button 
          @click="clearFile(puzzleButton, 'icon')"
          class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
          Excluir
        </button>
      </div>
    </fieldset>

    <div class="w-full text-base mt-4" v-if="puzzleButton.hasError" v-html="puzzleButton.validationPhraseHtml"></div>

    <div class="w-full flex mt-6">
      <button @click="storeOrUpdate()" :disabled="puzzleButton.hasError" class="button button-yellow px-8">
        Salvar
      </button>
    </div>

    <AvoidExitModal></AvoidExitModal>

  </div>
</template>

<script>
import PuzzleButton from '@/models/PuzzleButton'
import Game from '@/models/Game'
import {buttonAccessOptions} from '@/models/Enums'
import { round } from '@/util/Functions'
export default {
  name: 'GamePuzzleButtonForm',
  data() {
    return {
      puzzleButton: new PuzzleButton(),
      game: new Game(),
      buttonAccessOptions
    }
  },
  mounted() {
    this.puzzleButton.game_id = this.$route.params.gameId
    if(this.$route.params.puzzleButtonId) {
      this.getPuzzleButton()
    }
    this.getGame()
  },
  methods: {

    getPuzzleButton() {
      const query = `
        {
          puzzle_button (
            findBy: { column: "id", value: "${this.$route.params.puzzleButtonId}" }
          ) {
            id
            game_id
            left_position
            top_position
            access
            title
            label
            content
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only'})
        .then(({ data }) => {
          this.puzzleButton = new PuzzleButton(data.puzzle_button)
        })
    },

    getGame() {
      const query =  `
        {
          game (
            findBy: { column: "id", value: "${this.$route.params.gameId}"}
          ) {
            id
            name
            component
            introduction_url
            background_file_url
            difficulty
            resolution_time  
            resolution_block_after
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.game = new Game(data.game)
        })
    },

    storeOrUpdate() {
      this.$eventBus.$emit('setRouteShouldBeIntercepted', false)
      
      const url = this.$route.params.puzzleButtonId ? `/admin/puzzle_buttons/update/${this.$route.params.puzzleButtonId}` : `/admin/puzzle_buttons/store`
      const formData = this.puzzleButton.getFormData
      
      this.$api.post(url, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$router.push({ name: 'GameShow', params: { gameId: this.$route.params.gameId } })
          this.$toast.success('Botão salvo com sucesso')
          this.$eventBus.$emit('setRouteShouldBeIntercepted', true)
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar botão')
          this.$eventBus.$emit('setRouteShouldBeIntercepted', true)
        })
    },

    dragend($event) {
      const gamePreview = this.$refs.gamePreview
      const bounds = gamePreview.getBoundingClientRect()
      const x = $event.pageX - bounds.left
      const y = $event.pageY - bounds.top
      const positionLeft = round(((100 / bounds.width) * x), 0)
      const positionTop = round(((100 / bounds.height) * y), 0)
      if(positionLeft < 0 || positionLeft > 90) return
      if(positionTop < 0 || positionTop > 85) return
      this.puzzleButton.left_position = positionLeft
      this.puzzleButton.top_position = positionTop
    },
  }
}
</script>