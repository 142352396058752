<template>
  <BaseModal
    ref="baseModal"
    modalDialogClasses="bg-black max-w-md min-h-50vh"
  >
    <div class="w-full flex content-center">
      <img class="w-full h-full object-contain bg-center rounded" v-if="!getVideo" :src="game.introduction_file_url" />
      <iframe :src="getVideo"  width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
    </div>
  </BaseModal>

</template>

<script>
import getVideoId from '@/util/getVideoId'
export default {
  name: 'GameIntroductionModal',
  props: ['game'],
  computed: {
    getVideo() {
      if(!this.game.introduction_url) return false
      const { id, type } = getVideoId(this.game.introduction_url)
      if(type == 'youtube') return `https://youtube.com/embed/${id}`
      return `https://player.vimeo.com/video/${id}`
    }
  },
  mounted() {
    setTimeout(() => {
      if(process.env.NODE_ENV == 'development') return
      if(this.game.introduction_file_url || this.game.introduction_url) this.$refs.baseModal.showModal = true
    }, 500)
    this.$eventBus.$on('openGameIntroductionModal', () => {
      this.$refs.baseModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openGameIntroductionModal')
  }
}
</script>

<style>

</style>