<template>
  <div class="w-full p-2 px-3 bg-grey-light shadow-xs border-b border-smoke-light h-20 flex items-center">
    <div class="w-full flex items-center" :class="$route.meta.breadcrumb ? 'justify-between' : 'justify-between sm:justify-end'">
      <button
        @click="$eventBus.$emit('openSideMenu', { open: true})"
        aria-label="open side menu"
        class="cursor-pointer focus:outline-none flex text-black sm:hidden mr-2"
      >
        <feather type="menu"></feather>
      </button>
      <p class="text-left w-full text-xs ellipsis breadcrumb" v-if="$route.meta.breadcrumb">
        {{$route.meta.breadcrumb}}
      </p>
      <router-link :to="$store.getters.getterLoggedUser ? '/dashboard' : '/'" class="flex-shrink-0">
        <img src="/img/logo_ideia.svg" width="97px" alt="Ideia ativada logo" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminHeader',
  mounted() {
    this.$store.dispatch('getLoggedUser')
  }
}
</script>

<style>

</style>