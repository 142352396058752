<template>
  <BaseModal
    ref="gameChatModal"
    title="Chat"
    modalDialogClasses="max-w-xl bg-black"
  >
    <div class="w-full h-60vh overflow-y-auto hide-scrollbar flex flex-wrap py-2" id="chat-messages-container">
      <div class="w-full flex flex-wrap trasition duration-300" :class="[isFinishedLoad ? 'opacity-100' : 'opacity-0']">
        <div 
          v-for="(message, index) in team_messages"
          :key="index"
          class="w-full flex flex-col mb-3 flex-shrink-0"
          :class="[getMessageClasses(message)]"
        >
          <div class="rounded-sm border border-grey bg-yellow-smoke p-2" >
            {{message.content}}
          </div>
          <h4 class="mt-1" v-if="message.from_type == 'player'">{{message.playername}}</h4>
          <h4 class="mt-1" v-else>{{message.username}} <span class="ml-1 text-yellow">(ADMIN)</span></h4>
          <p class="text-grey text-xs italic">{{message.created_at}}</p>
        </div>
      </div>
    </div>

    <div class="w-full h-10vh flex">
      <div class="w-full relative">
        <input
          type="text"
          id="message-input"
          placeholder="Insira sua mensagem"
          v-model="content"
          @keydown.enter="sendMessage()"
          autocomplete="off"
          autocapitalize="sentences"
          ref="input"
          class="block appearance-none outline-none w-full h-full border-2 rounded-sm border-grey focus:border-green-dark bg-black text-grey-darker text-lg p-3"
        />
        <button 
          @click="sendMessage()" 
          :class="[content != '' ? 'text-yellow' : 'text-grey']"
          :disabled="isSending"
          class="h-full absolute pin-y top-0 right-0 flex items-center pr-4 focus:outline-none"
        >
          <feather type="send"></feather>
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'AdminProjectTeamChatModal',
  props: ['project'],
  data() {
    return {
      content: '',
      team_messages: [],
      isSending: false,
      isFinishedLoad: false,
      teamId: null
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getterLoggedUser
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
  },
  mounted() {
    this.$eventBus.$on('openAdminProjectTeamChatModal', (teamId) => {
      this.teamId = teamId
      this.$refs.gameChatModal.showModal = true
      this.getTeamMessages(true)
      this.isFinishedLoad = false
      let mainContent = document.getElementById('chat-messages-container')
      if(mainContent) mainContent.scrollIntoView(false)
      this.$store.commit('setUnreadMessages', 0)
      this.joinChatRoom()
    })

    this.$socket.on('messageReceived', () => {
      try {
        this.getTeamMessages()
      } catch (error) {
        console.log(error)
      }
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAdminProjectTeamChatModal')
    this.$socket.off('messageReceived')
    this.$socket.off('joinRoom')
  },
  methods: {

    getTeamMessages(firstLoad) {
      this.$api.get(`/game/team_message/get/${this.teamId}`)
        .then(({ data }) => {
          this.team_messages = data.team_messages
          setTimeout(() => {
            this.scrollToBottom(firstLoad)
          }, 100)
        })
    },

    joinChatRoom() {
      this.$socket.emit('joinRoom', {
        playerId: this.getUser.id,
        playerProjectId: this.getUser.id,
        playerName: this.getUser.name,
        playerBirthday: null,
        playerCountry: 'Brasil',
        projectSlug: this.project.slug,
        teamId: this.teamId,
        isLeader: false
      })
    },

    scrollToBottom(firstLoad) {
      setTimeout(() => {
        let mainContent = document.getElementById('chat-messages-container')
        if(firstLoad) {
         mainContent.scrollTo({
            top: mainContent.scrollHeight,
            left: 0,
          })
        } else {
          if(mainContent) mainContent.scrollTo({
            top: mainContent.scrollHeight,
            left: 0,
            behavior: 'smooth'
          })
        }
        setTimeout(() => {
          this.isFinishedLoad = true
        }, 200)
      },100)
    },

    getMessageClasses(message) {
      if(message.from_id != this.getUser.id) return 'items-start'
      return 'items-end'
    },

    sendMessage() {
      this.isSending = true
      this.$api.post(`/game/team_message/store`, { 
        content: this.content, 
        team_id: this.teamId,
        type: 'user'
      })
        .then(() => {
          try {
            this.content = ''
            this.scrollToBottom()
            this.getTeamMessages()
            this.isSending = false
            window.$socket.emit('messageReceived')
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          this.isSending = false
        })
    }
  }
}
</script>