import Vue from 'vue'
import io from "socket.io-client"

export default ( () => {

  console.log('Starting socket connection at ' + process.env.VUE_APP_BASE_WS_URL)
  const socket = io(process.env.VUE_APP_BASE_WS_URL, {
    reconnectionDelay: 2000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 20,
  })

  /**
   * Modules
   */

  socket.on('connect', () => {
    console.log('Socket successfully connected')
  })
  socket.on('disconnected', () => {
    console.log('Socket disconnected')
  })

  socket.on('error', () => {
    console.log('Socket error')
  })

  Vue.prototype.$socket = socket
  window.$socket = socket

})()
