<template>
  <BaseModal
    ref="baseModal" 
    modalDialogClasses="max-w-md bg-white"
  >
    <div class="w-full flex flex-col items-center justify-center p-4">

      <div class="w-full flex justify-center">
        <div class="flex flex-col items-center">
          <img class="h-5vh mx-2" src="/icons/pcd.png" alt="" />
          <p class="text-base mt-1">PCD</p>
        </div>
        <div class="flex flex-col items-center">
          <img class="h-5vh mx-2" src="/icons/diversidade.png" alt="" />
          <p class="text-base mt-1">LGBTQIA+</p>
        </div>
        <div class="flex flex-col items-center">
          <img class="h-5vh mx-2" src="/icons/autismo.png" alt="" />
          <p class="text-base mt-1">Autismo</p>
        </div>
        <div class="flex flex-col items-center">
          <img class="h-5vh mx-2" src="/icons/etnia.png" alt="" />
          <p class="text-base mt-1">Etnia</p>
        </div>
        <div class="flex flex-col items-center">
          <img class="h-5vh mx-2" src="/icons/60.png" alt="" />
          <p class="text-base mt-1">+ 60 anos</p>
        </div>
      </div>

      <p class="text-black text-center my-4 mx-6 font-light">A diversidade proporciona novas conexões e o desenvolvimento da capacidade intelectual, aprender a viver em um ambiente de diversidade é um dos principais desafios do mundo contemporâneo.</p>
      
      <GameButton label="Voltar" class="w-10vw text-lg" @click="$refs.inclusionModal.showModal = false"></GameButton>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'InclusionModal',
  computed: {
    getTeamPlayers() {
      return this.$store.getters.getterTeamPlayers
    }
  }

}
</script>

<style>

</style>