<template>
  <div class="w-full flex flex-col" v-if="currentQuizQuestion">

    <div class="w-full h-60vh">
      <GameGeniusLeaderBoard 
        v-if="getPlayerProject.is_leader"
        :project="getProject"
        :game="game"
        :getTotalGameTime="getTotalGameTime"
        :currentQuizQuestion="currentQuizQuestion"
        :optionSelectedIndex="optionSelectedIndex"
        :quizQuestions="quizQuestions"
      ></GameGeniusLeaderBoard>
      <GameGeniusTeamBoard 
        :currentQuizQuestion="currentQuizQuestion"
        :optionSelectedIndex="optionSelectedIndex"
        v-else
      ></GameGeniusTeamBoard>
    </div>

    <GameGeniusActionsMenu 
      :game="game"
      :currentQuizQuestion="currentQuizQuestion"
    ></GameGeniusActionsMenu>

    <GameIntroductionModal :game="game" ></GameIntroductionModal>

    <audio id="genius-sound-0" src="/audio/genius/genius_0.mp3"></audio>
    <audio id="genius-sound-1" src="/audio/genius/genius_1.mp3"></audio>
    <audio id="genius-sound-2" src="/audio/genius/genius_2.mp3"></audio>
    <audio id="genius-sound-3" src="/audio/genius/genius_3.mp3"></audio>

  </div>
</template>

<script>
import GameGeniusTeamBoard from './GameGeniusTeamBoard'
import GameGeniusLeaderBoard from './GameGeniusLeaderBoard'
import GameGeniusActionsMenu from './GameGeniusActionsMenu'
export default {
  name: 'GameGenius',
  components: { GameGeniusTeamBoard, GameGeniusLeaderBoard, GameGeniusActionsMenu },
  props: ['game'],
  data() {
    return {
      optionSelectedIndex: false,
      quizQuestions: [],
    }
  },

  computed: {
    currentQuizQuestion() {
      return this.quizQuestions[this.getCurrentStep] ? this.quizQuestions[this.getCurrentStep] : null
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getCurrentStep() {
      return this.$route.params.step || 0
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
  },

  mounted() {
    this.getQuizQuestions()
    window.$socket.on('gameSelectOptionIndex', ({ index }) => {
      this.optionSelectedIndex = index
    })
  },

  methods: {

    getQuizQuestions() {
      const query = `
        {
          game (
            findBy: { column: "slug", value: "${this.$route.params.gameSlug}" }
          ) {
            id
            name
            quiz_questions {
              id
              order
              title
              content
              options {
                id
                order
                title
                content
                is_correct
              }
            }
          }
        }
      `

      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.quizQuestions = data.game.quiz_questions
        })
    },

    selectOption(option) {
      window.$socket.emit('gameSelectOption', { option })
    }
    
  }
}
</script>