import createPersistedState from 'vuex-persistedstate'
import Vue from 'vue'
import Vuex from 'vuex'
import EventBus from '@/util/EventBus'


Vue.use(Vuex);

import adminGeneral from '@/modules/admin/general/vuex'
import adminAuth from '@/modules/admin/auth/vuex'

import gamePlayerAuth from '@/modules/game/auth/vuex'
import gamePlayer from '@/modules/game/player/vuex'
import gameRoom from '@/modules/game/room/vuex'
import gamePlay from '@/modules/game/play/vuex'
import gameChat from '@/modules/game/chat/vuex'

import reportAuth from '@/modules/report/auth/vuex'

const store = new Vuex.Store({
  modules: {
    adminGeneral,
    adminAuth,
    gamePlayerAuth,
    gamePlayer,
    gameRoom,
    gamePlay,
    gameChat,
    reportAuth,
  },
  plugins: [createPersistedState({
    key: 'ideia-ativada',
  })]
})

store.$eventBus = EventBus

export default store
