

export default {
  methods: {
    $gameToast(payload) {
      this.$eventBus.$emit('openGameToast', payload)
    }
  },
  mounted() {
    window.$gameToast = this.$gameToast
  }
}

