<template>
  <div>
    <span v-if="boolean">{{ positiveLabel }}</span>
    <span v-else>{{ negativeLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'BooleanLabel',
  props: {
    boolean: {
      type: Boolean,
      required: true
    },
    positiveLabel: {
      type: String,
      default: 'Sim'
    },
    negativeLabel: {
      type: String,
      default: 'Não'
    },
  }
}
</script>