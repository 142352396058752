<template>
  <div class="w-full">
    <div class="w-full text-white mt-2 rounded border border-yellow p-2 h-15vh relative border-only-corner flex justify-center relative">
      <span></span>
      <VideoAudioControls></VideoAudioControls>
      <GameButton @click="$eventBus.$emit('openChatModal')" label="Chat" class="w-12vw text-2xl mx-2" :notifications="getUnreadMessages" bgNotifications="bg-red"></GameButton>
      <GameButton @click="$eventBus.$emit('openGameTipModal')" label="Dicas" class="w-12vw text-2xl mx-2" :notifications="getTipsUnlocked" bgNotifications="bg-green"></GameButton>
      <div class="h-full flex items-center absolute right-0 top-0 pr-3">
        <button @click="$eventBus.$emit('openGameIntroductionModal')" class="w-6vh h-6vh rounded-full flex items-center justify-center text-yellow focus:outline-none">
          <feather type="help-circle"></feather>
        </button>
      </div>
    </div>

    <GameTipModal
      :game="game"
      :item_id="currentQuizQuestion.id"
      :item_type="'QuizQuestion'"
    ></GameTipModal>

  </div>
</template>

<script>
import GameTipModal from '../tips/GameTipModal'
export default {
  name: 'GameQuizActionMenu',
  components: { GameTipModal },
  props: ['game', 'currentQuizQuestion'],
  data() {
    return {
      tipSelected: null,
      quizQuestionTipSelected: null
    }
  },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
    getUnreadMessages() {
      return this.$store.state.gameChat.unreadMessages
    },
    getTipsUnlocked() {
      return this.$store.state.gamePlay.tipsUnlocked
    },
  }
}
</script>

<style>

</style>