<template>
  <div class="w-full max-w-full flex flex-wrap items-start">
    <fieldset class="w-full mt-6">
      <legend class="h4 font-semi-bold mb-2 flex">
        <span>{{quiz_question.title}}</span>
        <button 
          @click="$eventBus.$emit('openQuizQuestionModal', { quiz_question_id: quiz_question.id })"
          class="ml-2"
        >
          <feather type="edit"></feather>
        </button>
      </legend>
      <p>{{quiz_question.content}}</p>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-10"/>

    <GameQuizQuestionOptionList></GameQuizQuestionOptionList>

    <hr class="border-b-1 border-smoke-light w-full my-10"/>
    
    <GameTipList :item_id="$route.params.quizQuestionId"></GameTipList>

    <GameQuizQuestionModal @updated="getQuizQuestion()"></GameQuizQuestionModal>

    <AvoidExitModal></AvoidExitModal>

  </div>
</template>

<script>
import QuizQuestion from '@/models/QuizQuestion'
import GameQuizQuestionModal from './GameQuizQuestionModal'
import GameQuizQuestionOptionList from './GameQuizQuestionOptionList'
import GameTipList from '../tips/GameTipList'
export default {
  name: 'GameQuizQuestionShow',
  components: { GameQuizQuestionModal, GameQuizQuestionOptionList, GameTipList },
  data() {
    return {
      quiz_question: new QuizQuestion(),
      formType: null
    }
  },
  mounted() {
    this.getQuizQuestion()
  },
  methods: {

    getQuizQuestion() {
      const query =  `
        {
          quiz_question (
            findBy: { column: "id", value: "${this.$route.params.quizQuestionId}"}
          ) {
            id
            title
            content
            file_url
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.quiz_question = new QuizQuestion(data.quiz_question)
        })
    },
    
    showOptionTipForm(type) {
      this.formType = type
    }
  }
}
</script>