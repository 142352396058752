const accounting = require('accounting')

/**
 * Generate a rondom number
 * @type {number}
 */
module.exports.randomInt = (min, max) => {
	return min + Math.floor((max - min) * Math.random());
}

module.exports.round = (number, decimals = 2) => {
  var p = Math.pow(10, decimals)
  return Math.round(number * p) / p
}

/**
 * Whether the web app is running on a mobile browser.
 * @type {boolean}
 */
module.exports.isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false
  }
  return /Mobile/.test(navigator.userAgent)
})()

module.exports.formatCurrency = ({ value, precision = 2, symbol = '$' }) => {
  accounting.settings = {
    currency: {
      symbol: symbol,
      format: {
        pos : "%s%v",
        neg : "-%s%v",
        zero: " --"
      },
      decimal: ".",
      thousand: ",",
      precision: precision
    },
    number: {
      precision: precision,  // default precision on numbers is 0
      thousand: ".",
      decimal: ","
    }
  }

  return accounting.formatMoney(parseFloat(value))
}

module.exports.formatNumber = (num = '', precision = 2) => {

  accounting.settings = {
    currency: {
      symbol: "US$ ",
      format: "%s%v",
      decimal: ".",
      thousand: ",",
      precision: 2
    },
    number: {
      precision: precision,  // default precision on numbers is 0
      thousand: "",
      decimal: "."
    }
  }

  return parseFloat(accounting.formatNumber(parseFloat(num)))
}

const checkObjMatch = (item, obj) => {
  const diffKeys = Object.keys(obj).filter((key) => {
    if(Array.isArray(obj[key])) return !obj[key].includes(item[key])
    return obj[key] != item[key]
  })
  if(diffKeys.length) return false
  return item
}

const findByObj = (arr, obj) => {
  for(const item of arr) {
    if(!checkObjMatch(item, obj)) continue
    return item
  }
  return false
}
module.exports.findByObj = findByObj

const findByString = (arr, item, asBoolean = false) => {
  for(const arrItem of arr) {
    if(arrItem == item) {
      return asBoolean ? true : arrItem
    }
  }
  return false
}
module.exports.findByString = findByString

const find = (arr, obj) => {
  if(typeof(obj) === 'object') return findByObj(arr, obj)
  return findByString(arr, obj)
}
module.exports.find = find

const findIndex = (arr, obj) => {
  if(typeof(obj) === 'object') {
    const findedByObj = findByObj(arr, obj)
    return findedByObj != false ? arr.indexOf(findedByObj) : -1 
  }
  const findedByString = findByString(arr, obj)
  return findedByString !== false ? arr.indexOf(findedByString) : -1  
}
module.exports.findIndex = findIndex

const findAll = (arr, obj) => {
  if (!obj) return arr
  return arr.filter((item) => {
    if(typeof(obj) == 'string') return item === obj
    return !checkObjMatch(item, obj) ? false : true
  })
}
module.exports.findAll = findAll

const removeAll = (arr, obj) => {
  return arr.filter((item) => {
    if(typeof(obj) == 'string') return item !== obj
    return !checkObjMatch(item, obj) ? true : false
  })
}
module.exports.removeAll = removeAll

module.exports.getFormData = (obj) => {
    const fd = new FormData()
    for(const key of Object.keys(obj)) {
      if(typeof(obj[key]) === 'object' && obj[key] && obj[key].type) {
        if(obj[key].name) {
          fd.append(key, obj[key])
        } else {
          fd.append(key, null)
        }
      } else if( typeof(obj[key]) != 'function') {
        fd.append(key, obj[key])
      }
    }
    return fd
}

module.exports.getLetterNumber = (number) => {
  const string = 'abcdefghijklmnopqrstuvwxyz'
  return string[number]
}

module.exports.getHashCharCodeValue = (uuid) => {
  let value = 0
  for(let i = 0; i < uuid.length; i++) {
    value+= uuid[i].charCodeAt()
  }
  return value
}