export default {
  getterPlayer: state => state.player,
  getterProject: state => state.project,
  getterTeam: state => state.team,
  getterPlayerProject: state => state.playerProject,
  getterTeamPlayersProject: (state) => {
    return state.team.players_project
  },
  getterTeamPlayers: (state) => {
    return state.team.players_project.map((player_project) => player_project.player)
  },
}
