<template>
  <div class="block w-full">
    <label :for="getId" v-if="label" class="block mb-1">{{label}}</label>
    <div class="relative">
      <input
        v-if="!mask"
        :type="getType"
        :id="getId"
        :autocapitalize="autocapitalize"
        :class="{'input-has-error' : validation.hasError, 'filled' : value}"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :placeholder="placeholder || label"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        class="block appearance-none rounded bg-white focus:shadow-sm outline-none w-full border-2 border-grey-light focus:border-yellow placeholder-grey bg-white text-grey-darker text-base py-1 px-2"
      />
      <input
        v-else
        :type="getType"
        :id="getId"
        :autocapitalize="autocapitalize"
        :class="{'input-has-error' : validation.hasError, 'filled' : value}"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :placeholder="placeholder || label"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        v-mask="mask"
        class="block appearance-none rounded bg-white focus:shadow-sm outline-none w-full border-2 border-grey-light focus:border-yellow placeholder-grey bg-white text-grey-darker text-base py-1 px-2"
      />
      <button 
        v-if="type === 'password'" 
        @click="showPassword = !showPassword"
        :class="[showPassword ? 'text-yellow' : 'text-black', validation.hasError ? 'text-red' : '']"
        class="h-full absolute pin-y top-0 right-0 mr-3 flex items-center pl-2 focus:outline-none">
        <feather :type="showPassword ? 'eye' : 'eye-off'" size="18"></feather>
      </button>
    </div>
    <p class="text-red w-full pl-3" v-if="validation.hasError && validation.label">{{validation.label}}</p>
  </div>
</template>

<script>
import { StringHelpers } from '@igortrindade/lazyfy'

export default {
  name: "InputWithLabel",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    action: {
      type: Function,
      required: false
    },
    mask: {
      type: [String, Array],
      default: ''
    },
    autocapitalize: {
      type: String,
      default: 'sentences'
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      default: false
    },
    disabled: {
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    getType() {
      if(this.type !== 'password') return this.type
      if(this.type === 'password' && this.showPassword) return 'text'
      return this.type
    },

    getId() {
      if(this.id) return this.id
      return StringHelpers.randomString(12)
    }
  },
  methods: {
    makeAction() {
      if(this.action && typeof(this.action) == 'function') this.action()
    }
  }
};
</script>

<style>

  input:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
</style>
