import GamePlayerAuth from './auth/GamePlayerAuth'
import GameLobby from './lobby/GameLobby'
import GamePlay from './play/GamePlay'
import GameResult from './result/GameResult'
import GameTeamRanking from './team-ranking/GameTeamRanking'
import GamePlayerForm from './player-form/GamePlayerForm'
import InGameLayout from './layout/InGameLayout'

export default [
  {
    path: '/game/welcome/:projectSlug',
    name: 'GamePlayerAuth',
    component: GamePlayerAuth
  },
  {
    path: '/game/welcome/:projectSlug/:teamId',
    name: 'GameLobby',
    component: GameLobby,
    meta: {
      auth: { type: 'player' }
    }
  },
  {
    path: '/game/ranking/:teamId',
    name: 'GameTeamRanking',
    component: GameTeamRanking,
  },
  {
    path: '/game/cadastro/:playerProjectId',
    name: 'GamePlayerForm',
    component: GamePlayerForm,
  },
  {
    path: '/game/play',
    component: InGameLayout,
    children: [
      {
        path: 'result/:projectSlug/:teamId',
        name: 'GameResult',
        component: GameResult,
        meta: {
          auth: { type: 'player' }
        }
      },
      {
        path: ':projectSlug/:teamId/:gameSlug/:step?',
        name: 'GamePlay',
        component: GamePlay,
        meta: {
          auth: { type: 'player' }
        }
      },
    ]
  }
]