<template>
  <ReportWithHeader>
    <div class="w-full flex h-full">

      <div class="w-60/100 h-full flex items-center">
        <div class="w-full h-full max-h-full" ref="diversityChart"></div>
      </div>
      <div class="w-40/100 flex items-center justify-center p-12">

        <div class="p-6 border border-smoke-extra-light rounded shadow flex flex-col">
          <h3 class="font-bold mb-3">Legenda</h3>

          <div class="w-full flex flex-col space-y-2">
            <div class="w-full flex items-center space-x-3" v-for="(item, index) in items" :key="index">
              <div class="w-2vh h-2vh block" :style="{ backgroundColor: chartAvailableColors[index] }"></div>
              <h3 class="font-semi-bold">{{ item.title }} ({{ item.percentage }}%)</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ReportWithHeader>
</template>

<script>
import ApexCharts from 'apexcharts'
import { chartAvailableColors } from '@/models/Enums'
export default {
  name: 'ReportTeamAnalysis',
  data() {
    return {
      chartAvailableColors,
      items: []
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_diversity_report')}`)
        .then(({ data }) => {
          this.mountChart(data.items, data.total)
          this.items = data.items
        })
    },
    mountChart(items, total) {
      var options = {
        chart: {
          type: 'radialBar',
          height: '100%',
        },
        colors: chartAvailableColors,
        series: items.map((item) => item.percentage),
        labels: items.map((item) => `${item.title}`),
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px"
              },
              value: {
                fontSize: "16px"
              },
              total: {
                show: true,
                label: "Total",
                formatter: function() {
                  return total.count
                }
              }
            }
          }
        },
      }

      const chart = new ApexCharts(this.$refs.diversityChart, options)
      chart.render()
    }
  }
}
</script>

<style>

</style>