<template>
  <div class="flex items-center">
    <label class="switch">
      <input type="checkbox" v-model="childValue" :disabled="disabled" tabindex="1"/>
      <span class="slider"></span>
    </label>
    <p class="ellipsis ml-2" v-if="label">{{label}}</p>
  </div>
</template>

<script>
export default {
  name: 'SwitchToggle',
  props: ['value', 'label', 'disabled'],
  computed: {
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue )
      }
    }
  }
}
</script>

<style>

.switch input {
  display: none;
}

.switch {
  display: flex;
  background-color: #FFF;
  width: 64px; /*=w*/
  min-width: 64px;
  height: 30px; /*=h*/
  margin: 8px 8px 8px 0px;
  padding-left: 4px;
  align-items: center;
  border-radius: 9999px;
  box-shadow: 2px 2px 6px 0px rgba(47, 49, 82, 0.15);
  cursor: pointer;
}

.slider {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 9999px;
  box-shadow: 2px 2px 6px 0px rgba(47, 49, 82, 0.15);
  cursor: pointer;
  transition: 0.4s;
  background-color: #737273;
}


input:checked + .slider {
  transform: translateX(32px); /*translateX(w-h)*/
  background-color: #FBBD0E;
}


</style>
