<template>
  <BaseModal
    ref="gameTipModal" 
    modalDialogClasses="max-w-lg bg-black"
    @onCloseModal="close()"
  >


    <div class="w-full h-full flex items-center justify-center" v-if="!tips.length">
      <h2 class="w-full text-center font-bold text-xl">Este game não possui dicas</h2>
    </div>
    <div class="w-full flex flex-col" v-else>
      <h2 class="w-full text-center font-bold text-xl">Dicas</h2>
      
      <div v-if="!tipOpened && !tipSelectedToOpen" class="w-full flex flex-col justify-center mt-4 relative">
        <div class="w-full flex justify-start overflow-x-auto hide-scrollbar space-x-4">
          <div
            class="max-h-full"
            v-for="(tip, index) in tips"
            :key="index"
          >
            <button
              v-if="!tip.team_tips.length"
              @click="setTipToUnlock(tip)"
              :disabled="!checkTipCouldBeUnlocked(index) || getPlayerProjectIsHidden"
              class="h-full rounded p-3 border border-yellow flex flex-col justify-center items-center"
            >
              <feather type="unlock"></feather>
              <span class="mt-3">Revelar {{tip.title}}</span>
            </button>
            <button
              v-else
              @click="tipOpened = tip"
              class="rounded p-3 border border-white flex flex-col justify-center items-center"
            >
              <feather type="eye"></feather>
              <span class="mt-3">Abrir {{tip.title}}</span>
            </button>
          </div>
        </div>
        <p class="w-full text-white text-base text-center mt-2" v-if="getProject.has_penalties">Ao revelar a dica, será acrescido {{game.penalty_time}} segundos no tempo total</p>
      </div>

      <div v-if="tipSelectedToOpen" class="w-full flex flex-col justify-center items-center mt-4">
        <p class="w-full text-white text-center mt-2 bg-smoke-yellow p-2">Ao revelar ass dica, será acrescido {{ game.penalty_time }} segundos no tempo total</p>
        <div class="w-full flex justify-center mt-4">
          <button class="text-white mr-3" @click="tipSelectedToOpen = null">Cancelar</button>
          <GameButton class="max-w-10vw" label="Confirma" @click="confirmUnlockTip()"></GameButton>
        </div>
      </div>

      <div v-if="tipOpened" class="w-full flex flex-col justify-center items-center mt-4">
        <div class="w-full flex justify-center">
          <img v-if="tipOpened.file_url" class="w-full object-cover center" :src="tipOpened.file_url" alt=""/>
          <p v-else>{{tipOpened.content}}</p>
        </div>
        <div class="w-full flex justify-center mt-3">
          <GameButton class="max-w-8vw" label="voltar" @click="tipOpened = null"></GameButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'GameTipModal',
  props: [
    'game',
    'item_id',
    'item_type',
  ],
  data() {
    return {
      tips: [],
      tipOpened: null,
      tipSelectedToOpen: null
    }
  },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
    getPlayerProjectIsHidden() {
      return this.$store.getters.getterPlayerProject && this.$store.getters.getterPlayerProject.is_hidden
    },
  },
  mounted() {
    this.$eventBus.$on('openGameTipModal', () => {
      this.$refs.gameTipModal.showModal = true
      this.getTips()
    })
    window.$socket.on('roomTipUnlocked', this.onTipUnlocked)
  },
  beforeDestroy() {
    this.$eventBus.$off('openGameTipModal')
    window.$socket.off('roomTipUnlocked')
  },
  methods: {

    getTips() {
      const query = `
        {
          tips (
            where: [
              { column: "item_id", value: "${this.item_id}" }
              { column: "item_type", value: "${this.item_type}" }
            ]
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            content
            file_url
            team_tips (
              where: [
                { column: "team_id", operation: "=", value: "${this.getTeam.id}" }
              ]
            ) {
              id
            }
          }
        }
      `

      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.tips = data.tips
          this.setTipsUnlocked()
        })
    },
    close() {
      this.tipOpened = null
      this.tipSelectedToOpen = null
    },
    setTipToUnlock(tip) {
      if(this.getProject.has_penalties) {
        this.tipSelectedToOpen = tip
      } else {
        this.unlockTip(tip.id)
      }
    },

    confirmUnlockTip() {
      this.unlockTip(this.tipSelectedToOpen.id)
    },

    unlockTip(tip_id) {
      const data = {
        game_id: this.game.id,
        team_id: this.getTeam.id,
        tip_id,
        game_current_time: this.getTotalGameTime,
        team_current_time: this.getTotalTeamTime,
      }
      this.$api.post(`/game/team_tip/unlock_tip`, data)
        .then(() => {
          this.getTips()
          this.tipSelectedToOpen = null
          this.$socket.emit('roomTipUnlocked')
          window.playSound('unlock-tip')
        })
    },

    onTipUnlocked() {
      this.$store.dispatch('refreshLoggedPlayer')
      this.getTips()
    },

    setTipsUnlocked() {
      const tipsUnlocked = this.tips.reduce((acc, item) => {
        if(item.team_tips.length) return acc + 1
        return acc
      }, 0)
      this.$store.commit('setTipsUnlocked', tipsUnlocked)
    },

    checkTipCouldBeUnlocked(index) {
      if(index === 0) return true
      if(this.tips[index-1].team_tips.length) return true
      return false
    }
  }
}
</script>

<style>

</style>