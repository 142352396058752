<template>
  <div class="w-full flex flex-col rounded-lg">

    <div class="w-full h-10vh">
      <h1 class="text-center text-white text-3xl font-bold">Parabéns</h1>
    </div>
    <div class="w-full h-50vh rounded border border-yellow border-only-corner p-2 relative flex hide-scrollbar">
      <span></span>
      <div class="w-full h-full rounded text-white flex flex-col py-2 px-4">
        <div class="w-full flex justify-between text-yellow">
          <h3 class="w-60/100">Jogo</h3>
          <h3 class="w-20/100">Tempo resolução</h3>
          <h3 class="w-20/100">Penalidades</h3>
        </div>
        <div class="w-full flex flex-col mt-5 overflow-y-auto max-h-full hide-scrollbar">
          <div 
            class="w-full flex justify-between"
            v-for="team_game in team_games"
            :key="team_game.id"
          >
            <h3 class="w-60/100">{{ team_game.game.name }}</h3>
            <h3 class="w-20/100">{{ team_game.game_total_time }}</h3>
            <h3 class="w-20/100 text-red"><span v-if="team_game.game_penalties_time && team_game.game_penalties_time != '00:00:00'">+{{ team_game.game_penalties_time }}</span></h3>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full mt-2 rounded border border-yellow p-2 h-15vh relative border-only-corner flex justify-center relative">
      <span></span>
      <VideoAudioControls></VideoAudioControls>
      <GameButton @click="$eventBus.$emit('openChatModal')" label="Chat" class="w-12vw text-2xl mx-2" :notifications="getUnreadMessages" bgNotifications="bg-red"></GameButton>
      <GameButton @click="$router.push(`/game/welcome/${getProject.slug}`)" label="Sair" class="w-12vw text-2xl mx-2"></GameButton>
      <GameButton @click="$router.push(`/game/ranking/${getTeam.id}`)" label="Ranking" class="w-12vw text-2xl mx-2"></GameButton>
    </div>

    <GameChatModal></GameChatModal>
  </div>
</template>

<script>
import GameChatModal from '@/modules/game/chat/GameChatModal'
export default {
  name: 'GameResult',
  components: {  GameChatModal },
  metaInfo () {
    return {
      title: `Resultado time ${this.getTeam.name} | `
    }
  },
  data() {
    return {
      team_games: []
    }
  },
  computed: {
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getUnreadMessages() {
      return this.$store.state.gameChat.unreadMessages
    },
  },

  mounted() {
    this.getTeamGames()
  },
  methods: {
    getTeamGames() {
      const query = `
        {
          team (
            findBy:{
              column: "id", value: "${this.$route.params.teamId}"
            }
          ) {
            id
            name
            team_games {
              id
              game_total_time
              game_penalties_time
              game {
                name
              }
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.team_games = data.team.team_games
        })
    }
  }
}
</script>