// import dayjs from 'dayjs'
import BaseModel from './BaseModel'
import { emailValidator, bdayValidator, minLength } from '@/util/ValidationFunctions'
import { 
    playerSchoolarship,
    playerSexOrientation,
    playerRaceColors,
    playerForeignLanguage,
    playerTimeFromWork,
    playerGender,
    playerDistanceFromWork
} from '@/models/Enums'
export default class Player extends BaseModel {

  constructor(obj = {}) {
    super()

    if(process.env.NODE_ENV == 'development') {

      this.id = obj.id || null
      this.name = obj.name || 'Igor'
      this.email = obj.email || 'igortrindademe@gmail.com'
      this.mobile = obj.mobile || '31981007753'
      this.cep = obj.cep || '35690-000'
      this.address = obj.address || 'Rua da rua'
      this.country = obj.country || 'Brazuca'
      this.education = obj.education || playerSchoolarship[0].value
      this.occupation = obj.occupation || 'Atoa'
      this.role = obj.role || 'Sem nada pra fazer'
      this.time_in_service = obj.time_in_service || '2 anos'
      this.gender = obj.gender || playerGender[0]
      this.sex_orientation = obj.sex_orientation || playerSexOrientation[0]
      this.race_color = obj.race_color || playerRaceColors[0]
      this.birthday = obj.birthday || '10/10/2000'
      this.is_filled = obj.is_filled || ''
      this.is_blocked = obj.is_blocked || ''
      
      this.foreign_language = obj.foreign_language || playerForeignLanguage[0].value
      this.distance_from_work = obj.distance_from_work || playerDistanceFromWork[0]
      this.time_from_work = obj.time_from_work || playerTimeFromWork[0]
      this.type_of_transport_to_work = obj.type_of_transport_to_work || 'Particular'

      this.languages = obj.languages || []
      this.pcds = obj.pcds || []

    } else {
      
      this.id = obj.id || null
      this.name = obj.name || ''
      this.email = obj.email || ''
      this.mobile = obj.mobile || ''
      this.cep = obj.cep || ''
      this.address = obj.address || ''
      this.country = obj.country || ''
      this.education = obj.education || ''
      this.occupation = obj.occupation || ''
      this.role = obj.role || ''
      this.time_in_service = obj.time_in_service || ''
      this.gender = obj.gender || ''
      this.sex_orientation = obj.sex_orientation || ''
      this.race_color = obj.race_color || ''
      this.birthday = obj.birthday || ''
      this.is_filled = obj.is_filled || ''
      this.is_blocked = obj.is_blocked || ''

      this.foreign_language = obj.foreign_language || ''
      this.distance_from_work = obj.distance_from_work || ''
      this.time_from_work = obj.time_from_work || ''
      this.type_of_transport_to_work = obj.type_of_transport_to_work || ''
      this.languages = obj.languages || []
      this.pcds = obj.pcds || []
    }
    
  }

  get requireds() {
    return [
      { item: 'name', label: 'nome' },
      { item: 'email', label: 'email', validation: value => emailValidator(value) },
      { item: 'cep', label: 'CEP', validation: value => minLength(value, 9) },
      { item: 'address', label: 'endereço completo', validation: value => minLength(value, 9) },
      { item: 'country', label: 'país', validation: value => minLength(value, 3) },
      { item: 'education', label: 'educação', validation: value => minLength(value, 3) },
      { item: 'distance_from_work', label: 'distância do trabalho', validation: value => minLength(value, 3) },
      { item: 'time_from_work', label: 'tempo de deslocamento do trabalho', validation: value => minLength(value, 3) },
      { item: 'type_of_transport_to_work', label: 'tipo de transporte para o trabalho', validation: value => minLength(value, 3) },
      { item: 'occupation', label: 'ocupação', validation: value => minLength(value, 3) },
      { item: 'role', label: 'cargo', validation: value => minLength(value, 3) },
      { item: 'time_in_service', label: 'tempo de serviço', validation: value => minLength(value, 3) },
      { item: 'gender', label: 'gênero' },
      { item: 'gender_identity', label: 'identidade de gênero' },
      { item: 'sex_orientation', label: 'orientação sexual' },
      { item: 'race_color', label: 'cor' },
      { item: 'birthday', label: 'data nascimento', validation: value => bdayValidator(value) },
    ]
  }

}