<template>
  <div class="app-inputrangeslider">
    <div class="bar-wrapper" :style="{ width: `${width}px`}">
      <input 
        type="range"
        class="slider"
        :min="min" 
        :max="max" 
        :value="value"
        :step="step"
        @input="$emit('input', Number($event.target.value))"
      />
      <div class="bar">
        <span class="fill" :style="{ width: `${valueScaledInPixels}px` }"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputSlider',
  props: {
    min: {
      type: Number,
      default: 0,
    },    
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: [Number, String],
    },
    step: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 160
    }
  },
  computed: {
    rangeDistance() {
      return this.value - this.min
    },
    rangeDistanceMax() {
      return this.max - this.min
    },
    valueScaled() {
      return this.getValueToScale(this.rangeDistance)
    },
    baseValueScaled() {
      return this.getValueToScale(this.baseValue)
    },
    valueScaledInPixels() {
      const value = (this.width * this.getValueToScale(this.rangeDistance) / 100 )
      if (value > 0 && value < 82) {
        return value + 4
      } else if (value > 0 && value > 82) {
        return value - 2
      } else {
        return value
      }
    },
    baseValueScaledInPixels() {
      return this.width * this.baseValueScaled / 100       
    }
  },
  methods: {
    getValueToScale(value) {
      return 100 * value / this.rangeDistanceMax
    }
  }
}
</script>

<style scoped>

.app-inputrangeslider {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.bar-wrapper {
  position: relative;
  flex-shrink: 0;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: 20px;
  border-radius: 5px;
}

/* The slider itself */
input {
  appearance: none;
  width: 100%; /* Full-width */
  height: 14px; /* Specified height */
  background-color: transparent;
  outline: none; /* Remove outline */  
  transition: opacity .2s;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  cursor: pointer;
  appearance: none;
  width: 21px;
  height: 21px;
  background: #F7F7F7;
  border: 3px solid #FBBD0E;
  border-radius: 30px;
  margin-top: 3px;
}

.bar {
  width: 100%;
  position: absolute;
  height: 10px;
  border-radius: 5px;
  background: #FFF;
  top: 4px;
  left: 0;
  z-index: 0;
}

.bar  span.fill {
  position: absolute;
  height: 10px;
  border-radius: 5px;
  background: #FBBD0E;
  top: 0;
  left: 0;
  z-index: 0;
}

</style>