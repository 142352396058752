<template>
  <transition
    name="fade"
  >
    <div v-if="showModal" class="fixed inset-0 bg-smoke flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div class="w-full rounded shadow p-6 relative my-auto border border-yellow border-only-corner border-only-corner-lg rounded" :class="[modalDialogClasses]">
        <span></span>
        <button 
          @click="closeModal()"
          class="modal-exit-button p-1 rounded-full absolute top-0 right-0 bg-black flex items-center justify-center text-yellow focus:outline-none z-20">
          <feather type="x" size="24"></feather>
        </button>
        <h4 class="w-full font-bold text-center font-normal tracking-wide" v-if="title">{{title}}</h4>
        <div class="w-full text-white">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    modalDialogClasses: {
      default: 'max-w-sm bg-yellow-smoke'
    }
  },
  data() {
    return {
      showModal: false,
    }
  },
  mounted() {
    document.addEventListener("keyup", this.handleEsc)
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEsc)
  },
  methods: {
    handleEsc(evt) {
      if (this.showModal && evt.keyCode === 27) this.closeModal()
    },
    closeModal() {
      this.showModal = false
      this.$emit('onCloseModal')
    }
  }
}
</script>
<style>
.modal-exit-button {
  top: -3vh;
  right: -3vh;
}
</style>
