<template>
  <BaseModal
    ref="baseModal"
    modalDialogClasses="max-w-sm bg-white text-black"
    @onCloseModal="close()"
  >
    <div class="w-full max-w-full flex flex-wrap items-start text-black">
      <fieldset class="w-full mt-6">
        <legend class="h4 font-semi-bold mb-2">Opção de resposta</legend>
        <div class="w-full flex flex-wrap">
          <InputWithLabel
            class="mt-4 w-full"
            label="Opção de resposta enigma"
            placeholder="Digite aqui"
            v-model="puzzleAnswerOption.content"
          ></InputWithLabel>
        </div>

        <div class="rounded bg-yellow text-black text-base p-1 text-center mt-2">Não é necessário diferenciar letras maísculas e minúsculas</div>
      </fieldset>

      <div class="w-full text-base mt-4" v-if="puzzleAnswerOption.hasError" v-html="puzzleAnswerOption.validationPhraseHtml"></div>

      <div class="w-full flex mt-6">
        <button @click="store()" :disabled="puzzleAnswerOption.hasError" class="button button-yellow px-8">
          Salvar
        </button>
      </div>

    </div>
  </BaseModal>
</template>

<script>
import PuzzleAnswerOption from '@/models/PuzzleAnswerOption'
export default {
  name: 'GamePuzzleAnswerOptionForm',
  data() {
    return {
      puzzleAnswerOption: new PuzzleAnswerOption()
    }
  },
  mounted() {
    this.$eventBus.$on('openPuzzleAnswerOptionModal', ({ id = null } = {}) => {
      this.puzzleAnswerOption.game_id = this.$route.params.gameId
      this.$refs.baseModal.showModal = true
      this.puzzleAnswerOption.id = id
      if(id) this.getPuzzleAnswerOption()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPuzzleAnswerOptionModal')
  },
  methods: {

    getPuzzleAnswerOption() {
      const query =  `
        {
          puzzle_answer_option (
            findBy: { column: "id", value: "${ this.puzzleAnswerOption.id }"}
          ) {
            id
            content
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.puzzleAnswerOption = new PuzzleAnswerOption(data.puzzle_answer_option)
        })
    },

    store() {
      const url = this.puzzleAnswerOption.id ? `/admin/puzzle_answer_options/update/${this.puzzleAnswerOption.id}` : `/admin/puzzle_answer_options/store`
      this.$api.post(url, this.puzzleAnswerOption, { loader: true  })
        .then(() => {
          this.$toast.success(`Item salvo com sucesso`)
          this.close()
          this.$emit('update')
        })
    },

    close() {
      this.$refs.baseModal.showModal = false
      this.puzzleAnswerOption = new PuzzleAnswerOption()
    }
  }
}
</script>