import BaseModel from './BaseModel'
import { getFormData } from '@/util/Functions'
export default class Company extends BaseModel {
  constructor(obj = {}) {
    super()
    this.key = obj.key || null
    this.id = obj.id || null
    this.is_branch = obj.is_branch || false
    this.industry_id = obj.industry_id || null
    this.main_company_id = obj.main_company_id || null
    this.name = obj.name || ''
    this.corporate_name = obj.corporate_name || ''
    this.cnpj = obj.cnpj || ''
    this.company_size = obj.company_size || 'small'
    this.employees_number = obj.employees_number || ''
    this.cep = obj.cep || ''
    this.street = obj.street || ''
    this.state = obj.state || ''
    this.country = obj.country || ''
    this.contact_name = obj.contact_name || ''
    this.contact_phone = obj.contact_phone || ''
    this.email = obj.email || ''
    this.observation = obj.observation || ''
    this.is_active = obj.is_active || false
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''

    this.logo = obj.logo || new File(["foo"], "", { type: "text/plain" })
    this.logo_url = obj.logo_url || ''
    this.logo_path = obj.logo_path || ''

    this.industry = obj.industry || null
  }

  get requireds() {
    return [
      { item: 'industry_id', label: 'área de atuação' },
      { item: 'name', label: 'nome' },
      { item: 'corporate_name', label: 'razão social' },
      { item: 'cnpj', label: 'cnpj' },
      { item: 'company_size', label: 'tamanho da empresa' },
      { item: 'employees_number', label: 'número de funcionários' },
      { item: 'cep', label: 'cep' },
      { item: 'street', label: 'endereço' },
      { item: 'state', label: 'estado' },
      { item: 'country', label: 'país' },
      { item: 'contact_name', label: 'nome contato' },
      { item: 'contact_phone', label: 'telefone contato' },
      { item: 'email', label: 'email contato' },
      { item: 'logo', label: 'logo', validation(value, obj) {
          if(obj.logo_url) return false
          if(!value.name) return true
          return false
        }
      },
      { item: 'is_branch', label: 'matriz da filial', validation(value, obj) {
          if(value && !obj.main_company_id) return true
          return false
        }
      }
    ]
  }

  get formattedCompanySize() {
    switch(this.company_size) {
      case 'small' :
        return 'Pequena'
      case 'medium' :
        return 'Média'
      default :
        return 'Grande'
    }
  }

  get formattedIsActive() {
    if(this.is_active) {
      return 'Sim'
    }
    return 'Não'
  }

  get getFormData() {
    return getFormData(this)
  }
  
}