<template>
  <div class="w-full flex flex-wrap items-start">
    
    <fieldset class="w-full">      
      <div class="mt-2 w-full flex flex-wrap sm:flex-no-wrap items-end">
        <div class="mr-3">
          <label>É filial?</label>
          <div class="w-full flex box-radio-filial">
            <Radio
              v-model="company.is_branch"
              :items="isBranchItems"
              @input="toggleIsBranch"
            ></Radio>
          </div>
        </div>
        <div class="w-full">
          <label>Empresa Matriz</label>
          <v-select 
            class="vue-select"
            placeholder="Selecione uma empresa matriz"
            :disabled="!company.is_branch"
            :class="company.main_company_id ? 'filled' : ''"
            label="name"
            :options="companies"
            :reduce="company => company.id"
            v-model="company.main_company_id"
          >
            <span slot="no-options">Não localizamos nenhum cliente para adicionar</span>
          </v-select>
        </div>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-4">

    <fieldset class="w-full">
      <legend class="h4">Dados</legend>

      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-3 md:pr-3 w-full md:w-50/100"
          :label="'Nome da empresa'"
          v-model="company.name"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-3 md:pl-3 w-full md:w-50/100"
          :label="'Razão social'"
          v-model="company.corporate_name"
        ></InputWithLabel>
      </div>

      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-3 md:pr-3 w-full md:w-50/100"
          :label="'CNPJ'"
          v-model="company.cnpj"
          :mask="'##.###.###/####-##'"
        ></InputWithLabel>
        <div class="mt-3 md:pl-3 w-full md:w-50/100">
          <label>Tamanho da empresa</label>
          <v-select 
            class="vue-select"
            :class="company.company_size ? 'filled' : ''"
            label="label"
            :options="companySizes"
            :reduce="companySize => companySize.value"
            v-model="company.company_size"
          ></v-select>
        </div>
        <InputWithLabel
          class="mt-3 md:pr-3 w-full md:w-50/100"
          :label="'Quantidade de funcionários'"
          v-model="company.employees_number"
          :mask="'####'"
        ></InputWithLabel>
        <div class="mt-3 md:pl-3 w-full md:w-50/100 relative">
          <label class="block">Ramo</label>
          <v-select 
            class="vue-select"
            :class="company.industry_id ? 'filled' : ''"
            label="name"
            :options="industries"
            :reduce="item => item.id"
            v-model="company.industry_id"
          ></v-select>
        </div>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-4">

    <fieldset class="w-full">
      <legend class="h4 mb-2">Endereço</legend>
      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-3 md:pr-3 w-full md:w-50/100"
          :label="'CEP'"
          v-model="company.cep"
          :mask="'#####-###'"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-3 w-full md:pl-3 md:w-50/100"
          :label="'Endereço completo'"
          v-model="company.street"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-3  md:pr-3 w-full md:w-50/100"
          :label="'Estado'"
          v-model="company.state"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-3 md:pl-3 w-full md:w-50/100"
          :label="'País'"
          v-model="company.country"
        ></InputWithLabel>
      </div>
    </fieldset>

    <hr class="border-b-1 border-smoke-light w-full my-4">

    <fieldset class="w-full">
      <legend class="h4 mb-2">Dados adicionais</legend>

      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-3 md:pr-3 w-full md:w-50/100"
          :label="'Nome do contato'"
          v-model="company.contact_name"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-3 md:pl-3 w-full md:w-50/100"
          :label="'Telefone do contato'"
          v-model="company.contact_phone"
          :mask="['(##) ####-####', '(##) #####-####']"
        ></InputWithLabel>
        <InputWithLabel
          class="mt-3 md:pr-3 w-full md:w-50/100"
          :type="'email'"
          :label="'Email'"
          v-model="company.email"
        ></InputWithLabel>
        <Textarea
          class="mt-3"
          :label="'Observação'"
          v-model="company.observation"
        ></Textarea>

      </div>

      <div class="w-full flex mt-4">
        <InputFile
          class="w-full"
          label="Imagem logotipo"
          v-model="company.logo"
        ></InputFile>
        <button 
          @click="clearLogo()"
          class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
          Excluir
        </button>
      </div>

    </fieldset>

    <div class="w-full text-base mt-4 mb-2" v-if="company.hasError" v-html="company.validationPhraseHtml"></div>
    
    <button 
      v-if="!isEdit"
      @click="store()" 
      :disabled="company.hasError"
      class="button button-yellow">
      Cadastrar
    </button>
    <button 
      v-else
      @click="update()"
      :disabled="company.hasError" 
      class="button button-yellow mt-4">
      Atualizar
    </button>

    <AvoidExitModal></AvoidExitModal>

  </div>
</template>

<script>
import Company from '@/models/Company'
import { companySizeOptions, radioBooleanOptions } from '@/models/Enums'
export default {
  name: 'CompanyForm',
  data() {
    return {
      isBranchItems: radioBooleanOptions,
      companySizes: companySizeOptions,
      companies: [],
      industries: [],
      company: new Company(),
      isEdit: false
    }
  },
  mounted() {
    this.getMainCompanies()
    this.getIndustries()
    if(this.$route.params.companyId) {
      this.isEdit = true
      this.getCompany()
    }
  },
  methods: {

    getMainCompanies() {
      const query =  `
        {
          companies (
            whereNull: { columns: ["main_company_id"] }
            orderBy: [{ column: "name", order: "asc" }]
          ) {
            id
            name
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.companies = data.companies
        })
    },

    getIndustries() {
      const query =  `
        {
          industries {
            id
            name
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.industries = data.industries
        })
    },

    getCompany() {
      const query =  `
        {
          company (
            findBy: { column: "id", value: "${this.$route.params.companyId}" }
          ) {
            id
            industry_id
            main_company_id
            name
            slug
            corporate_name
            cnpj
            company_size
            employees_number
            cep
            street
            state
            country
            contact_name
            contact_phone
            email
            observation
            created_at
            updated_at
            is_active
            logo_url
            main_company {
              name
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.company = new Company(data.company)
        })
    },
    
    store() {
      const formData = this.company.getFormData

      this.$api.post(`/admin/company/store`, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success('Empresa cadastrada com sucesso')
          this.$router.push({ name: 'CompanyList', forceRedirect: true })
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error('Erro ao cadastrar empresa')
        })
    },
    update() {
      const formData = this.company.getFormData

      this.$api.post(`/admin/company/update/${this.company.id}`, formData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success('Empresa atualizada com sucesso')
          this.$router.push({ name: 'CompanyList' , forceRedirect: true})
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error('Erro ao atualizar empresa')
        })
    },

    toggleIsBranch(value){
      if(!value) this.company.main_company_id = ''
    },

    clearLogo() {
      this.game.logo = new File(["foo"], "", { type: "text/plain" })
    },
  }
}
</script>