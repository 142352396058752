<template>
  <BaseGameLayout>
    <GameHeader></GameHeader>

    <div class="w-full h-85vh flex">
      <div class="w-15vw pr-5">
        <div class="border-yellow border-r-2 h-full flex flex-col items-start justify-start px-1 py-3 max-h-full overflow-y-auto overflow-x-hidden">
          <PlayerCard 
            :playerProject="playerProject"
            v-for="playerProject in getFirstThreePlayers"
            :key="playerProject.id"
          ></PlayerCard>
        </div>
      </div>

      <div class="w-70vw px-12 py-6 h-85vh max-h-85vh hide-scrollbar overflow-y-hidden">
        <transition
          mode="out-in"
          name="fade"
          >
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </div>

      <div class="w-15vw h-full pl-5">
        <div class="border-yellow border-l-2 h-full flex flex-col items-start justify-start px-1 py-3 max-h-full overflow-y-auto overflow-x-hidden">
          
          <PlayerCard 
            :playerProject="playerProject"
            v-for="playerProject in getLastThreePlayers"
            :key="playerProject.id"
          ></PlayerCard>

        </div>
      </div>
    </div>

    <audio id="receive-message"><source src="/sounds/receive-message.mp3" type="audio/mpeg"></audio>
    <audio id="send-message"><source src="/sounds/send-message.mp3" type="audio/mpeg"></audio>
    <audio id="correct-answer"><source src="/sounds/correct-answer.mp3" type="audio/mpeg"></audio>
    <audio id="wrong-answer"><source src="/sounds/wrong-answer.mp3" type="audio/mpeg"></audio>
    <audio id="unlock-tip"><source src="/sounds/unlock-tip.mp3" type="audio/mpeg"></audio>
    <audio id="background-sound" v-if="getProject.background_music_url" autoplay loop>
      <source :src="getProject.background_music_url" type="audio/mp3" >
    </audio>
  </BaseGameLayout>
</template>

<script>
import GameHeader from './GameHeader'
import PlayerCard from '../player/PlayerCard'
export default {
  name: 'InGameLayout',
  components: { GameHeader, PlayerCard },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getTeamPlayersProject() {
      return this.$store.getters.getterTeamPlayersProject.filter((playerProject) => !playerProject.is_hidden)
    },
    getRoom() {
      return this.$store.getters.getterRoom
    },
    getRoomPlayers() {
      return this.getRoom.players.filter((player) => !player.playerIsHidden)
    },

    getHalfOfTheRoomSize() {
      return Math.floor(this.getTeamPlayersProject.length / 2)
    },
    getFirstThreePlayers() {
      return this.getTeamPlayersProject.map((item) => item).splice(0, this.getHalfOfTheRoomSize)
    },
    getLastThreePlayers() {
      return this.getTeamPlayersProject.map((item) => item).splice(this.getHalfOfTheRoomSize, (this.getTeamPlayersProject.length) )
    }
  },
  mounted() {
   this.$store.dispatch('getLoggedPlayer', { projectSlug: this.getProject.slug })
    .then(() => {
      this.$store.dispatch('getTwilioPlayerToken')
      setTimeout(() => {
        this.$api.post(`/game/room/go_to_current_game`, { team_id: this.getTeam.id })
      }, 5000)
    })
  }
}
</script>