<template>
  <h5 class="font-bold">{{accessLabel.label}}</h5>
</template>

<script>
export default {
  name: 'PuzzleButtonAccessHelper',
  props: ['access'],
  computed: {
    accessLabel() {
      if(this.access == 'all') return { label: 'Todos', color: 'text-blue'}
      if(this.access == 'leader') return { label: 'Líder', color: 'text-green'}
      if(this.access == 'one_player') return { label: '1 jogador', color: 'text-green'}
      if(this.access == 'two_players') return { label: '2 jogadores', color: 'text-green'}
      if(this.access == 'three_players') return { label: '3 jogadores', color: 'text-green'}
      return { label: 'Todos', color: 'text-blue'}
    }
  }
}
</script>