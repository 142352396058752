<template>
  <div class="flex flex-col rounded-lg shadow">
    <div class="w-full flex flex-col space-y-3 rounded-t-lg p-3" :class="[bg_color]">
      <h3 class="w-full text-center text-white font-bold text-xl">{{title}}</h3>
      <img class="h-10vh" :src="icon" />
    </div>
    <div class="w-full p-2 flex items-center justify-center bg-white rounded-b-lg">
      <h3 class="text-black font-bold text-xl" v-if="subtitle">{{subtitle}}</h3>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemCard',
  props: ['bg_color', 'title', 'subtitle', 'icon']
}
</script>