<template>

  <div class="max-w-sm w-full flex">
    <div class="w-full p-6 bg-white shadow rounded">
      
      <div class="w-full flex justify-center">
        <img src="/img/logo_ideia.svg" width="144px" alt="Ideia ativada logo" />
      </div>

      <div class="w-full flex content-center" v-if="!$route.params.projectSlug">
        <h1 class="w-full text-2xl italic text-center mt-4">Projeto não localizado</h1>
      </div>
      <div class="w-full flex flex-col" v-else-if="!twoFactorSent">
        <InputGame
          v-model="email"
          placeholder="Insira seu email"
          @keydown="sendTwoFactorCode()"
          inputClasses="border-2 border-grey-light"
        ></InputGame>

        <GameButton @click="sendTwoFactorCode()" label="Entrar" class="w-12vw text-3xl mt-4"></GameButton>
      </div>

      <div class="w-full flex flex-col" v-else>
        <InputGame
          v-model="twoFactorCode"
          placeholder="Insira o código de acesso"
          type="tel"
          @keydown.enter="checkTwoFactorCode()"
          inputClasses="border-2 border-grey-light"
        ></InputGame>

        <GameButton @click="checkTwoFactorCode()" label="Confirmar" class="w-12vw text-3xl mt-4"></GameButton>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportLogin',
  metaInfo () {
    return {
      title: `Relatórios Idéia Ativada |`
    }
  },
  data() {
    return {
      email: 'igortrindademe@gmail.com',
      twoFactorSent: false,
      twoFactorCode: ''
    }
  },
  methods: {
    sendTwoFactorCode() {
      this.$api.post(`/game/player/auth/send_two_factor_code`, { email: this.email, projectSlug: this.$route.params.projectSlug }, { loader: true })
        .then(({ data }) => {
          this.$eventBus.$emit('openGameToast', { title: 'Enviado', message: data.message, duration: 5000 })
          this.twoFactorSent = true
        })
        .catch((err) => {
          if(err && err.response && err.response.status == 404) {
            this.$eventBus.$emit('openGameToast', { title: 'Ops!', message: 'Usuario não localizado no projeto'})
          }
        })
    },

    checkTwoFactorCode() {

      this.$store.dispatch('attemptReportPlayerLogin', { two_factor_code: this.twoFactorCode, email: this.email, projectSlug: this.$route.params.projectSlug })

    }
  }
}
</script>

<style>

</style>