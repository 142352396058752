<template>
  <BaseModal
    ref="baseModal"
    modalDialogClasses="max-w-sm bg-white text-black"
    @onCloseModal="close()"
  >
    <fieldset class="w-full mt-6 text-black" >
      <legend class="h4 font-semi-bold mb-2">Adicionar ou editar opção</legend>
      <div class="w-full flex flex-wrap">
        <InputWithLabel
          class="mt-4 w-full"
          label="Título (opcional)"
          placeholder="Digite aqui"
          v-model="quiz_question_option.title"
        ></InputWithLabel>
        <Textarea
          class="mt-4 w-full"
          label="Conteúdo (opcional)"
          placeholder="Digite aqui"
          v-model="quiz_question_option.content"
        ></Textarea>
        <div class="mt-4 mr-3" v-if="type == 'quiz_question_options'">
          <label>É correta</label>
          <div class="w-full mt-2 flex">
            <Radio
              v-model="quiz_question_option.is_correct"
              :items="radioBooleanOptions"
            ></Radio>
          </div>
        </div>
      </div>
      <div class="w-full flex items-end flex-wrap mt-4">
        <InputFile
          class="lg:max-w-50/100"
          label="Imagem"
          v-model="quiz_question_option.file"
        ></InputFile>
        <button 
          @click="clearFile(quiz_question_option, 'file')"
          class="mt-3 lg:mt-0 lg:ml-6 button border border-red text-red rounded focus:outline-none">
          Excluir
        </button>
      </div>

      <div class="w-full text-base mt-4" v-if="quiz_question_option.hasError" v-html="quiz_question_option.validationPhraseHtml"></div>

      <div class="w-full flex mt-4">
        <button @click="store()" :disabled="quiz_question_option.hasError" class="button button-yellow px-8">
          Salvar
        </button>
      </div>
    </fieldset>
  </BaseModal>
</template>

<script>
import QuizQuestionItem from '@/models/QuizQuestionItem'
import { radioBooleanOptions } from '@/models/Enums'
export default {
  name: "QuestionQuizQuestionOptionModal",
  props: ['type'],
  data() {
    return {
      id: null,
      quiz_question_option: new QuizQuestionItem(),
      radioBooleanOptions
    }
  },
  computed: {
    getTitle() {
      if(this.type == 'quiz_question_tips') return 'Dicas'
      return 'Opcoes de resposta'
    }
  },
  mounted() {
    this.quiz_question_option.quiz_question_id = this.$route.params.quizQuestionId
    
    this.$eventBus.$on('openQuizQuestionOptionModal', ({ id = null } = {}) => {
        this.$refs.baseModal.showModal = true
        this.quiz_question_option.id = id
        if(id) this.getQuizQuestionOption()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openQuizQuestionOptionModal')
  },
  methods: {

    getQuizQuestionOption() {
      const query =  `
        {
          quiz_question_option (
            findBy: { column: "id", value: "${ this.quiz_question_option.id }"}
          ) {
            id
            quiz_question_id
            order
            title
            content
            file_url
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.quiz_question_option = new QuizQuestionItem(data.quiz_question_option)
        })
    },

    store() {

      const url = this.quiz_question_option.id ? `/admin/quiz_question_options/update/${this.quiz_question_option.id}` : `/admin/quiz_question_options/store`

      this.$api.post(url, this.quiz_question_option.getFormData, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(() => {
          this.$toast.success(`Item salvo com sucesso`)
          this.close()
          this.$emit('update')
          this.$emit('getItems')
        })
    },

    openForm(){
      this.showForm = true
      setTimeout(() => {
        document.getElementById(`${this.type}-input`).focus()
      }, 100)
    },

    close() {
      this.quiz_question_id = new QuizQuestionItem()
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>