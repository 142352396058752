<template>
  <div class="h-full flex items-center absolute left-0 top-0 pl-3">
    <div class="flex flex-col">
      <div class="flex">
        <button
          @click="$refs.roomInfoModal.$refs.teamPlayersModal.showModal = true"
          class="p-1 ml-1 rounded-sm justify-self-end focus:outline-none bg-yellow"
        >
          <feather size="18" type="info"></feather>
        </button>
        <button
          @click="$refs.videoAudioSettings.showModal = true"
          class="p-1 ml-1 rounded-sm justify-self-end focus:outline-none bg-yellow"
        >
          <feather size="18" type="settings"></feather>
        </button>
      </div>
      <div class="flex" v-if="getProject.background_music_url">
        <InputSlider :width="120" v-model="volume" @input="updateMusicVolume" :step="0.1" :max="1"></InputSlider>
      </div>
    </div>

    <BaseModal
      ref="videoAudioSettings" 
      modalDialogClasses="max-w-lg bg-black"
    >
      <div class="w-full flex flex-col">
        <h2 class="w-full text-center font-bold text-xl">Configurações</h2>

        <div class="w-full flex flex-col mt-4">
          <div class="w-full flex justify-between">
            <label class="inline-block text-2xl text-yellow">Câmera</label>
            <button 
              @click="$store.dispatch('toggleVideo')"
              class="p-1 rounded-sm justify-self-end focus:outline-none"
              :class="[$store.getters.getterVideoIsOn ? 'bg-yellow' : 'bg-grey-light text-red']"
            >
              <feather class="mr-2" size="18" :type="$store.getters.getterVideoIsOn ? 'video' : 'video-off'"></feather>
              <span v-if="$store.getters.getterVideoIsOn">Ligado</span>
              <span v-else>Desligado</span>
            </button>
          </div>
          <select v-model="videoDevice" class="mt-2 block appearance-none outline-none w-full border-2 border-yellow  focus:border-yellow bg-black text-yellow text-2xl py-2 px-3">
            <option :value="null" disabled>Escolha uma câmera</option>
            <option v-for="device in videoDevices" :value="device.label" :key="device.deviceId" :data-value="device.deviceId">{{device.label}}</option>
          </select>
        </div>

        <div class="w-full flex flex-col mt-4">
          <div class="w-full flex justify-between">
            <label class="inline-block text-2xl text-yellow">Microfone</label>
            <button 
              @click="$store.dispatch('toggleAudio')"
              class="p-1 rounded-sm justify-self-end focus:outline-none"
              :class="[$store.getters.getterAudioIsOn ? 'bg-yellow' : 'bg-grey-light text-red']"
            >
              <feather class="mr-2" size="18" :type="$store.getters.getterAudioIsOn ? 'video' : 'video-off'"></feather>
              <span v-if="$store.getters.getterAudioIsOn">Ligado</span>
              <span v-else>Desligado</span>
            </button>
          </div>
          <select v-model="audioDevice" class="mt-2 block appearance-none outline-none w-full border-2 border-yellow  focus:border-yellow bg-black text-yellow text-2xl py-2 px-3">
            <option :value="null" disabled>Escolha um microfone</option>
            <option v-for="device in audioDevices" :value="device.label" :key="device.deviceId" :data-value="device.deviceId">{{device.label}}</option>
          </select>
        </div>


      </div>
    </BaseModal>

    <RoomInfoModal ref="roomInfoModal"/>
  </div>
</template>

<script>
import { find } from '@/util/Functions'
import RoomInfoModal from '@/modules/game/room/RoomInfoModal'
export default {
  name: 'VideoAudioControls',
  components: { RoomInfoModal },
  data() {
    return {
      volume: 1,
      videoDevices: [],
      audioDevices: [],
    }
  },
  computed: {
    getProject() {
      return this.$store.getters.getterProject
    },
    videoDevice: {
      get() {
        return this.$store.state.gamePlayer.videoDeviceLabel
      },
      set(label) {
        const selected = find(this.videoDevices, { label })
        if(selected) {
          this.$store.dispatch('changeVideoDevice', { deviceId: selected.deviceId })
        }
      }
    },
    audioDevice: {
      get() {
        return this.$store.state.gamePlayer.audioDeviceLabel
      },
      set(label) {
        const selected = find(this.audioDevices, { label })
        if(selected) {
          this.$store.dispatch('changeAudioDevice', { deviceId: selected.deviceId })
        }
      }
    }
  },
  mounted() {
    this.getVideoDevices()
  },
  methods: {

    getVideoDevices() {
       navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          this.videoDevices = devices.filter(device => device.kind == 'videoinput')
          this.audioDevices = devices.filter(device => device.kind == 'audioinput')
        })
    },

    handleVideoSelected($event) {
      console.log($event.target.value)
    },

    updateMusicVolume(value) {
      const backgroundMusic = document.getElementById('background-sound')
      if(backgroundMusic) backgroundMusic.volume = value
    }
  }
}
</script>