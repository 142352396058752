<template>
  <ReportWithHeader>
    <div class="w-full flex space-x-6 h-full">
      <GenerationCard 
        v-for="(item, index) in items" 
        :key="index" 
        :item="item" 
        :index="index"
        :showMarkers="true"
      ></GenerationCard>
    </div>
  </ReportWithHeader>
</template>

<script>
import GenerationCard from '../shared/GenerationCard.vue'
export default {
  name: 'Report04',
  components: { GenerationCard },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.$api.get(`${this.$store.getters.getterReportApiRoute('get_generation_report')}`)
        .then(({ data }) => {
          this.items = data.items
        })
    },
  }
}
</script>