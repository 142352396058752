

export default {
  setLoggedUserToken(state, { token, type = 'admin' }) {
    state.loggedUserToken = token
    state.loggedUserType = type
  },
  setLoggedUser(state, { user }) {
    state.loggedUser = user
  },
  setLogout(state) {
    state.loggedUser = null
    state.loggedUserToken = null
    state.loggedUserType = null
  }
}
