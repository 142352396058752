<template>
  <div class="w-full flex flex-col rounded-lg">
    <div class="w-full h-60vh rounded border border-yellow border-only-corner p-2 relative flex">
      <span></span>
      <div class="w-full h-full rounded bg-smoke-light text-white relative">
        
        <img class="object-cover h-full w-full rounded-sm" :src="game.background_file_url" />

        <div
          v-for="(button, index) of puzzle_buttons"
          :key="index"
          v-show="getButtonAccess(button)"
          @click="openbutton(button)"
          :style="{
            left: button.left_position + '%',
            top: button.top_position + '%',
          }"
          class="absolute h-10vh w-10vh border-3 border-white rounded-full bg-smoke-light flex items-center justify-center cursor-pointer"
        >
          <img v-if="button.icon_url" :src="button.icon_url"/>
          <span v-else>{{button.label}}</span>
        </div>
      </div>
    </div>

    <GamePuzzleActionMenu 
      :game="game"
      @getGame="$emit('getGame')"
    ></GamePuzzleActionMenu>

    <BaseModal
      ref="gamePuzzleButtonModal" 
      modalDialogClasses="max-w-lg bg-black"
      @onCloseModal="buttonSelected = null"
    >
      <div class="w-full flex flex-col" v-if="buttonSelected">
        <h2 class="w-full text-center font-bold text-xl">{{ buttonSelected.title }}</h2>
        <div  class="w-full flex justify-center mt-4">
          <img v-if="buttonSelected.file_url" class="w-full object-cover center" :src="buttonSelected.file_url" alt=""/>
          <p v-else>{{buttonSelected.content}}</p>
        </div>
      </div>
    </BaseModal>

    <GameIntroductionModal :game="game"></GameIntroductionModal>

  </div>
</template>

<script>
import { shufleArray } from '@/util/Functions'
import GamePuzzleActionMenu from './GamePuzzleActionMenu'

export default {
  name: 'GamePuzzle',
  components: { GamePuzzleActionMenu },
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      puzzle_buttons: [],
      buttonSelected: null
    }
  },

  computed: {
    randomOptions() {
      return shufleArray(this.game.options.map((item) => item))
    },
    currentQuizQuestion() {
      return this.quizQuestions[this.getCurrentStep] ? this.quizQuestions[this.getCurrentStep] : null
    },
    getPlayerProject() {
      return this.$store.getters.getterPlayerProject
    },
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getProject() {
      return this.$store.getters.getterProject
    },
    getCurrentStep() {
      return this.$route.params.step || 0
    },
    getTotalGameTime() {
      return this.$store.getters.getterTotalGameTime
    },
    getTotalTeamTime() {
      return this.$store.getters.getterTotalTeamTime
    },
  },

  mounted() {
    this.getPuzzleButtons()
  },
  methods: {

    getPuzzleButtons() {
      const query = `
        {
          game (
            findBy: { column: "slug", value: "${this.$route.params.gameSlug}" }
          ) {
            id
            name
            puzzle_buttons {
              id
              title
              label
              content
              file_url
              icon_url
              access
              left_position
              top_position
            }
            tips {
              id
              title
              content
              file_url
              team_tips {
                id
              }
            }
          }
        }
      `

      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.puzzle_buttons = data.game.puzzle_buttons
        })
    },
    getButtonAccess(button) {
      if(button.access == 'leader' && !this.getPlayerProject.is_leader) return false
      if(button.access == 'players' && this.getPlayerProject.is_leader) return false
      return true
    },
    openbutton(button) {
      this.$refs.gamePuzzleButtonModal.showModal = true
      this.buttonSelected = button
    }
  }
}
</script>

<style scoped>

.options-label {
  hyphens: auto;
}

</style>