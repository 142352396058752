
const PaginationInfo = class {
  constructor(obj = {}) {
    this.CurrentPage = obj.CurrentPage || 1
    this.ItemPerPage = obj.ItemPerPage || 10
    this.TotalPage = obj.TotalPage || 1
    this.TotalInfo = obj.TotalInfo || 0
  }

}

module.exports = PaginationInfo
