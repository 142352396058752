import Vue from 'vue'
import App from './App.vue'
import mainRouter from './mainRouter'
import store from './mainVuex'
import EventBus from '@/util/EventBus'
import Api from '@/util/Api'
import ApolloClient from '@/util/ApolloClient'

Vue.config.productionTip = false

Vue.prototype.$api = Api
Vue.prototype.$graphql = ApolloClient
Vue.prototype.$eventBus = EventBus
window.$eventBus = EventBus

require('./mainComponents')

require('./socket/main')

require('./util/GlobalFunctions')

store.dispatch('resetLoader')

window.$vueInstance = new Vue({
  router: mainRouter,
  store,
  render: h => h(App)
}).$mount('#app')
