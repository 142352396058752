<template>
  <div class="h-full w-full flex">
    <div class="w-full bg-white shadow rounded text-black">
      <transition name="fade" mode="out-in">
        <component
          :is="getRouteReport.component"
        ></component>
      </transition>
    </div>
    <div class="w-5/100 h-full flex flex-col items-center justify-center space-y-3">
      <router-link 
        class="report-navigator-bullet rounded-full shadow"
        :class="{'active' : getRouteSlug == route.slug}"
        v-for="route in getReportRoutes" :key="route.slug"
        :to="getRouteTo(route.slug)"
      ></router-link>
    </div>
  </div>
</template>

<script>
import { reportManagerComponents, reportPlayerComponents } from '../reportList'
import { find } from '@/util/Functions'
export default {
  name: 'InReportLayout',
  computed: {

    getRouteSlug() {
      if(!this.$route.params.reportType) return 'home'
      return this.$route.params.reportType
    },

    getReportRoutes() {
      if(this.$route.params.userType == 'gerencial') {
        return reportManagerComponents
      } else {
        return reportPlayerComponents
      }
    },
    getRouteReport() {

      if(!this.$route.params.reportType || this.$route.params.reportType == 'home') {
        return this.getReportRoutes[0]
      }

      const route = find(this.getReportRoutes, { slug: this.$route.params.reportType})
      if(route) {
        return route
      } else {
        return this.getReportRoutes[0]
      }
      
    }
  },
  methods: {
    getRouteTo(slug) {
      return { ...this.$route, params: { ...this.$route.params, reportType: slug}}
    }
  }
}

</script>

<style scoped>

  .report-navigator-bullet {
    width: 12px;
    height: 12px;
    background-color: #FFFFFF;
    transition: all 500ms;
  }

  .report-navigator-bullet.active {
    background-color: #F7A24A !important;
    width: 16px;
    height: 16px;
  }
</style>