<template>
  <div class="w-full">
    <div class="w-full flex flex-wrap lg:flex-no-wrap justify-between items-end">
      <SearchInput
        class="lg:max-w-50/100 search-input"
        v-model="filters.project_name"
        @keydown="getProjects"
        :label="'Busca'"
      ></SearchInput>
      <router-link :to="{ name: 'ProjectAdd' }" class="lg:w-1/6 mt-3 lg:mt-0 button button-yellow whitespace-no-wrap">
        <feather type="plus" class="mr-2"></feather>
        <span>Novo projeto</span>
      </router-link>
    </div>

    <div class="w-full mt-4 overflow-x-auto max-w-full min-w-0">
      <table class="table table-striped bg-white shadow-sm whitespace-no-wrap">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data do evento</th>
            <th>Empresa</th>
            <th>Quantidade usuários</th>
            <th>Quantidade games</th>
            <th>Status</th>
            <th>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects" :key="project.id">
            <td>{{project.name}}</td>
            <td>{{project.event_start_at}}</td>
            <td>{{project.company.name}}</td>
            <td>{{project.user_quantity}}</td>
            <td>{{project.games.length}}</td>
            <td><ProjectStatusLabel :status="project.status"></ProjectStatusLabel></td>
            <td>
              <router-link class="" :to="{ name: 'ProjectUpdate', params: { projectId: project.id }}">
                <feather type="edit"></feather>
              </router-link>
              <router-link class="ml-3" :to="{ name: 'ProjectShow', params: { projectId: project.id }}">
                <feather type="search"></feather>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Paginator
      class="mt-2"
      v-model="offset" 
      :length="projects.length" 
      :limit="limit"
      @getItems="getProjects"
    ></Paginator>

  </div>
</template>

<script>
import Paginator from '@/components/Paginator'
import { gameComponentOptions, gameDifficultiesOptions } from '@/models/Enums'
export default {
  name: 'GameList',
  components: { Paginator },
  data() {
    return {
      gameComponentOptions,
      gameDifficultiesOptions,
      orderBy: {
        column: 'name',
        direction: 'asc'
      },
      filters: {
        project_name: '',
        difficulty: '',
        type: ''
      },
      projects: [],
      offset: 0,
      limit: 12
    }
  },
  mounted() {
    this.getProjects()
  },
  methods: {

    getProjects() {
      const query =  `
        {
          projects (
            where: [
              ${this.filters.project_name ? `{ column: "name", operation: "ILIKE", value: "%${this.filters.project_name}%" }` : ''}
              ${this.filters.difficulty ? `{ column: "difficulty", operation: "=", value: "${this.filters.difficulty}" }` : ''}
              ${this.filters.type ? `{ column: "type", operation: "=", value: "${this.filters.type}" }` : ''}
            ]
            orderBy: [{ column: "${this.orderBy.column}", order: "${this.orderBy.direction}" }]
            paginate: { offset: ${this.offset}, limit: ${this.limit} }
          ) {
            id
            name
            event_start_at
            user_quantity
            status
            created_at
            updated_at
            company {
              id
              name
            }
            games {
              id
              name
            }
          }
        }
      `
      this.$graphql({ query, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.projects = data.projects
        })
    },
  }
}
</script>

<style>

</style>