<template>
  <ReportWithHeader
    :title="getSelectedPlayer ? getSelectedPlayer.name : ' '"
  >
    

    <div class="h-full w-full relative overflow-x-hidden overflow-y-hidden rounded" id="swiper-section">
      <div class="w-full h-full absolute z-20 flex items-center justify-between">
        <button 
            @click="setPlayer('left')"
            :disabled="selectedPlayerIndex == 0"
            class="rounded-full bg-smoke flex items-center justify-center p-1 text-white">
            <feather type="chevron-left"></feather>
          </button>
        <button 
            @click="setPlayer('right')"
            :disabled="selectedPlayerIndex == team_players.length-1"
            class="rounded-full bg-smoke flex items-center justify-center p-1 text-white">
            <feather type="chevron-right"></feather>
          </button>
      </div>

      <div class="w-full h-full">
        <PlayerPersonality
          ref="personalityCard"
          :team="getTeam" 
          :player="getSelectedPlayer"
        ></PlayerPersonality>
      </div>
    </div>
  </ReportWithHeader>
</template>

<script>
import TinyGesture from 'tinygesture'
import PlayerPersonality from './PlayerPersonality'
export default {
  name: 'Report10',
  components: { PlayerPersonality },
  data() {
    return {
      team_players: [],
      selectedPlayerIndex: null,
      direction: 'right',
      tinyGesture: null
    }
  },
  computed: {
    getTeam() {
      return this.$store.getters.getterTeam
    },
    getPlayer() {
      return this.$store.getters.getterPlayer
    },
    getSelectedPlayer() {
      if(!this.team_players.length || this.selectedPlayerIndex == null) return null
      return this.team_players[this.selectedPlayerIndex]
    }
  },
  mounted() {
    this.getTeamPlayers()
    this.initSwiper()
  },
  beforeDestroy() {
    this.tinyGesture.destroy()


    
  },
  methods: {

    getTeamPlayers() {
      const query = `
        {
          team (
            findBy: { column: "id", value: "${this.getTeam.id}"}
          ) {
            id
            players_project {
              id
              player {
                id
                name
              }
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.team_players = data.team.players_project.map((item) => item.player)
          this.selectedPlayerIndex = 0
        })
    },

    setPlayer(direction) {

      if(direction == 'left' && this.selectedPlayerIndex == 0 || direction == 'right' && this.team_players.length-1 == this.selectedPlayerIndex) return
      this.direction = direction
      this.moveCard(direction)
      let index
      if(direction == 'left') {
        index = this.selectedPlayerIndex -1
      } else {
        index = this.selectedPlayerIndex +1
      }
      this.selectedPlayerIndex = index
    },

    initSwiper() {
      this.tinyGesture = new TinyGesture(document.getElementById('swiper-section'))
      

      this.tinyGesture.on('panend', () => {
        const target = document.getElementById('personality-card')
        // if(Math.abs(this.tinyGesture.touchMoveX) < 30) return
        // if(this.tinyGesture.touchMoveX > 0) {
          //   this.setPlayer('left')
        // } else {
          //   this.setPlayer('right')
        // }

        window.cancelAnimationFrame(this.tinyGesture.animationFrame)
        this.tinyGesture.animationFrame = null
        target.style.transition = null
        target.style.transform = null
        console.log('panend')
      })

      this.tinyGesture.on('panmove', () => {
        const target = document.getElementById('personality-card')
        if (this.tinyGesture.animationFrame) return
        this.tinyGesture.animationFrame = window.requestAnimationFrame(() => { target.style.transition = 'background-color ease .3s'
          target.style.transform = 'perspective(1000px) translate3d('+this.tinyGesture.touchMoveX+'px, 0, 0)'
          window.requestAnimationFrame(() => {
            target.style.transition = null
          })
          this.tinyGesture.animationFrame = null
        })
      })

      this.tinyGesture.on('swiperight', () => {
        if(this.selectedPlayerIndex == 0) return
        this.setPlayer('left')
      })
      this.tinyGesture.on('swipeleft', () => {
        if(this.team_players.length-1 == this.selectedPlayerIndex) return
        this.setPlayer('right')
      })
    },

    moveCard(direction) {
      
      const target = document.getElementById('personality-card')
      target.style.transform = `perspective(1000px) translate3d(${direction == 'right' ? '-' : ''}2000px, 0, 0)`
      target.style.opacity = 0

      setTimeout(() => {
        target.style.transition = `transform ease .01s`
        target.style.transform = `perspective(1000px) translate3d(${direction == 'right' ? '' : '-'}1000px, 0, 0)`
        setTimeout(() => {
          target.style.transition = `transform ease .3s`
          target.style.transform = null
          target.style.opacity = 1
        }, 20);
      }, 200)
    }
   
  }

}
</script>