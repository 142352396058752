export default {
  setPlayer(state, { player }) {
    state.player = player
  },
  setProject(state, { project }) {
    state.project = project
  },
  setTeam(state, { team }) {
    state.team = team
  },
  setPlayerProject(state, { playerProject }) {
    state.playerProject = playerProject
  },
  setTeamIsFinished(state) {
    state.team.is_finished = true
  }
}
