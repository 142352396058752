<template>
  <button class="relative focus:outline-none" @click="$emit('click')">
    <img class="-mb-0.5" src="/buttons/button_clear.svg" />
    <div class="w-full flex items-center justify-center text-white absolute">
      <img :src="icon" v-if="icon" :alt="label" />
      <span>{{label}}</span>
    </div>
    <span v-if="notifications>0" class="absolute top-0 right-0 -mr-1 -mt-1 h-3vh w-3vh z-20 text-sm rounded-full bg-red text-white flex items-center justify-center">
      {{notifications}}
    </span>
  </button>
</template>

<script>
export default {
  name: 'GameButton',
  props: {
    label: {
      default: ''
    },
    icon: {
      required: false
    },
    classes: {
      default: ''
    },
    notifications: {
      default: 0
    },
    bgNotifications: {
      required: false
    }
  }
}
</script>
<style>
  .a,.f{fill:#fff;}.a{stroke:#707070;}.b{fill:#16150c;}.b,.c,.e{stroke:#ffff50;}.c{fill:#ffe600;opacity:0.1;}.d{clip-path:url(#e);}.e,.h{fill:none;}.e{stroke-width:4px;}.f{font-size:30px;font-family:LondrinaSolid-Regular, Londrina Solid;letter-spacing:0.05em;}.g{stroke:none;}.i{filter:url(#c);}.j{filter:url(#a);}
</style>